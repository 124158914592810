// v2/cart/RemovePreOrderItem
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { GetCart } from 'src/queries/curbside/get-cart';
import { post } from 'src/utils/helpers/http';
import { useBaseAPIParams } from 'src/utils/hooks/useBaseAPIParams';

const REMOVE_PREORDER_ITEM = 'v2/cart/RemovePreOrderItem';

export type RemovePreorderCartItemPayload = {
  customerId: number;
  productId: number;
  shipmentId: number;
};

const transformToServerPayload = (payload: RemovePreorderCartItemPayload) => ({
  ProdId: payload.productId,
  AcctId: payload.customerId,
});

export const useRemovePreorderItemFromCart = () => {
  const queryClient = useQueryClient();
  const baseAPIParams = useBaseAPIParams();

  return useMutation({
    mutationFn: async (payload: RemovePreorderCartItemPayload) => {
      await post(REMOVE_PREORDER_ITEM, {
        ...baseAPIParams,
        ...transformToServerPayload(payload),
      });
    },
    onSettled: (_data, error, payload) => {
      void queryClient.invalidateQueries({ queryKey: [GetCart, payload.shipmentId] });

      if (error) {
        throw error;
      }
    },
  });
};

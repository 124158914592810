import { useMutation } from '@tanstack/react-query';

import { post } from 'src/utils/helpers/http';
import { useBaseAPIParams } from 'src/utils/hooks/useBaseAPIParams';

const SAVE_SIGNATURE_URL = '/v2/guest/save-checkout-signature';

export type SaveSignatureMutationType = {
  ShipmentId?: number;
  Signature: string;
};

export function useSaveSignature({ ShipmentId, Signature }: SaveSignatureMutationType) {
  const baseAPIParams = useBaseAPIParams();

  return useMutation({
    mutationFn: async () => {
      const res = await post(SAVE_SIGNATURE_URL, {
        ...baseAPIParams,
        ShipmentId,
        Signature,
      });
      return res;
    },
    mutationKey: ['save-signature'],
  });
}

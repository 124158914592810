import { type QueryKey, useMutation } from '@tanstack/react-query';

import { post } from 'src/utils/helpers/http';

export const SSOLoginRedirectKey: QueryKey = ['sso-login-redirect'];

const SSO_LOGIN_ENABLED_URL = '/api/saml/SSOLoginRedirect';

export const useSSOLoginRedirectMutation = () =>
  useMutation({
    mutationFn: async (payload: { username: string }) => {
      const res = await post<any>(SSO_LOGIN_ENABLED_URL, payload);
      if (res) {
        window.location.href = res;
      }
    },
    mutationKey: [SSOLoginRedirectKey],
  });

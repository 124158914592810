/**
 * BaseCustomEventData is the base type for all custom event data objects.
 *
 * key - unique identifier for the custom event
 * comment - optional comment to add to the custom event to add more verbose context
 */
export type BaseCustomEventData = { comment?: string; key: string };

/**
 * WARNING: changing keys will potentially break existing dashboards/queries in DataDog and/or LogRocket
 * PLEASE LEAVE A COMMENT DESCRIBING WHAT THE EVENT IS TRACKING
 */
export const customEventKeys = {
  cart: {
    loaded: 'cart_loaded',
    checkout: 'checkout_initiated',
  },
  notification: 'notification_displayed',
  scanning: {
    scanReceived: 'scan_received',
  },
};

import { useMemo } from 'react';

import { type CartItemTemp, type AppliedDiscountTemp } from '@dutchie-pos/types';

export const useApplicableItemDiscounts = (cartItem?: CartItemTemp, discounts?: AppliedDiscountTemp[]) =>
  useMemo(() => {
    if (!cartItem || !discounts) {
      return [];
    }
    return discounts.filter((discount) => cartItem.RowInventoryIds?.includes(discount.InvId));
  }, [cartItem, discounts]);

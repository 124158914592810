import { useMutation } from '@tanstack/react-query';

import { post } from 'src/utils/helpers/http';
import { useBaseAPIParams } from 'src/utils/hooks/useBaseAPIParams';

const UPLOAD_ID_URL = '/curbside/upload-id';

export type UseUploadIdMutationType = {
  Image: string;
  onError: (error: any) => void;
  onSuccess: (resp: any) => void;
  ShipmentId: number;
};

export function useUploadId({ Image, ShipmentId, onSuccess, onError }: UseUploadIdMutationType) {
  const baseAPIParams = useBaseAPIParams();

  return useMutation({
    mutationFn: async () => {
      const res = await post(UPLOAD_ID_URL, {
        ...baseAPIParams,
        Image,
        ShipmentId,
      });
      return res;
    },
    mutationKey: ['upload-id'],
    onSuccess,
    onError,
  });
}

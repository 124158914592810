import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';

import { type CartParams } from 'src/app/pages/cart';
import { post } from 'src/utils/helpers/http';
import { useBaseAPIParams } from 'src/utils/hooks/useBaseAPIParams';

import type { DeliveryInfo } from './types';

export const GetDeliveryInfo = 'get-delivery-info';

const GET_DELIVERY_INFO = '/curbside/get-delivery-info';

export function useDeliveryInfo() {
  const baseAPIParams = useBaseAPIParams();
  const { customerId, shipmentId } = useParams<CartParams>();

  return useQuery({
    queryKey: [GetDeliveryInfo, shipmentId],
    queryFn: async () => {
      const res = await post<DeliveryInfo>(GET_DELIVERY_INFO, {
        ...baseAPIParams,
        AcctId: Number(customerId),
        ShipmentId: Number(shipmentId),
      });
      return res;
    },
    enabled: !!(customerId && shipmentId),
  });
}

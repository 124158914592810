import { createSlice, type ActionReducerMapBuilder } from '@reduxjs/toolkit';

import type { Cart } from 'src/queries/curbside/types';

import {
  setActiveCart,
  setIsCheckoutComplete,
  setTotalAmountPaid,
  setChangeAmountDue,
  setIsDutchiePayError,
  setDutchiePayErrorMessage,
  setIsRethinkError,
  setRethinkErrorMessage,
  setSelectedLocation,
} from '../actions/MobileCheckoutActions';

export type LoggedInUser = {
  FullName: string;
  Id: number;
  LoginType: string;
  OnlineState: string;
  PatientAcct: number;
  SessionGId: string;
  SessionId: string;
  TraceId: string;
  UserName: string;
};

export type ActiveLocationType = {
  BioTrackIntegration: boolean;
  CompanyFlag: string;
  location_id: number;
  location_name: string;
  LspId?: any;
  registersAvailable: boolean;
};

export type MobileCheckoutState = {
  activeCart?: Cart | null;
  changeAmountDue?: number;
  dutchiePayErrorMessage?: string;
  isCheckoutComplete: boolean;
  isDutchiePayError: boolean;
  isRethinkError: boolean;
  rethinkErrorMessage?: string;
  totalAmountPaid?: number;
  // eslint-disable-next-line typescript-sort-keys/interface
  selectedLocation?: ActiveLocationType;
};

const initialState: MobileCheckoutState = {
  activeCart: undefined,
  isCheckoutComplete: false,
  totalAmountPaid: undefined,
  changeAmountDue: undefined,
  isDutchiePayError: false,
  dutchiePayErrorMessage: undefined,
  isRethinkError: false,
  rethinkErrorMessage: undefined,
};

export const mobileCheckoutSlice = createSlice({
  name: 'mobileCheckout',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<MobileCheckoutState>) => {
    builder.addCase(setActiveCart, (state: MobileCheckoutState, action) => {
      state.activeCart = action.payload;
    });
    builder.addCase(setIsCheckoutComplete, (state: MobileCheckoutState, action) => {
      state.isCheckoutComplete = action.payload;
    });
    builder.addCase(setTotalAmountPaid, (state: MobileCheckoutState, action) => {
      state.totalAmountPaid = action.payload;
    });
    builder.addCase(setChangeAmountDue, (state: MobileCheckoutState, action) => {
      state.changeAmountDue = action.payload;
    });
    builder.addCase(setIsDutchiePayError, (state: MobileCheckoutState, action) => {
      state.isDutchiePayError = action.payload;
    });
    builder.addCase(setDutchiePayErrorMessage, (state: MobileCheckoutState, action) => {
      state.dutchiePayErrorMessage = action.payload;
    });
    builder.addCase(setIsRethinkError, (state: MobileCheckoutState, action) => {
      state.isRethinkError = action.payload;
    });
    builder.addCase(setRethinkErrorMessage, (state: MobileCheckoutState, action) => {
      state.rethinkErrorMessage = action.payload;
    });
    builder.addCase(setSelectedLocation, (state: MobileCheckoutState, action) => {
      state.selectedLocation = action.payload;
    });
  },
});

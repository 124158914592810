import { useMutation, useQueryClient } from '@tanstack/react-query';

import { GetCart } from 'src/queries/curbside/get-cart';
import { post } from 'src/utils/helpers/http';
import { useBaseAPIParams } from 'src/utils/hooks/useBaseAPIParams';

import { transformToAddItemResponse } from './types';

import type { AddItemToShoppingCartResponse, AddItemResponse } from './types';

const ADD_ITEM_TO_SHOPPING_CART = 'v2/cart/add_item_to_shopping_cart';

export type AddItemToShoppingCartPayload = {
  availableOz: number;
  batchId?: number;
  cannabisProduct?: string;
  count: number;
  defaultLabelId?: number;
  defaultUnitId?: number;
  grouping: string;
  guestId: number;
  inventoryId?: number;
  isLoyaltyAsDiscountEnabled: boolean;
  isQuantityItem: boolean;
  isRunAutoDiscountEnabled: boolean;
  isRunAutoPriceEnabled: boolean;
  isUsingDaysSupply: boolean;
  packageItemId?: number | null;
  productDescription: string;
  productId: number;
  recUnitPrice: number;
  registerId: number;
  serialNo: string;
  shipmentId: number;
  unitPrice: number;
  weight: number;
};

const transformToServerPayload = (payload: AddItemToShoppingCartPayload) => ({
  AcctId: payload.guestId,
  AvailOz: payload.availableOz,
  BatchId: payload.batchId,
  CannbisProduct: payload.cannabisProduct,
  Cnt: payload.count,
  DefaultLabelId: payload.defaultLabelId,
  DefaultUnitId: payload.defaultUnitId,
  Grouping: payload.grouping,
  InventoryId: payload.inventoryId,
  LoyaltyAsDiscount: payload.isLoyaltyAsDiscountEnabled,
  PackageItemId: payload.packageItemId,
  ProductDesc: payload.productDescription,
  ProductId: payload.productId,
  QuantityItem: payload.isQuantityItem,
  RecUnitPrice: payload.recUnitPrice,
  Register: payload.registerId,
  RunAutoDiscount: payload.isRunAutoDiscountEnabled,
  RunAutoPrice: payload.isRunAutoPriceEnabled,
  SerialNo: payload.serialNo,
  ShipmentId: payload.shipmentId,
  UnitPrice: payload.unitPrice,
  UsingDaysSupply: payload.isUsingDaysSupply,
  Weight: payload.weight,
});

export const useAddItemToShoppingCart = () => {
  const queryClient = useQueryClient();
  const baseAPIParams = useBaseAPIParams();

  return useMutation({
    mutationFn: async (payload: AddItemToShoppingCartPayload): Promise<AddItemResponse> => {
      // Make the request
      const response = await post<AddItemToShoppingCartResponse>(ADD_ITEM_TO_SHOPPING_CART, {
        ...baseAPIParams,
        ...transformToServerPayload(payload),
      });

      return transformToAddItemResponse(response);
    },
    mutationKey: ['add-item-to-shopping-cart'],
    onSettled: (_data, error, payload) => {
      // Refetch the cart details
      void queryClient.invalidateQueries({ queryKey: [GetCart, payload.shipmentId] });

      if (error) {
        throw error;
      }
    },
  });
};

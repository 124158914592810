import { useMutation } from '@tanstack/react-query';

import { errorNotification } from 'src/store/actions/notifications-actions';
import { useAppSelector, useAppDispatch } from 'src/utils';
import { post } from 'src/utils/helpers/http';
import { useBaseAPIParams } from 'src/utils/hooks/useBaseAPIParams';

import { useActiveCustomer } from './use-carts';

const PAY_URL = '/v2/guest/Pay';

export type PaymentData = {
  Token: string;
};

type usePayType = {
  ElectronicPaid: string;
  ElectronicPaymentData?: PaymentData;
  ElectronicPaymentType: string;
  onSuccess: (resp: PayResponse) => void;
};

type PayResponse = {
  DebitPaid: number;
  IsPolling: boolean;
  MissingPaymentException: string;
  PaymentPollingIntervalseconds: number;
  TipAmount: number;
};

export function usePay({ ElectronicPaymentType, ElectronicPaid, ElectronicPaymentData, onSuccess }: usePayType) {
  const dispatch = useAppDispatch();
  const baseAPIParams = useBaseAPIParams();

  const { data: activeCustomer } = useActiveCustomer();
  const selectedRegisterId = useAppSelector((state) => state.user.selectedRegister?.id);

  return useMutation({
    mutationFn: async () =>
      await post<PayResponse>(PAY_URL, {
        ...baseAPIParams,
        ElectronicPaid,
        ElectronicPaymentData,
        ElectronicPaymentType,
        ShipmentId: activeCustomer?.ShipmentId,
        RecordAmountAsElectronicPayment: false,
        Register: selectedRegisterId,
        AppName: 'curbside',
        SupportsPolling: true,
      }),
    mutationKey: ['pay'],
    onError: () => dispatch(errorNotification('Error processing payment')),
    onSuccess,
  });
}

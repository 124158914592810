import { type FC, useState } from 'react';

import styled from 'styled-components';

import { DutchieCheckbox } from 'src/components/checkbox';
import { DutchieButton } from 'src/components/dutchie-button';
import { DutchieInput } from 'src/components/dutchie-input';
import { useBypassStateSystem } from 'src/queries/curbside/bypass-state-system';
import { successNotification, errorNotification } from 'src/store/actions/notifications-actions';
import { useAppDispatch } from 'src/utils';
import type { BaseAPIResponse } from 'src/utils/helpers/http';

import { LoadingScreen } from '../loading';

export const BypassDelivery: FC = () => {
  // Local state
  const [bypass, setBypass] = useState(false);
  const [managerPin, setManagerPin] = useState('');

  // Hooks
  const dispatch = useAppDispatch();

  // Computed
  const canBypass = !!bypass && !!managerPin;

  const { isPending: isBypassingStateSystem, mutate: bypassStateSystem } = useBypassStateSystem({
    ManagerPIN: managerPin,
    onSuccess: (resp: BaseAPIResponse<any>) => {
      if (resp.Result) {
        void dispatch(successNotification('State system bypassed'));
      } else {
        dispatch(errorNotification('Bypass failed'));
      }
    },
  });

  const handleBypass = async () => {
    if (canBypass) {
      bypassStateSystem();
    }
  };

  return (
    <>
      {/* TODO 57212: what about loading message here??? */}
      {/* loading message to display: 'Payment in Progress on Terminal Device' */}
      {isBypassingStateSystem && <LoadingScreen />}
      <InputRow>
        <DutchieCheckbox state={bypass ? 'on' : 'off'} onClick={() => setBypass(!bypass)} ddActionName={`Bypass State System checkbox (${!bypass ? 'on' : 'off'})`} />
        <InputRowField>
          <DutchieInput
            disabled={!bypass}
            endAdornment={
              <DutchieButton disabled={!canBypass} isAdornment onClick={handleBypass}>
                Bypass
              </DutchieButton>
            }
            placeholder='...'
            value={managerPin}
            onChange={(e) => setManagerPin(e.target.value)}
          />
        </InputRowField>
      </InputRow>
    </>
  );
};

export const InputRow = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-bottom: 1rem;
`;

export const InputRowField = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

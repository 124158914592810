import { useNavigate, useParams } from 'react-router';
import styled from 'styled-components';

import { Button } from '@dutchie-pos/components';

import { CartHeader } from 'src/app/components/checkout/cart-header';
import { CartSummary } from 'src/app/components/checkout/cart-order-summary';
import { CartOrderTotal } from 'src/app/components/checkout/cart-order-total';
import { ProvideSignature } from 'src/app/components/signature';
import { VerifyDOB } from 'src/app/components/verify-dob';
import { Chevron, ChevronDirections } from 'src/assets/chevron';

import { type CartParams } from '../cart';

import { CheckCard } from './components/check-card';
import { useCompleteChecks } from './use-complete-checks';

const CompleteChecksWithoutSignature = (): JSX.Element => {
  const navigate = useNavigate();
  const { customerId, shipmentId } = useParams<CartParams>();

  const {
    totals,
    showBirthdayPicker,
    checksToDisplay,
    driversLicenseImgRef,
    isAnyCheckRequired,
    handleSubmitDOB,
    handleCancelVerifyDOB,
    handleImageUpload,
    handleClickSelectPayment,
  } = useCompleteChecks();

  if (showBirthdayPicker) {
    return (
      <VerifyDOBContainer>
        <VerifyDOB onCancel={handleCancelVerifyDOB} onSubmit={handleSubmitDOB} />
      </VerifyDOBContainer>
    );
  }

  return (
    <Container>
      <CartHeaderContainer>
        <CartHeader onGoBack={() => navigate(`/cart/${shipmentId}/${customerId}`)} />
      </CartHeaderContainer>
      <ChecksContainer>
        {checksToDisplay.map((check) => (
          <CheckCard {...check} />
        ))}
      </ChecksContainer>
      <StickyFooter>
        <CartSummary displayNonCashFee={false} totals={totals} />
        <CartOrderTotal
          cash={0}
          change={0}
          debit={0}
          displayTotal={totals.total}
          paymentMethodFallback={false}
          remaining={0}
        />
        <ButtonContainer>
          <Button
            fullWidth
            label={<Chevron direction={ChevronDirections.LEFT} fill='var(--color-blue-80)' height={16} width={16} />}
            variant='outlineBlue'
            onClick={() => navigate(`/cart/${shipmentId}/${customerId}`)}
          />
          <Button disabled={isAnyCheckRequired} fullWidth label='Select payment' onClick={handleClickSelectPayment} />
        </ButtonContainer>
      </StickyFooter>
      <input
        accept='image/*'
        capture='environment'
        ref={driversLicenseImgRef}
        style={{ display: 'none' }}
        type='file'
        onChange={handleImageUpload}
      />
    </Container>
  );
};

export const CompleteChecks = () => (
  <ProvideSignature>
    <CompleteChecksWithoutSignature />
  </ProvideSignature>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const VerifyDOBContainer = styled(Container)`
  padding: 20px 24px 24px;
`;

const CartHeaderContainer = styled.div`
  flex: 0 0 auto;
  padding: 0 24px;
`;

const ChecksContainer = styled.div`
  flex: 1;
  position: relative;
  padding: 20px 24px 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StickyFooter = styled.div`
  position: sticky;
  bottom: 0;
  width: 100%;
  box-shadow: 0px -2px 4px 0px #00000014;
  padding: 20px 24px;
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Subtotal = styled.div`
  display: flex;
  max-width: 452px;
  justify-content: space-between;
  width: 100%;
  font-weight: 600;
`;

const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 0.2fr 1fr;
  gap: 16px;
`;

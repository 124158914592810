import { type ReactNode } from 'react';

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next, I18nextProvider as Provider } from 'react-i18next';

// import translationEnCa from '@backoffice/src/app_deprecated/utils/lang/locales/en-ca/translation.json';
// import translationEn from '@backoffice/src/app_deprecated/utils/lang/locales/en-us/translation.json';
// import translationEs from '@backoffice/src/app_deprecated/utils/lang/locales/es/translation.json';

void i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: 'en', // use en if detected lng is not available
    keySeparator: '>',
    nsSeparator: '|',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },

    resources: {
      // en: {
      //   translations: translationEn,
      // },
      // 'en-US': {
      //   translations: translationEn,
      // },
      // es: {
      //   translations: translationEs,
      // },
      // 'en-CA': {
      //   translations: translationEnCa,
      // },
    },
    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',
  });

export default i18n;

export function I18nextProvider({ children }: { children: ReactNode }) {
  return (
    <Provider defaultNS='translation' i18n={i18n}>
      {children}
    </Provider>
  );
}

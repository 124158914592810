import { useRef, useState, useEffect } from 'react';

import imageCompression from 'browser-image-compression';
import { useParams, useNavigate } from 'react-router';

import { useSignature } from 'mobile-checkout/src/app/components/signature';

import type { CartParams } from 'src/app/types/params';
import { useCart } from 'src/queries/curbside/get-cart';
import { useLocation } from 'src/queries/curbside/location-details';
import { useUploadId } from 'src/queries/curbside/upload-id';
import { useVerifyDOB } from 'src/queries/curbside/verify-dob';
import { successNotification, errorNotification } from 'src/store/actions/notifications-actions';
import { useAppDispatch } from 'src/utils';

type CheckToDisplay = {
  isCompleted: boolean;
  key: string;
  label: string;
  onClick: () => void;
};

export const useCompleteChecks = () => {
  // Local state
  const [validBirthday, setValidBirthday] = useState<boolean>(false);
  const [validDL, setValidDL] = useState<boolean>(false);
  const [validSignature, setValidSignature] = useState<boolean>(false);
  const [showBirthdayPicker, setShowBirthdayPicker] = useState(false);
  const [imageBytes, setImageBytes] = useState<string>();

  // Refs
  const driversLicenseImgRef = useRef<HTMLInputElement | null>(null);

  // Hooks
  const dispatch = useAppDispatch();
  const { shipmentId, customerId } = useParams<CartParams>();
  const navigate = useNavigate();
  const signature = useSignature();
  const { data: cart } = useCart({
    enabled: !!(customerId && shipmentId),
  });
  const { data: location } = useLocation();

  const { mutate: mutateVerifyDOB } = useVerifyDOB({
    onSuccess: () => {
      void dispatch(successNotification('Birthdate validated'));
      setValidBirthday(true);
      setShowBirthdayPicker(false);
    },
    onError: () => dispatch(errorNotification('Customer does not meet age requirements')),
  });

  const { mutate: uploadId } = useUploadId({
    ShipmentId: parseInt(shipmentId ?? '', 10),
    Image: imageBytes ?? '',
    onError: (e) => dispatch(errorNotification(`Failed to save DL: ${e}`)),
    onSuccess: (resp) => {
      void dispatch(successNotification('DL image saved'));
      setValidDL(true);
    },
  });

  useEffect(() => {
    if (imageBytes) {
      uploadId();
    }
  }, [imageBytes, uploadId]);

  useEffect(() => {
    if (signature.current.onSuccess === undefined) {
      signature.onSuccess(() => setValidSignature(true));
    }
  }, [signature]);

  // Computed
  const isVerifyAgeInMobileCheckoutFFEnabled = location?.Features.VerifyAgeInMobileCheckout;
  const isRequireSignatureMobileCheckoutFFEnabled = location?.Features.RequireSignatureMobileCheckout;
  const isRequireDLPhotoMobileCheckoutFFEnabled = location?.Features.RequireDLPhotoMobileCheckout;
  const subtotal = cart?.SubTotal;
  const checksToDisplay: CheckToDisplay[] = [];
  const isVerifyDOBRequired = isVerifyAgeInMobileCheckoutFFEnabled && !validBirthday;
  const isSignatureRequired = isRequireSignatureMobileCheckoutFFEnabled && !validSignature;
  const isDriversLicenseRequired = isRequireDLPhotoMobileCheckoutFFEnabled && !validDL;
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const isAnyCheckRequired = isVerifyDOBRequired || isSignatureRequired || isDriversLicenseRequired;
  const totals: PaymentFormTotals = {
    total: cart?.GrandTotalRounded ?? 0,
    subtotal: cart?.SubTotal ?? 0,
    tax: cart?.Tax ?? 0,
    discount: cart?.TotalDiscountAndLoyalty ?? 0,
    rounding: cart?.RoundingAmount ?? 0,
    feesDonations: cart?.FeesAndDonations ?? 0,
    changeDue: 0,
    totalPaid: 0,
    tip: cart?.TipAmount ?? 0,
    dutchiePayFees: 0,
    nonCashAdjustmentFees: 0,
    creditNonCashAdjustmentFees: 0,
  };

  // Handlers
  const handleClickVerifyDOB = () => {
    setShowBirthdayPicker(true);
  };

  const handleCancelVerifyDOB = () => {
    setShowBirthdayPicker(false);
  };

  const handleSubmitDOB = (dob: Date) => {
    mutateVerifyDOB({ shipmentId: Number(shipmentId), birthdate: dob });
  };

  const handleClickVerifySignature = () => {
    signature.show();
  };

  const handleClickVerifyDriversLicense = () => {
    driversLicenseImgRef?.current?.click();
  };

  const handleImageUpload = () => {
    const e = driversLicenseImgRef.current;
    const file = e?.files?.[0] ?? null;

    // Validate image file
    if (file === null) {
      return;
    }
    if (!file.type.includes('image')) {
      dispatch(errorNotification('Please upload an image file only'));
      return;
    }

    // Set up the file reader
    const reader = new FileReader();
    reader.onload = async (upload: ProgressEvent<FileReader>) => {
      if (!upload?.target?.result) {
        return;
      }

      const result = String(upload.target.result);
      const [imgMetadata, byteString] = result.split(',');

      if (!imgMetadata.includes('base64')) {
        return;
      }

      setImageBytes(byteString);
    };

    // Compress the image and call the file reader
    imageCompression(file, { maxSizeMB: 4 })
      .then((compressedFile) => reader.readAsDataURL(compressedFile))
      .catch((error) => dispatch(errorNotification(String(error))));
  };

  const handleClickSelectPayment = () => {
    if (!isAnyCheckRequired) {
      navigate(`/payment/${shipmentId}/${customerId}`);
    }
  };

  if (isVerifyAgeInMobileCheckoutFFEnabled) {
    checksToDisplay.push({
      key: 'verify-dob',
      label: 'Verify DOB',
      isCompleted: !isVerifyDOBRequired,
      onClick: handleClickVerifyDOB,
    });
  }

  if (isRequireSignatureMobileCheckoutFFEnabled) {
    checksToDisplay.push({
      key: 'customer-signature',
      label: 'Customer signature',
      isCompleted: !isSignatureRequired,
      onClick: handleClickVerifySignature,
    });
  }

  if (isRequireDLPhotoMobileCheckoutFFEnabled) {
    checksToDisplay.push({
      key: 'id-photo',
      label: 'Take photo of ID',
      isCompleted: !isDriversLicenseRequired,
      onClick: handleClickVerifyDriversLicense,
    });
  }

  return {
    totals,
    subtotal,
    showBirthdayPicker,
    checksToDisplay,
    driversLicenseImgRef,
    isAnyCheckRequired,
    handleSubmitDOB,
    handleCancelVerifyDOB,
    handleImageUpload,
    handleClickSelectPayment,
  };
};

import { PaymentType } from 'src/app/types/payments';
import { useLocation } from 'src/queries/curbside/location-details';

import { useAppSelector } from './useAppSelector';

export function useIsRethink(): boolean {
  const activeCart = useAppSelector((state) => state.mobileCheckout.activeCart);
  const isRethinkError = useAppSelector((state) => state.mobileCheckout.isRethinkError);

  const { data: location } = useLocation();
  const isUseRethinkIntegrationActive = location?.Features.UseReThink;

  return !!(
    (activeCart?.PreauthInfo?.PreauthAmount ?? 0) > 0 &&
    activeCart?.PreauthInfo?.PaymentType === PaymentType.Rethink &&
    isUseRethinkIntegrationActive &&
    !isRethinkError
  );
}

import styled from 'styled-components';

import { colors } from 'src/css/theme';

export const Container = styled.div`
  border-radius: 0.375rem;
  border: 1px solid ${colors.dutchie.borderGrey};
  padding: 16px 20px 16px 20px;
`;

export const ItemTitle = styled.div`
  color: ${colors.dutchie.almostBlack};
  font-size: 1rem;
  line-height: 170%;
  font-weight: 600;
  margin-bottom: 12px;
`;

export const BrandName = styled.div`
  color: ${colors.dutchie.grey35};
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 600;
  text-transform: uppercase;
`;

export const TopSection = styled.div`
  border-bottom: 1px solid ${colors.dutchie.gray20};
  padding-bottom: 16px;
  position: relative;
`;

export const BottomSection = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
  font-weight: 600;
`;

export const Quantity = styled.div``;

export const TrashContainer = styled.div`
  position: absolute;
  right: 0;
`;

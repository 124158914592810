import { useMemo, useState } from 'react';

import { Global, css } from '@emotion/react';
import { BrowserRouter } from 'react-router-dom';

import { ScreenLoader, ThemeProvider } from '@dutchie-pos/components';

import { App } from '../app/app';

import { DatadogProvider } from './providers/datadog';
import { ProvideDocumentViewer } from './providers/document-viewer';
import { I18nextProvider } from './providers/i18next';
import { LaunchDarklyRoot } from './providers/launch-darkly';
import { LogRocketProvider } from './providers/log-rocket';
import { LoggerProvider } from './providers/logger';
import { PendoProvider } from './providers/pendo';
import { ReactQueryProvider } from './providers/react-query';
import { ReduxProvider } from './providers/redux';

const datadogConfig = {
  applicationId: 'b71d53af-f911-49e8-823d-54eece74e681',
  clientToken: 'pub5e7cd45f39aafe641fc73e021a3243d5',
  service: 'mobile-checkout',
  allowedTracingUrls: [/https:\/\/.*\.mobile-checkout.dutchie\.com/, /http:\/\/localhost:\d+/],
};

const pendoApiKey = '28a64b1a-322b-48a4-6319-f6c7ad86b901';
const logRocketAppKey = 'zg2tcu/dutchie-mobile-checkout';

export const MAX_3RD_PARTY_WAIT_TIME = 5000;

export type AppReady = {
  datadog: boolean;
  launchdarkly: boolean;
  logrocket: boolean;
  pendo: boolean;
};

export function Root() {
  const [ready, setReady] = useState<AppReady>({
    datadog: false,
    pendo: false,
    logrocket: false,
    launchdarkly: false,
  });

  const appIsReady = useMemo(() => Object.values(ready).every(Boolean), [ready]);

  return (
    <BrowserRouter>
      <I18nextProvider>
        <ThemeProvider>
          <ReactQueryProvider>
            <PendoProvider apiKey={pendoApiKey} setReady={setReady} ready={ready}>
              <LogRocketProvider appKey={logRocketAppKey} setReady={setReady} ready={ready}>
                <DatadogProvider config={datadogConfig} setReady={setReady} ready={ready}>
                  <LaunchDarklyRoot setReady={setReady}>
                    <LoggerProvider>
                      <ReduxProvider>
                        <ProvideDocumentViewer>{appIsReady && <App />}</ProvideDocumentViewer>
                      </ReduxProvider>
                    </LoggerProvider>
                  </LaunchDarklyRoot>
                </DatadogProvider>
              </LogRocketProvider>
            </PendoProvider>
          </ReactQueryProvider>
          <ScreenLoader visible={!appIsReady} />
          <Global styles={mobileCheckoutStyleOverrides} />
        </ThemeProvider>
      </I18nextProvider>
    </BrowserRouter>
  );
}

const mobileCheckoutStyleOverrides = css`
  * {
    box-sizing: border-box;
  }

  #root {
    height: 100vh;
    width: 100vw;
    flex: 1 0;
    display: flex;
    flex-direction: column;
  }

  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 24px;
    font-size: 16px;
    box-sizing: border-box;
  }
`;

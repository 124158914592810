import React from 'react';

import { Tooltip as MUITooltip } from '@mui/material';

import { StyledTooltip } from './tooltip.styles';

import type { TooltipProps } from '@mui/material';

export const Tooltip = React.forwardRef<HTMLElement, TooltipProps & { disabled?: boolean }>((props, ref) => {
  const { children, className = '', disabled = false, ...other } = props;
  if (disabled) {
    return children;
  }
  return (
    <MUITooltip {...other} arrow className={className} PopperComponent={StyledTooltip} ref={ref}>
      {children}
    </MUITooltip>
  );
});

import styled from 'styled-components';

import { colors } from 'src/css/theme';
import { useInventoryLedger } from 'src/queries/v2/delivery/inventory-ledger';
import { useAppSelector } from 'src/utils';
import { useInventoryLedgerLD } from 'src/utils/hooks/launch-darkly/use-inventory-ledger';

export const InventoryLedger = () => {
  const selectedRoute = useAppSelector((state) => state.user.selectedRouteId);
  const { data: ledger } = useInventoryLedger({ routeId: selectedRoute || 0, enabled: !!selectedRoute });
  const useLedger = useInventoryLedgerLD();

  if (!useLedger || !ledger) {
    return null;
  }

  return (
    <>
      {ledger.CarInventory?.length > 0 && (
        <>
          <h3>Car Inventory</h3>
          <HeaderRow>
            <NameCol>Product</NameCol>
            <Col>Quantity</Col>
          </HeaderRow>
          {ledger.CarInventory?.map((item) => (
            <Row key={item.id}>
              <NameCol>
                <div>{item.ProductName}</div>
                <div>{item.SerialNumber}</div>
              </NameCol>
              <Col>
                {item.Quantity}
                {' ' + item.Unit}
              </Col>
            </Row>
          ))}
        </>
      )}
      {ledger.SoldInventory?.length > 0 && (
        <>
          <h3>Sold Inventory</h3>
          <HeaderRow>
            <NameCol>Product</NameCol>
            <Col>Quantity</Col>
          </HeaderRow>
          {ledger.SoldInventory?.map((item) => (
            <Row key={item.id}>
              <NameCol>
                <div>{item.ProductName}</div>
                <div>{item.SerialNumber}</div>
              </NameCol>
              <Col>
                {item.Quantity}
                {' ' + item.Unit}
              </Col>
            </Row>
          ))}
        </>
      )}
    </>
  );
};

const NameCol = styled.div`
  margin: 0.25rem;
  width: 75%;
`;

const Col = styled.div`
  margin: 0.5rem;
  width: 25%;
`;

const HeaderRow = styled.div`
  border-bottom: 1px ${colors.dutchie.borderGrey} solid;
  display: flex;
  justify-content: space-between;
  color: ${colors.dutchie.almostBlack};
  font-size: 1rem;
  line-height: 2rem;
  font-weight: 600;
`;

const Row = styled.div`
  &:nth-child(odd) {
    background: ${colors.dutchie.grey10};

  }
  background: 
  font-size: 1rem;
  line-height: 162%;
  display: flex;
  justify-content: space-between;
`;

import {
  useState,
  type ComponentType,
  type ReactNode,
  useEffect,
  useRef,
  type Dispatch,
  type SetStateAction,
} from 'react';

import { type datadogRum } from '@datadog/browser-rum';
import { asyncWithLDProvider, type AsyncProviderConfig } from 'launchdarkly-react-client-sdk';

import { useEnvironmentDetailsQuery } from '@dutchie-pos/requests/admin/use-environment-details-query';
import { type EnvironmentDetails } from '@dutchie-pos/types';
import { cleanLdKey } from '@dutchie-pos/utils';

import { type AppReady } from '..';

import { useDatadog } from './datadog';

async function createLaunchDarklyProvider(
  { LaunchDarklyClientKey, Region }: Partial<EnvironmentDetails>,
  datadog?: typeof datadogRum,
) {
  const ldProviderParam: AsyncProviderConfig = {
    clientSideID: LaunchDarklyClientKey ?? '',
    context: {
      kind: 'user',
      key: 'eng-internal',
      email: 'engineering@dutchie.com',
      region: Region ?? '',
      host: window.location.hostname,
    },
    reactOptions: {
      useCamelCaseFlagKeys: false,
      sendEventsOnFlagRead: true,
    },
  };

  if (!!datadog) {
    ldProviderParam.options = {
      inspectors: [
        {
          type: 'flag-used',
          name: 'dd-inspector',
          method: (key, detail) => {
            datadog.addFeatureFlagEvaluation(cleanLdKey(key), detail.value);
          },
        },
      ],
    };
  }

  // eslint-disable-next-line no-return-await
  return await asyncWithLDProvider(ldProviderParam);
}

export type LaunchDarklyProviderProps = {
  children: React.ReactNode;
  setReady: Dispatch<SetStateAction<AppReady>>;
};

function LaunchDarklyIdentity({ children, setReady }: LaunchDarklyProviderProps) {
  // use this to set identity
  const [ldReady, setLdReady] = useState(false);
  // const client = useLDClient();

  useEffect(() => {
    setLdReady(true);
    setReady((prev) => ({
      ...prev,
      launchdarkly: true,
    }));
  }, []);

  if (!ldReady) {
    return null;
  }

  return children;
}

// https://github.com/launchdarkly/react-client-sdk/blob/main/src/asyncWithLDProvider.tsx
export function LaunchDarklyRoot({ children, setReady }: LaunchDarklyProviderProps) {
  const [ldProviderCreated, setLdProviderCreated] = useState(false);
  const provider = useRef<ComponentType<{ children?: ReactNode }> | null>(null);
  const { data: envDetails, isLoading, error } = useEnvironmentDetailsQuery();
  const { datadog } = useDatadog();

  const isLoadingEnvDetails = isLoading || !envDetails?.Data;

  useEffect(() => {
    async function init() {
      if (envDetails?.Data && !provider.current && !ldProviderCreated) {
        provider.current = await createLaunchDarklyProvider(envDetails.Data, datadog);
        // we need to set the state to trigger a re-render
        setLdProviderCreated(true);
      }
    }
    void init();
  }, [envDetails?.Data, provider, ldProviderCreated, datadog]);

  const LaunchDarklyProvider = provider.current;

  return (
    <>
      {!isLoadingEnvDetails && LaunchDarklyProvider && ldProviderCreated && (
        <LaunchDarklyProvider>
          <LaunchDarklyIdentity setReady={setReady}>{children}</LaunchDarklyIdentity>
        </LaunchDarklyProvider>
      )}
      {!isLoading && error && <div>Unable to connect to /api</div>}
    </>
  );
}

import styled from 'styled-components';

import { colors } from 'src/css/theme';

import { CartItem } from './components/cart-item';
import { PreorderItem } from './components/preorder-item';
import { type CartDisplayItem, CartItemType } from './types';

type CartItemsProps = {
  cartItems: CartDisplayItem[];
};

export const CartItems = ({ cartItems }: CartItemsProps): JSX.Element => (
  <Container data-testid='cart-item-container'>
    <Header>Cart items</Header>
    {cartItems.length ? (
      <Items>
        {cartItems.map((item: CartDisplayItem) => {
          if (item.type === CartItemType.Preorder) {
            return <PreorderItem item={item} key={item.Id} />;
          }
          return <CartItem item={item} key={item.InventoryId} />;
        })}
      </Items>
    ) : (
      <Message>No items in cart</Message>
    )}
  </Container>
);

const Container = styled.div`
  padding-top: 10px;
`;

const Header = styled.div`
  font-weight: 600;
  font-size: 20px;
  position: sticky;
  top: 5rem;
  background-color: white;
  padding: 1rem 0rem;
  z-index: 2;
`;

const Items = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Message = styled.p`
  color: ${colors.dutchie.borderGrey};
  font-size: 1rem;
  line-height: 1.5rem;
`;

/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React from 'react';

import { Network } from '@capacitor/network';
import { useMutation } from '@tanstack/react-query';

import { PaymentType, PrepaymentType } from 'src/models/checkout';
import { useAppSelector } from 'src/utils';
import { post } from 'src/utils/helpers/http';
import { useBaseAPIParams } from 'src/utils/hooks/useBaseAPIParams';

import { useActiveCustomer } from './use-carts';

const CHECKOUT_URL = '/v2/guest/checkOut_Guest';

export type useCheckoutType = {
  onError?: (ex: any) => void;
  onSuccess?: (data: CheckoutResponse) => void;
  Request: CheckoutRequest;
};

export type CheckoutRequest = {
  CCPaid: number;
  DebitPaid: number;
  DueCustomer: number;
  GiftPaid: number;
  OrderId: number;
  Paidamt: number;
  PreAuth: number;
  PrepaymentType: string;
  SubTotal: number;
  TaxAmt: number;
  TotalCharges: number;
};

export type CheckoutResponse = {
  OverThreshold: boolean;
  ReesultTxt: string;
  ResultId: number;
};

export function useCheckout({ Request, onError, onSuccess }: useCheckoutType) {
  const activeCart = useAppSelector((state) => state.mobileCheckout.activeCart);
  const selectedRegisterId = useAppSelector((state) => state.user.selectedRegister?.id);
  const { data: activeCustomer } = useActiveCustomer();

  const baseAPIParams = useBaseAPIParams();

  return useMutation({
    mutationKey: ['checkout'],
    mutationFn: async () => {
      try {
        const resp = await post<CheckoutResponse[]>(CHECKOUT_URL, {
          ...baseAPIParams,
          ...Request,
          ...(Request.PrepaymentType === PrepaymentType.Rethink
            ? {
                PaymentMethods: [
                  {
                    amount: Request.CCPaid,
                    finalized: true,
                    id: activeCart?.PreauthInfo?.PreauthId,
                    name: PrepaymentType.Rethink,
                    tipAmount: activeCart?.TipAmount ?? 0,
                    type: PaymentType.RethinkEcomm,
                  },
                ],
              }
            : []),
          ...(Request.PrepaymentType === PrepaymentType.Dutchiepay
            ? {
                PaymentMethods: [
                  {
                    amount: Request.PreAuth,
                    id: activeCart?.PreauthInfo?.PreauthId,
                    name: PrepaymentType.Dutchiepay,
                    tipAmount: activeCart?.TipAmount ?? 0,
                    type: PaymentType.DutchiePay,
                  },
                ],
              }
            : []),
          ReactVersion: React.version,
          Data: activeCart?.PreauthInfo,
          TerminalId: selectedRegisterId,
          TransType: 'Cash',
          ShipmentId: activeCustomer?.ShipmentId,
          LoyaltyPoints: activeCart?.Loyalty?.AppliedLoyaltyPoints ?? 0,
          DiscountAmt: activeCart?.TotalDiscount,
          TipPaid: activeCart?.TipAmount ?? 0,
          TotalItems: activeCart?.TotalItems,
        });

        if (resp) {
          const data = resp ?? [];
          if (data.length > 0) {
            return data[0];
          }

          return await Promise.reject(new Error(`An error occurred. Please try again.`));
        } else {
          return Promise.reject(new Error(`${resp.Message ?? ''} ${resp.IntegrationMessage ?? ''}`));
        }
      } catch (ex: any) {
        const connectionStataus = await Network.getStatus();
        if (!connectionStataus?.connected) {
          throw new Error('No internet connection found.');
        }

        if (ex?.message) {
          const errorObject = JSON.parse(ex);
          throw new Error(`${errorObject.Message ?? ''} ${errorObject.IntegrationMessage ?? ''}`);
        }

        if (typeof ex === 'string') {
          throw new Error(ex);
        }

        throw ex;
      }
    },
    onError,
    onSuccess,
  });
}

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { GetCart } from 'src/queries/curbside/get-cart';
import { post } from 'src/utils/helpers/http';
import { useBaseAPIParams } from 'src/utils/hooks/useBaseAPIParams';

const REMOVE_ITEM_FROM_CART = 'v2/cart/Remove_item_from_cart';

export type RemoveItemFromCartPayload = {
  count?: number;
  guestId: number;
  inventoryId?: number;
  isLoyaltyAsDiscountEnabled: boolean;
  productId: number;
  registerId: number;
  serialNo: string;
  shipmentId: number;
};

const transformToServerPayload = (payload: RemoveItemFromCartPayload) => ({
  AcctId: payload.guestId,
  Cnt: payload.count,
  LoyaltyAsDiscount: payload.isLoyaltyAsDiscountEnabled,
  ProductId: payload.productId,
  Register: payload.registerId,
  SerialNo: payload.serialNo,
  ShipmentId: payload.shipmentId,
});

export const useRemoveItemFromCart = () => {
  const queryClient = useQueryClient();
  const baseAPIParams = useBaseAPIParams();

  return useMutation({
    mutationFn: async (payload: RemoveItemFromCartPayload) => {
      await post(REMOVE_ITEM_FROM_CART, {
        ...baseAPIParams,
        ...transformToServerPayload(payload),
      });
    },
    onSettled: (_data, error, payload) => {
      void queryClient.invalidateQueries({ queryKey: [GetCart, payload.shipmentId] });

      if (error) {
        throw error;
      }
    },
  });
};

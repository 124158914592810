import { useMutation } from '@tanstack/react-query';

import { errorNotification } from 'src/store/actions/notifications-actions';
import { useAppDispatch } from 'src/utils';
import { post } from 'src/utils/helpers/http';
import type { BaseAPIResponse } from 'src/utils/helpers/http';
import { useBaseAPIParams } from 'src/utils/hooks/useBaseAPIParams';

import { useActiveCustomer } from './use-carts';

const BYPASS_STATE_SYSTEM = '/v2/cart/bypass-state-system';

type BypassStateSystemType = {
  ManagerPIN: string;
  onSuccess: (resp: BaseAPIResponse<any>) => void;
};

export function useBypassStateSystem({ ManagerPIN, onSuccess }: BypassStateSystemType) {
  const { data: activeCustomer } = useActiveCustomer();
  const dispatch = useAppDispatch();
  const baseAPIParams = useBaseAPIParams();

  return useMutation({
    mutationFn: async () =>
      await post<BaseAPIResponse<any>>(BYPASS_STATE_SYSTEM, {
        ...baseAPIParams,
        ManagerPIN,
        ShipmentId: activeCustomer?.ShipmentId ?? 0,
      }),
    mutationKey: ['bypass-state-system'],
    onSuccess,
    onError: () => dispatch(errorNotification('Bypass failed')),
  });
}

export type AddItemToShoppingCartResponse = {
  InvId: number;
  PackageItemId: number;
};

export type AddItemResponse = ReturnType<typeof transformToAddItemResponse>;

export const transformToAddItemResponse = (response: AddItemToShoppingCartResponse) => ({
    inventoryId: response.InvId,
    packageItemId: response.PackageItemId,
  });

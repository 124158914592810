import { useEffect } from 'react';

import * as Cookies from 'es-cookie';
import { Route, Navigate, Routes, Outlet, useNavigate, useLocation } from 'react-router-dom';

import { useUser } from 'src/queries/curbside/user-details';
import { resetUser, setUser } from 'src/store/slices/user';
import { useAppDispatch, useAppSelector } from 'src/utils';

import { Layout } from './components/layout';
import { Cart } from './pages/cart';
import { CartList } from './pages/cart-list';
import { CompleteChecks } from './pages/complete-checks';
import { Delivery } from './pages/delivery';
import { DeliveryRoute } from './pages/delivery-route';
import { InventoryLedger } from './pages/inventory-ledger';
import { Login } from './pages/login';
import { Payment } from './pages/payment';
import { Restock } from './pages/restock';
import { SelectLocation } from './pages/select-location';
import { SelectPath } from './pages/select-path';
import { SelectRegister } from './pages/select-register';

type SessionInfo = {
  FullName: string;
  Id: string;
  LoginType: string;
  OnlineStatus: string;
  PatientAcct: string;
  SessionGid: string;
  SessionId: string;
};

export function App() {
  const navigate = useNavigate();
  const session = useAppSelector((state) => state.user.session);
  const user = useUser();
  const dispatch = useAppDispatch();
  const isSamlLogin = window.location.search.toLowerCase().indexOf('samllogin=true') > 0;

  // not a huge fan of this...
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (!session && pathname.toLowerCase() !== '/ssologin') {
      navigate('/login');
    }
  }, [navigate, session, pathname]);

  useEffect(() => {
    if (user.error) {
      dispatch(resetUser());
    }
  }, [user.error]);

  useEffect(() => {
    if (isSamlLogin) {
      const sessionInfoFromCookie: SessionInfo = JSON.parse(Cookies.get('LeafLogixSessionInfo') ?? '{}');
      window.sessionStorage.setItem('SessionId', sessionInfoFromCookie.SessionId);
      window.sessionStorage.setItem('SessionGId', sessionInfoFromCookie.SessionGid);
      window.sessionStorage.setItem('UserId', sessionInfoFromCookie.Id);
      window.sessionStorage.setItem('FullName', sessionInfoFromCookie.FullName);
      window.sessionStorage.setItem('UserName', sessionInfoFromCookie.Id);

      dispatch(
        setUser({
          SessionGId: sessionInfoFromCookie.SessionGid,
          Id: parseInt(sessionInfoFromCookie.Id, 10),
        }),
      );
    }
  }, [dispatch, isSamlLogin]);

  return (
    <Routes>
      <Route
        element={
          <Layout>
            <Outlet />
          </Layout>
        }
      >
        <Route element={<SelectLocation />} path='/locations' />
        <Route element={<SelectPath />} path='/paths' />
        <Route element={<SelectRegister />} path='/registers' />
        <Route element={<CartList />} path='/carts' />
        <Route element={<Delivery />} path='/route/delivery' />
        <Route element={<DeliveryRoute />} path='/route/delivery/:routeId' />
        <Route element={<Cart />} path='/cart/:shipmentId/:customerId' />
        <Route element={<CompleteChecks />} path='/checks/:shipmentId/:customerId' />
        <Route element={<Payment />} path='/payment/:shipmentId/:customerId' />
        <Route element={<Restock />} path='/restock' />
        <Route element={<InventoryLedger />} path='/route/delivery/:routeId/inventory-ledger' />
        <Route element={<Navigate to='/locations' />} path='*' />
      </Route>
      <Route element={<Login />} path='/login' />
      <Route element={<Login isSSO={true} />} path='/ssologin' />
    </Routes>
  );
}

import { createReducer, type ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { showConfirmRemoveItemPopup, closePopup } from '../actions/popups-actions';

export type PopupsPayloads = {
  showConfirmRemoveItemPopup: { onConfirm: () => Promise<void> };
};

export type PopupsState = {
  confirmRemoveItemPopup: { onConfirm: () => Promise<void> } | null;
};

export const popupsInitialState: PopupsState = {
  confirmRemoveItemPopup: null,
};

export const popupsReducer = createReducer(popupsInitialState, (builder: ActionReducerMapBuilder<PopupsState>) => {
  builder.addCase(showConfirmRemoveItemPopup, (_state, action) => ({
    ...popupsInitialState,
    confirmRemoveItemPopup: action.payload,
  }));
  builder.addCase(closePopup, (state, action) => {
    if (Object.keys(popupsInitialState).includes(action.payload)) {
      return {
        ...state,
        [action.payload]: null,
      };
    } 
    
    return state;
  });
});

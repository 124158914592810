import { useBarcodeReader } from './use-barcode-reader';

export type BarcodeReaderProps = {
  onScan: (scannedString: string) => Promise<void>;
};

export const BarcodeReader = ({ onScan }: BarcodeReaderProps): JSX.Element | null => {
  const { hostElemRef } = useBarcodeReader({ onScan, qrCode: false });

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div ref={hostElemRef} style={{ position: 'relative', height: '214px' }} />
      <div className='actions' />
    </div>
  );
};

export const QRCodeReader = ({ onScan }: BarcodeReaderProps): JSX.Element | null => {
  const { hostElemRef } = useBarcodeReader({ onScan, qrCode: true });

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div ref={hostElemRef} style={{ position: 'relative', height: '428px' }} />
      <div className='actions' />
    </div>
  );
};

import { type FC, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { DutchieButton } from 'src/components/dutchie-button';
import { DutchieInput } from 'src/components/dutchie-input';
import { colors } from 'src/css/theme';
import { type PaymentData, usePay } from 'src/queries/curbside/pay';
import { setActiveCart } from 'src/store/actions/MobileCheckoutActions';
import { errorNotification } from 'src/store/actions/notifications-actions';
import { useAppSelector, useAppDispatch } from 'src/utils';

import { LoadingScreen } from '../loading';

type ICanPayCheckoutProps = {
  amount: number;
  onPay: () => void;
};

type PayReq = {
  ElectronicPaid: string;
  ElectronicPaymentData: PaymentData;
};

export const CanPayCheckout: FC<ICanPayCheckoutProps> = (props) => {
  // Global state
  const selectedRegister = useAppSelector((state) => state.user.selectedRegister?.id);

  // Local state
  const [pin, setPin] = useState('');
  const [showPin, setShowPin] = useState(false);
  const [payReq, setPayReq] = useState<PayReq>();

  // Hooks
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { mutate: pay, isPending: isPaying } = usePay({
    ...payReq!, // TODO: sus
    ElectronicPaymentType: 'canpay',
    onSuccess: (resp) => {
      if (resp) {
        props.onPay();
      }
    },
  });

  useEffect(() => {
    pay();
  }, [payReq, pay]);

  const handlePay = async () => {
    if (!selectedRegister || selectedRegister === 0) {
      dispatch(errorNotification('No register selected, please select a register'));
      dispatch(setActiveCart(null));
      navigate(-1);
    }

    setPayReq({
      ElectronicPaid: props.amount.toFixed(2),
      ElectronicPaymentData: { Token: pin },
    });
  };

  return (
    <>
      {isPaying && <LoadingScreen />}
      <InputRow>
        <InputRowLabel>Pin:</InputRowLabel>
        <InputRowField>
          <DutchieInput
            autoComplete='false'
            endAdornment={
              <DutchieButton isAdornment onClick={() => setShowPin(!showPin)}>
                {showPin ? 'Hide' : 'Show'}
              </DutchieButton>
            }
            placeholder='...'
            type={showPin ? 'text' : 'password'}
            value={pin}
            onChange={(e) => setPin(e.target.value)}
          />
        </InputRowField>
      </InputRow>
      <ActionButton disabled={pin === ''} onClick={handlePay}>
        Pay
      </ActionButton>
    </>
  );
};

const InputRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  margin-bottom: 1rem;
`;

const InputRowLabel = styled.div<{ alignRight?: boolean }>`
  flex: 0 0 3rem;
  width: 3rem;
  color: ${colors.dutchie.grey30};
  font-size: 0.875rem;
  line-height: 1.25rem;
  display: flex;
  align-items: center;
`;

const InputRowField = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
`;

const ActionButton = styled(DutchieButton)`
  height: 5rem;
  font-size: 1.25rem;
  font-weight: 700;
  margin: 2rem 0;
`;

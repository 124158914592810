import { useQuery } from '@tanstack/react-query';

import { setActiveCart } from 'src/store/actions/MobileCheckoutActions';
import { useAppSelector, useAppDispatch } from 'src/utils';
import { post } from 'src/utils/helpers/http';
import { useBaseAPIParams } from 'src/utils/hooks/useBaseAPIParams';

export const PollPayment = 'poll-payment';

const POLL_PAYMENT_URL = '/payment/poll-payment';

type usePollPaymentType = {
  ElectronicPaymentType: string;
  ShipmentId: number;
};

type ElectronicPaymentResponse = {
  CreditPaid: number;
  DebitPaid: number;
  PaymentSuccess: boolean;
  PollingComplete: boolean;
  PollingError: string;
  TipAmount?: number;
};

export function usePollPaymentQuery({ ElectronicPaymentType, ShipmentId }: usePollPaymentType) {
  const dispatch = useAppDispatch();
  const baseAPIParams = useBaseAPIParams();
  const activeCart = useAppSelector((state) => state.mobileCheckout.activeCart);

  return useQuery({
    queryKey: [PollPayment, ShipmentId],
    queryFn: async () => {
      const resp = await post<ElectronicPaymentResponse>(POLL_PAYMENT_URL, {
        ...baseAPIParams,
        ShipmentId,
        ElectronicPaymentType,
      });

      if (resp.PollingComplete) {
        if (resp.PaymentSuccess) {
          dispatch(setActiveCart({ ...activeCart, TipAmount: resp.TipAmount }));
          return resp;
        }
        // TODO: this needs to be implemented in payment-form.tsx
        // setErrMsg(resp.PollingError);
        return await Promise.resolve();
      }

      // If we aren't done or got an error, retry
      return await Promise.reject();
    },
    enabled: false,
    retryDelay: 5000,
    retry: 24,
    refetchInterval: false,
  });
}

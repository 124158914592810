import { useQuery } from '@tanstack/react-query';

import { environmentDetailsQuery } from './environment-details-query';

export function useEnvironmentDetailsQuery() {
  return useQuery({
    queryKey: ['/api/admin/environment-details'],
    queryFn: async () => {
      return await environmentDetailsQuery();
    },
  });
}

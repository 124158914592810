import { useEffect, useMemo, useState } from 'react';

import { useNavigate, useParams } from 'react-router';
import styled from 'styled-components';

import { Button } from '@dutchie-pos/components';

import { CartHeader } from 'src/app/components/checkout/cart-header';
import { CartItems } from 'src/app/components/checkout/cart-items';
import { ScanBarcodeDrawer } from 'src/app/components/checkout/cart-items/components/scan-barcode-drawer';
import { useAddScanItem } from 'src/app/components/checkout/cart-items/components/scan-barcode-drawer/use-add-scanned-item';
import type { CartDisplayItem } from 'src/app/components/checkout/cart-items/types';
import { getCartItems, getPreorderItems } from 'src/app/components/checkout/cart-items/utils';
import { Popups } from 'src/app/components/popups';
import { Chevron } from 'src/assets/chevron';
import { BottomDrawer } from 'src/components/bottom-drawer';
import { CashAmount } from 'src/components/cash-amount';
import { useCart } from 'src/queries/curbside/get-cart';
import { useLocation } from 'src/queries/curbside/location-details';
import { useScannedItem } from 'src/queries/v2/product/scan-product';
import { closeDrawer } from 'src/store/actions/drawers-actions';
import { Path } from 'src/store/slices/user';
import { useAppSelector, useAppDispatch } from 'src/utils';
import { logger, customEventKeys } from 'src/utils/logger';

import { SkipDeliveryDrawer } from './skip-delivery-drawer';

export type CartParams = {
  customerId: string;
  shipmentId: string;
};

export const Cart = (): JSX.Element => {
  // Global state
  const selectedRouteId = useAppSelector((state) => state.user.selectedRouteId);
  const selectedPath = useAppSelector((state) => state.user.selectedPath);
  const confirmRemoveItemDrawer = useAppSelector((state) => state.drawers.confirmRemoveItemDrawer);

  // Local state
  const [isScanBarcodeDrawerOpen, setIsScanBarcodeDrawerOpen] = useState<boolean>(false);
  const [scannedString, setScannedString] = useState<string>('');
  const [warnWhenAddingItemModalOpen, setWarnWhenAddingItemModalOpen] = useState<boolean>(false);
  const [showSkipModal, setShowSkipModal] = useState<boolean>(false);
  const [scannedItemIsLoading, setScannedItemIsLoading] = useState<boolean>(false);

  // Hooks
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { shipmentId, customerId } = useParams<CartParams>();
  const { data: cart } = useCart();
  const { data: location } = useLocation();
  const { mutateAsync: getScannedItem } = useScannedItem();

  useEffect(() => {
    if (cart && customerId) {
      logger.info(`cart ${shipmentId} loaded for customer ${customerId}`, {
        key: customEventKeys.cart.loaded,
        cart,
        customerId,
        shipmentId,
      });
    }
  }, [cart, customerId]);

  // Computed
  const warnWhenAddingItem = location?.Features.AddWarningWhenAddingItemToPreOrder;
  const isVerifyAgeInMobileCheckoutFFEnabled = location?.Features.VerifyAgeInMobileCheckout;
  const isRequireSignatureMobileCheckoutFFEnabled = location?.Features.RequireSignatureMobileCheckout;
  const isRequireDLPhotoMobileCheckoutFFEnabled = location?.Features.RequireDLPhotoMobileCheckout;
  const isAddItemsFFEnabled = !!location?.Features.AddItemsInMobileCheckout;
  const isValidationNeeded = !!(
    isVerifyAgeInMobileCheckoutFFEnabled ??
    isRequireSignatureMobileCheckoutFFEnabled ??
    isRequireDLPhotoMobileCheckoutFFEnabled
  );

  const cartItemsToDisplay: CartDisplayItem[] = useMemo(() => {
    const cartItems = cart?.Cart ?? [];
    const preorderItems = cart?.PreOrders ?? [];

    const preorderItemsToDisplay = getPreorderItems(preorderItems, cartItems); // Includes unfulfilled AND fulfilled preorder items
    const cartItemsToDisplay = getCartItems(preorderItems, cartItems); // Includes only cart items that were never part of a preorder

    return preorderItemsToDisplay.concat(cartItemsToDisplay);
  }, [cart?.Cart, cart?.PreOrders]);

  const hasNoItemsInCart = cartItemsToDisplay.length === 0;

  const isActionButtonDisabled = hasNoItemsInCart;

  const handleClickVerify = () => {
    if (isValidationNeeded) {
      navigate(`/checks/${shipmentId}/${customerId}`);
    } else {
      navigate(`/payment/${shipmentId}/${customerId}`);
    }
  };

  const { handleScan } = useAddScanItem({ onClose: () => setIsScanBarcodeDrawerOpen(false) });

  async function handleWarnWhenAddingItem(scannedString: string) {
    logger.debug(`item barcode scanned: ${scannedString}`, {
      key: customEventKeys.scanning.scanReceived,
      type: 'item',
      scannedString,
    });

    setScannedItemIsLoading(true);
    const hasPreOrders = cart?.PreOrders?.length;
    const currentPreOrderStateArr = cart?.PreOrders.map((item) => ({
      ...item,
      QtySelected:
        item.QtySelected -
        cart.Cart.filter((cartItem) => cartItem.ProductId === item.ProductId).reduce(
          (amount, cartItem) => amount + (cartItem.WgtCnt === 'Wgt' ? cartItem.Grams ?? 0 : cartItem.QtySelected),
          0,
        ),
    })).filter((item) => item.QtySelected > 0);

    // note: not a fan of this implementation, should look to refactor
    let foundItem;
    try {
      const [result] = await getScannedItem(scannedString);
      foundItem = result;
    } catch (e) {
      logger.error(e, { message: 'failed to get scanned item' });

      setScannedItemIsLoading(false);
      setIsScanBarcodeDrawerOpen(false);
      return;
    }

    const isItemInCurrentPreOrderState =
      currentPreOrderStateArr?.length === 0
        ? false
        : currentPreOrderStateArr?.find((preorder) => preorder.ProductId === foundItem.ProductId);

    if (warnWhenAddingItem && hasPreOrders && !isItemInCurrentPreOrderState) {
      setScannedString(scannedString);
      setIsScanBarcodeDrawerOpen(false);
      setWarnWhenAddingItemModalOpen(true);
    } else {
      await handleScan(scannedString);
    }
    setScannedItemIsLoading(false);
  }

  const goBackRoute = selectedPath === Path.Delivery ? `/route/delivery/${selectedRouteId}` : '/carts';

  return (
    <>
      <Popups />
      <Container>
        <CartHeaderContainer>
          <CartHeader onGoBack={() => navigate(goBackRoute)} />
        </CartHeaderContainer>
        <CartItemsContainer>
          <CartItems cartItems={cartItemsToDisplay} />
        </CartItemsContainer>
        <StickyFooter>
          <Subtotal>
            <div>Total</div>
            <CashAmount value={cart?.GrandTotalRounded} />
          </Subtotal>
          <ButtonContainer cols={isAddItemsFFEnabled && selectedPath === Path.Delivery ? 3 : 2}>
            {selectedPath === Path.Delivery && (
              <Button fullWidth label='Skip' variant='outlineBlue' onClick={() => setShowSkipModal(true)} />
            )}
            {isAddItemsFFEnabled && (
              <Button
                fullWidth
                label='Scan barcode'
                variant='outlineBlue'
                onClick={() => setIsScanBarcodeDrawerOpen(true)}
              />
            )}
            <Button
              disabled={isActionButtonDisabled}
              endIcon={<Chevron fill='white' height={16} width={16} />}
              fullWidth
              label={isValidationNeeded ? 'Next' : 'Select payment'}
              onClick={handleClickVerify}
            />
          </ButtonContainer>
        </StickyFooter>
        <BottomDrawer
          height={300}
          marginX={0}
          open={isScanBarcodeDrawerOpen}
          onClose={() => setIsScanBarcodeDrawerOpen(false)}
        >
          {isScanBarcodeDrawerOpen && (
            <ScanBarcodeDrawer
              isLoading={scannedItemIsLoading}
              onClose={() => setIsScanBarcodeDrawerOpen(false)}
              onScan={handleWarnWhenAddingItem}
            />
          )}
        </BottomDrawer>
        <BottomDrawer
          height={600}
          marginX={0}
          open={warnWhenAddingItemModalOpen}
          onClose={() => setWarnWhenAddingItemModalOpen(false)}
        >
          <WarnContainer>
            <h2>Warning</h2>
            Item is not in the customer's order. Are you sure you want to add item?
            <WarnButtonContainer>
              <Button
                fullWidth
                label='Cancel'
                variant='outlineBlue'
                onClick={() => setWarnWhenAddingItemModalOpen(false)}
              />
              <Button
                fullWidth
                label='Add item'
                onClick={async () => {
                  await handleScan(scannedString);
                  setWarnWhenAddingItemModalOpen(false);
                }}
              />
            </WarnButtonContainer>
          </WarnContainer>
        </BottomDrawer>
        <BottomDrawer
          height={600}
          marginX={0}
          open={!!confirmRemoveItemDrawer}
          onClose={() => dispatch(closeDrawer('confirmRemoveItemDrawer'))}
        >
          <WarnContainer data-testid='confirm-remove-item-drawer'>
            <h2>Warning</h2>
            Are you sure you want to remove this item?
            <WarnButtonContainer>
              <Button
                fullWidth
                label='Cancel'
                variant='outlineBlue'
                onClick={() => dispatch(closeDrawer('confirmRemoveItemDrawer'))}
              />
              <Button
                fullWidth
                label='Remove item'
                onClick={async () => {
                  await confirmRemoveItemDrawer?.onConfirm();
                  dispatch(closeDrawer('confirmRemoveItemDrawer'));
                }}
              />
            </WarnButtonContainer>
          </WarnContainer>
        </BottomDrawer>
        <SkipDeliveryDrawer open={showSkipModal} onClose={() => setShowSkipModal(false)} />
      </Container>
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const CartHeaderContainer = styled.div`
  flex: 0 0 auto;
  padding: 0px 24px;
`;

const CartItemsContainer = styled.div`
  flex: 1;
  position: relative;
  padding: 0px 24px 24px;
`;

const StickyFooter = styled.div`
  position: sticky;
  bottom: 0;
  width: 100%;
  box-shadow: 0px -2px 4px 0px #00000014;
  padding: 20px 24px;
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Subtotal = styled.div`
  display: flex;
  max-width: 452px;
  justify-content: space-between;
  width: 100%;
  font-weight: 600;
`;

const ButtonContainer = styled.div<{ cols: number }>`
  display: grid;
  grid-template-columns: ${({ cols }) => {
    switch (cols) {
      case 1:
        return '1fr';
      case 2:
        return '1fr 1fr';
      case 3:
        return '.2fr 1fr 1fr';
      default:
        return '1fr 1fr';
    }
  }};
  gap: 16px;
`;

const WarnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 1.25rem;
`;

const WarnButtonContainer = styled.div`
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
`;

import * as React from "react";
const SvgCheckboxOff = (props) => /* @__PURE__ */ React.createElement("svg", { width: "100%", height: "100%", viewBox: "0 0 16 16", xmlns: "http://www.w3.org/2000/svg", style: {
  fillRule: "evenodd",
  clipRule: "evenodd",
  strokeLinejoin: "round",
  strokeMiterlimit: 2
}, ...props }, /* @__PURE__ */ React.createElement("g", { transform: "matrix(0.953984,0,0,0.953984,0.368131,0.368131)" }, /* @__PURE__ */ React.createElement("path", { d: "M0,3C0,1.343 1.343,0 3,0L13,0C14.657,0 16,1.343 16,3L16,13C16,14.657 14.657,16 13,16L3,16C1.343,16 0,14.657 0,13L0,3Z", style: {
  fill: "white",
  fillRule: "nonzero"
} })), /* @__PURE__ */ React.createElement("g", { transform: "matrix(0.889379,0,0,0.889379,0.884968,0.884968)" }, /* @__PURE__ */ React.createElement("path", { d: "M3,1L13,1L13,-1L3,-1L3,1ZM15,3L15,13L17,13L17,3L15,3ZM13,15L3,15L3,17L13,17L13,15ZM1,13L1,3L-1,3L-1,13L1,13ZM3,15C1.895,15 1,14.105 1,13L-1,13C-1,15.209 0.791,17 3,17L3,15ZM15,13C15,14.105 14.105,15 13,15L13,17C15.209,17 17,15.209 17,13L15,13ZM13,1C14.105,1 15,1.895 15,3L17,3C17,0.791 15.209,-1 13,-1L13,1ZM3,-1C0.791,-1 -1,0.791 -1,3L1,3C1,1.895 1.895,1 3,1L3,-1Z", style: {
  fill: "rgb(160,168,171)",
  fillRule: "nonzero"
} })));
export default SvgCheckboxOff;

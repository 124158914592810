import { type QueryKey, useQuery } from '@tanstack/react-query';

import { getReq } from 'src/utils/helpers/http';

export const SSOLoginEnabledKey: QueryKey = ['sso-login-enabled'];

const SSO_LOGIN_ENABLED_URL = '/api/saml/SSOLoginEnabled';

export function useSSOLoginEnabledQuery() {
  return useQuery({
    queryKey: SSOLoginEnabledKey,
    queryFn: async () => {
      const res = await getReq<{ isSSOLoginEnabled: boolean }>(SSO_LOGIN_ENABLED_URL, { ignoreFormat: true });
      return res?.isSSOLoginEnabled;
    },
  });
}

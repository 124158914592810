import { useQuery } from '@tanstack/react-query';

import { post } from 'src/utils/helpers/http';
import { useBaseAPIParams } from 'src/utils/hooks/useBaseAPIParams';

export const GetRegistersKey = 'get-registers';

const GET_REGISTERS_URL = '/api/posv3/registers/get';

export type RegisterInfo = {
  AllowDeliveryReceipts: boolean;
  AutoPrintFulfillmentTicket: boolean;
  AutoPrintLabels: boolean;
  AutoPrintReceipts: boolean;
  DefaultLabelId?: any;
  DrawerStatusId: number;
  FulfillmentPrinterId?: number;
  id: number;
  LabelPrinterId?: any;
  PopCashDrawer: boolean;
  PopUpNotesOnEntry: boolean;
  ReceiptPrinterId?: number;
  RestrictToScaleWeighing: boolean;
  RoomId: number;
  RoomNo: string;
  TerminalName: string;
};

export function useRegisters() {
  const baseAPIParams = useBaseAPIParams();

  return useQuery({
    queryKey: [GetRegistersKey, baseAPIParams.LocId],
    queryFn: async () => {
      const res = await post<RegisterInfo[]>(GET_REGISTERS_URL, baseAPIParams);
      return res;
    },
  });
}

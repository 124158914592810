import { useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router';

import { post } from 'src/utils/helpers/http';
import { useBaseAPIParams } from 'src/utils/hooks/useBaseAPIParams';

const SKIP_DELIVERY_URL = '/curbside/skip-delivery';

export type SkipDeliveryMutationType = {
  onError?: () => void;
  onSuccess?: (resp: any) => void;
};

export function useSkipDeliveryMutation({ onError, onSuccess }: SkipDeliveryMutationType | undefined = {}) {
  const { shipmentId } = useParams();
  const baseAPIParams = useBaseAPIParams();

  return useMutation({
    mutationFn: async (reason?: string) => {
      const res = await post(SKIP_DELIVERY_URL, {
        ...baseAPIParams,
        ShipmentId: shipmentId,
        Reason: reason,
      });
      return res;
    },
    mutationKey: ['skip-delivery'],
    onError,
    onSuccess,
  });
}

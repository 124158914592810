import React from 'react';

import { useNavigate } from 'react-router';

import { QRCodeReader } from 'src/app/components/barcode-reader/barcode-reader';
import { ContentTitle } from 'src/app/components/content-title';
import { useRestockMutation } from 'src/queries/curbside/restock';
import { successNotification, errorNotification } from 'src/store/actions/notifications-actions';
import { useAppDispatch, useAppSelector } from 'src/utils/hooks/index';

export type LocationUseMethodType = 'Curbside' | 'Delivery' | null;

export const Restock = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const selectedRegister = useAppSelector((state) => state.user.selectedRegister?.id);
  const doRestock = useRestockMutation({
    onError: (e) => dispatch(errorNotification(`Failed to restock: ${e}`)),
    onSuccess: () => {
      void dispatch(successNotification('Restocked successfully'));
      navigate(`/`);
    },
  });

  const onScan = async (scannedString: string) => {
    const data = JSON.parse(scannedString);
    doRestock.mutate({
      RegisterId: selectedRegister ?? 0,
      RoomId: data.RoomId,
      SubRoomId: data.SubRoomId,
    });
  };

  const goBack = () => {
    navigate(`/route/delivery`);
  };

  return (
    <div>
      <ContentTitle data-testid='select-list-header-name' onGoBack={goBack}>
        Scan Kit
      </ContentTitle>
      <QRCodeReader onScan={onScan} />
    </div>
  );
};

import { type FC } from 'react';

import { orderBy } from 'lodash-es';
import { useNavigate } from 'react-router';
import styled from 'styled-components';

import { ScreenLoader } from '@dutchie-pos/components';

import SearchIcon from 'src/assets/icon-search.svg?react';
import { DutchieInput } from 'src/components/dutchie-input';
import { colors } from 'src/css/theme';
import { useMultipleManifestBytes } from 'src/queries/v2/manifest/get-manifest-bytes';
import { errorNotification } from 'src/store/actions/notifications-actions';
import { Path } from 'src/store/slices/user';
import { useAppSelector, useAppDispatch } from 'src/utils';

import { ContentLayout } from './content-layout';
import { ContentTitle } from './content-title';
import { useDocumentViewer } from './document-viewer';
import { headerHeight } from './header';
import { InventoryLedger } from '../pages/inventory-ledger';

type SelectCartProps = {
  isLoading: boolean;
  list: any[];
  name: string;
  onSearchChange: (searchText) => void;
  onSelection: (location) => void;
  searchText: string;
};

export const SelectCart: FC<SelectCartProps> = (props) => {
  // Global state
  const selectedPath = useAppSelector((state) => state.user.selectedPath);

  // Hooks
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const documentViewer = useDocumentViewer();

  // sort by manifest stop number ascending.  If there's no stop number, place the order at the end of the list
  const sortedOrders = orderBy(props.list, [(x) => x.ManifestStopNumber], ['asc']);
  const { isFetching: fetchingManifestBytes, refetch: fetchManifestBytes } = useMultipleManifestBytes({
    ids: sortedOrders.map((order) => order.ShipmentId),
    enabled: false,
  });

  // Computed
  const noOrders = (!props.list || props.list.length === 0) && !props.isLoading;
  const isSearching = props.searchText.length > 0;

  const showManifests = async () => {
    const { data: manifestData } = await fetchManifestBytes();
    if (manifestData && manifestData?.length > 0) {
      documentViewer.show(manifestData);
    } else {
      dispatch(errorNotification('No manifests found'));
    }
  };

  return (
    <ContentLayout>
      <Container>
        <ContentTitle
          action={
            selectedPath === Path.Delivery && props.list.length > 0
              ? { label: 'View Manifests', onClick: () => void showManifests() }
              : undefined
          }
          onGoBack={() => (selectedPath === Path.Delivery ? navigate('/route/delivery') : navigate('/registers'))}
        >
          Select {props.name}
        </ContentTitle>
        {(!noOrders || isSearching) && (
          <SearchBar>
            <DutchieInput
              placeholder='Search Carts'
              startAdornment={<SearchIcon />}
              value={props.searchText}
              onChange={(e) => props.onSearchChange(e.target.value)}
            />
          </SearchBar>
        )}
        {noOrders && <NotFoundMessage>No Orders Found</NotFoundMessage>}
        {!noOrders &&
          sortedOrders.map((x, index) => (
            <CartItem data-testid='select-cart-button' key={x.FullName + index} onClick={() => props.onSelection(x)}>
              <CartHeader>
                <CustomerName>{x.FullName}</CustomerName>
              </CartHeader>
              <CartItemStatus data-testid='cart-item-status'>
                {`${x.PatientType} | ${x.TransactionStatus?.length > 0 ? x.TransactionStatus : 'Open'} ${
                  x.TransactionReference?.length > 0 ? `| REF# ${x.TransactionReference}` : ''
                } ${x.DeliveryStatus === 'Undeliverable' ? '| Skipped' : ''} ${
                  x.HasRethinkPreAuth ? '| (Pre-Auth) Card' : ''
                }`}
              </CartItemStatus>
            </CartItem>
          ))}
        {selectedPath === Path.Delivery && <InventoryLedger />}
        <ScreenLoader speed='fast' top={headerHeight} visible={fetchingManifestBytes} />
      </Container>
    </ContentLayout>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const NotFoundMessage = styled.div`
  color: ${colors.dutchie.almostBlack};
  font-size: 1.25rem;
  line-height: 2rem;
  font-weight: 700;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SearchBar = styled.div`
  margin-bottom: 1rem;
`;

const CartItem = styled.div`
  width: 100%;
  border: 1px solid ${colors.dutchie.borderGrey};
  border-radius: 0.375rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  padding: 0.75rem 1rem;
  cursor: pointer;
  &:hover {
    border-color: black;
  }
`;

const CartHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CustomerName = styled.div`
  color: ${colors.dutchie.almostBlack};
  font-weight: 600;
  line-height: 1.875rem;
`;

const CartItemStatus = styled.div`
  color: ${colors.dutchie.grey35};
  line-height: 1.5rem;
`;

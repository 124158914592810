import { type FC } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { clamp } from 'lodash-es';
import { rgba } from 'polished';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { Button, ScreenLoader } from '@dutchie-pos/components';

import { DutchieButton } from 'src/components/dutchie-button';
import { colors } from 'src/css/theme';
import { useFinishRoute } from 'src/queries/curbside/finish-route';
import { useCarDetails } from 'src/queries/curbside/get-car-details';
import { GetRoutesKey } from 'src/queries/curbside/get-my-routes';
import { useActiveRoute } from 'src/queries/curbside/use-carts';
import { LedgerKey } from 'src/queries/v2/delivery/inventory-ledger';
import { useStartRoute } from 'src/queries/v2/delivery/route-out-for-delivery';
import { StartRouteKey } from 'src/queries/v2/delivery/route-out-for-delivery';

import { headerHeight } from './header';

export const EndOfRoute: FC = () => {
  // Global state
  const { data: route } = useActiveRoute();

  // Hooks
  const navigate = useNavigate();
  const { routeId } = useParams();
  const queryClient = useQueryClient();

  const { mutate: handleStartRoute, isPending: fetchingStartRoute } = useStartRoute({
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [GetRoutesKey, route?.RegisterId] });
      void queryClient.invalidateQueries({ queryKey: [LedgerKey, route?.DeliveryRouteId] });
    },
    routeId: Number(routeId) || 0,
  });

  const { mutate: endRoute, isPending: fetchingEndRoute } = useFinishRoute({
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [GetRoutesKey, route?.RegisterId] });
      navigate('/route/delivery');
    },
    routeId: Number(routeId) || 0,
  });

  const { data: details, isFetching: fetchingGetCarDetails } = useCarDetails();
  const isLoading = fetchingGetCarDetails || fetchingStartRoute || fetchingEndRoute;

  return (
    <Container>
      {details && (
        <LimitsContainer>
          <Row>
            <Title>Total Value Limit</Title>
            <Value>
              ${details.CashOnHand?.toFixed(2)} / ${details.DeliveryMaxTotalInventoryDollars?.toFixed(2) ?? '0.00'}
            </Value>
          </Row>
          <Row>
            <ProgressBar>
              <FillBar
                over={details.CashOnHand > details.DeliveryMaxTotalInventoryDollars}
                used={clamp((details.CashOnHand / details.DeliveryMaxTotalInventoryDollars) * 100, 0, 100)}
              />
            </ProgressBar>
          </Row>
          <Row>
            <Label>Ordered Inventory Value</Label>
            <Value>${details.OrderedInventoryDollarValue?.toFixed(2)}</Value>
          </Row>
          <Row>
            <Label>Un-ordered Inventory Value</Label>
            <Value>${details.UnorderedInventory?.toFixed(2)}</Value>
          </Row>
          <Row>
            <Label>Total Inventory Value</Label>
            <Value>${(details.OrderedInventoryDollarValue + details.UnorderedInventory)?.toFixed(2)}</Value>
          </Row>
          <Row>
            <Label>Cash</Label>
            <Value>
              ${(details.OrderedInventoryDollarValue + details.UnorderedInventory + details.CashOnHand)?.toFixed(2)}
            </Value>
          </Row>
        </LimitsContainer>
      )}
      {route?.DeliveryStatusId === 1 && (
        <Button buttonSize='extra-large' fullWidth label='Start Route' onClick={() => handleStartRoute()} />
      )}
      {route?.DeliveryStatusId === 2 && (
        <Button buttonSize='extra-large' fullWidth label='End Route' onClick={() => endRoute()} />
      )}
      <ScreenLoader speed='fast' top={headerHeight} visible={isLoading} />
    </Container>
  );
};

const LimitsContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const Container = styled.div`
  position: sticky;
  background: white;
  bottom: 0;
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;

const Label = styled.div`
  color: ${colors.dutchie.grey35};
  font-size: 1rem;
  line-height: 1.5rem;
`;

const Value = styled.div`
  text-align: right;
`;

const Title = styled(Label)`
  color: ${colors.dutchie.almostBlack};
  font-size: 1.125rem;
  line-height: 2rem;
  font-weight: 700;
`;

const ProgressBar = styled.div`
  flex-grow: 1;
  height: 8px;
  background: ${rgba(colors.black, 0.05)};
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
`;

const FillBar = styled.div<{ over: boolean; used: number }>`
  width: ${({ used }) => used.toFixed(2)}%;
  height: 8px;
  border-radius: 4px;
  background: ${({ over }) => (over ? colors.dutchie.red : colors.dutchie.moss)};
`;

const ActionButton = styled(DutchieButton)`
  height: 5rem;
  font-size: 1.25rem;
  font-weight: 700;
`;

import styled from 'styled-components';

import { colors } from 'src/css/theme';

export const DutchieLabel = styled.label<{ hasError?: boolean }>`
  color: ${({ hasError }) => (hasError ? colors.dutchie.red : colors.dutchie.almostBlack)};
  font-size: 1rem;
  height: 1rem;
  line-height: 1rem;
  display: block;
  margin-bottom: 0.7rem;
  width: 100%;

  &.error {
    color: ${colors.dutchie.red};
  }
`;

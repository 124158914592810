import { type QueryKey, useMutation } from '@tanstack/react-query';

import { errorNotification } from 'src/store/actions/notifications-actions';
import { useAppDispatch } from 'src/utils';
import { type BaseAPIResponse, post } from 'src/utils/helpers/http';
import { useBaseAPIParams } from 'src/utils/hooks/useBaseAPIParams';

export const StartRouteKey: QueryKey = ['start-route'];

const START_ROUTE_URL = '/v2/delivery/route-out-for-delivery';

type useStartRouteQueryType = {
  onSuccess: (resp: BaseAPIResponse<any>) => void;
  routeId: number;
};

export function useStartRoute({ onSuccess, routeId }: useStartRouteQueryType) {
  const baseAPIParams = useBaseAPIParams();
  const dispatch = useAppDispatch();
  return useMutation({
    mutationKey: StartRouteKey,
    mutationFn: async () => {
      const res = await post<BaseAPIResponse<any>>(START_ROUTE_URL, {
        ...baseAPIParams,
        DeliveryRouteId: routeId,
      });
      return res;
    },
    onSuccess,
    onError: (resp: string) => dispatch(errorNotification(resp ?? 'Failed to start route')),
  });
}

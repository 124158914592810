import { useQuery } from '@tanstack/react-query';

import { post } from 'src/utils/helpers/http';
import { useBaseAPIParams } from 'src/utils/hooks/useBaseAPIParams';

export const LedgerKey = 'inventory-ledger';

const GET_LEDGER_URL = '/v2/delivery/get-ledger';

type useStartRouteQueryType = {
  enabled: boolean;
  routeId: number;
};

export function useInventoryLedger({ routeId, enabled }: useStartRouteQueryType) {
  const baseAPIParams = useBaseAPIParams();
  return useQuery({
    queryKey: [LedgerKey, routeId],
    enabled,
    // Disable cache because it's not simple to detect all the
    // places that can change this data, and it must always be up to date
    // for compliance purposes
    staleTime: 0,
    queryFn: async () => {
      const res = await post<any>(GET_LEDGER_URL, {
        ...baseAPIParams,
        DeliveryRouteId: routeId,
      });
      return res;
    },
  });
}

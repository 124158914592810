import React, { useCallback, useEffect, useRef, useState } from 'react';

import { isEqual } from 'lodash-es';
import SignaturePad, { type PointGroup } from 'signature_pad';

export type PointSet = PointGroup[];

export type CanvasDimensions = {
  height: number;
  width: number;
};

export type SignatureData = {
  dimensions?: CanvasDimensions;
  imgDataURI: string;
  pointSet: PointGroup[];
};

export const checkIsValidSignature = (signatureData?: PointSet, minLength = 20) => {
  const pointCount = signatureData?.reduce((c: number, a: PointGroup) => c + a.points.length, 0) ?? 0;
  return pointCount >= minLength;
};

export type SignatureCanvasRef = {
  getData: () => SignatureData;
};

type SignatureCanvasProps = {
  className: string;
  data?: PointSet;
  height?: number;
  isVisible?: boolean;
  onChange?: (data: SignatureData) => void;
  width?: number;
};

export const SignatureCanvas = React.forwardRef<SignatureCanvasRef, SignatureCanvasProps>(
  ({ className, width, height, isVisible, data, onChange }, ref) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const sigPadRef = useRef<SignaturePad>();
    const [dimensions, setDimensions] = useState<CanvasDimensions>({ width: 0, height: 0 });

    const getSignatureData = useCallback(
      () => ({
        pointSet: sigPadRef.current?.toData() ?? [],
        imgDataURI: sigPadRef.current?.toDataURL() ?? '',
        dimensions,
      }),
      [dimensions]
    );

    useEffect(() => {
      // Link the signature pad to the canvas
      if (!sigPadRef.current && canvasRef.current) {
        sigPadRef.current = new SignaturePad(canvasRef.current);
      }

      // Set up event listener to update data after end of stroke
      if (sigPadRef.current && onChange) {
        const updateData = () => onChange(getSignatureData());
        sigPadRef.current.addEventListener('endStroke', updateData);
        return () => {
          sigPadRef.current?.removeEventListener('endStroke', updateData);
        };
      }
    }, [onChange, getSignatureData]);

    useEffect(() => {
      // Update changes to the data
      // Timeout ensures that the canvas is rendered properly after updating SignaturePad
      setTimeout(() => sigPadRef?.current?.fromData(data ?? []), 0);
    }, [isVisible, data]);

    useEffect(() => {
      const updateDimensions = () => {
        const calculatedWidth = canvasRef.current?.clientWidth ?? 0;
        const calculatedHeight = canvasRef.current?.clientHeight ?? 0;
        const newDimensions = {
          width: width ?? calculatedWidth,
          height: height ?? calculatedHeight,
        };
        if (!isEqual(dimensions, newDimensions)) {
          sigPadRef?.current?.clear();
          setDimensions(newDimensions);
        }
      };
      updateDimensions();

      if (!width && !height) {
        window.addEventListener('resize', updateDimensions);
        window.addEventListener('orientationchange', updateDimensions);
        return () => {
          window.removeEventListener('resize', updateDimensions);
          window.removeEventListener('orientationchange', updateDimensions);
        };
      }
    }, [isVisible, width, height, dimensions]);

    useEffect(() => {
      // If a ref is passed, assign it an object to retrieve signature data
      if (ref) {
        const SigRefObject = { getData: getSignatureData };
        if (typeof ref === 'function') {
          ref(SigRefObject);
        } else {
          ref.current = SigRefObject;
        }
      }
    }, [ref, getSignatureData]);

    return <canvas className={className} ref={canvasRef} {...dimensions} />;
  }
);

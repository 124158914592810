import React from 'react';

// import { StyledGridContainer } from 'src/app/components/lib/table/no-results-overlay';
// import { RegularText } from 'src/app/components/lib/typography';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

import { Loader } from './loader';

import type { LoaderProps } from './loader';

type PageLoaderProps = {
  className?: string;
  copy?: string;
  size?: LoaderProps['size'];
  variant?: LoaderProps['variant'];
  visible?: boolean;
};

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    visibility: hidden; // Hide the element after it's fully transparent
  }
`;

export function PageLoader(props: PageLoaderProps) {
  const { visible = true, className = '', variant = 'blue', size = '3x', copy } = props;

  return (
    <Container className={visible ? className : `hidden ${className}`}>
      <Loader size={size} variant={variant} />
      {copy && (
        <StyledRegularText aria-busy='true' role='alert'>
          {copy}
        </StyledRegularText>
      )}
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;

  &.hidden {
    animation: ${fadeOut} 0.3s ease-out forwards;
    opacity: 0;
  }
`;

const StyledRegularText = styled.div`
  color: var(--color-gray-80);
  margin-top: var(--sizes-60);
`;

import { useNavigate } from 'react-router-dom';

import { useUser } from 'src/queries/curbside/user-details';
import { type ActiveLocationType } from 'src/store/reducers/MobileCheckoutReducer';
import { setLocation } from 'src/store/slices/user';
import { useAppDispatch } from 'src/utils';

import { ContentLayout } from '../components/content-layout';
import { SelectionList } from '../components/selection-list';

export function SelectLocation() {
  // Hooks
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { data: user } = useUser();

  // Computed
  const locations = user?.locations ?? [];

  // Handlers
  const handleLocationSelection = (location: ActiveLocationType) => {
    if (location.location_id) {
      dispatch(setLocation(location.location_id));
      navigate(`/paths`);
    }
  };

  return (
    <ContentLayout>
      <SelectionList
        hideBackButton
        key='location_id'
        list={locations.sort((a, b) => (a.location_name > b.location_name ? 1 : -1))}
        name='Location'
        noOptionsText='You do NOT have permissions to login to Mobile Checkout. Talk to your administrator'
        value='location_name'
        onSelection={handleLocationSelection}
      />
    </ContentLayout>
  );
}

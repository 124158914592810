import type { ForwardedRef } from 'react';
import { forwardRef } from 'react';

import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { LicenseManager } from '@ag-grid-enterprise/core';

import type { AgGridReactProps } from '@ag-grid-community/react';

ModuleRegistry.registerModules([ClientSideRowModelModule]);
LicenseManager.setLicenseKey('<your license key>');

export function CustomAgGrid<TData>(props: AgGridReactProps, ref: ForwardedRef<AgGridReact<TData> | null>) {
  return (
    <div
      className='ag-theme-quartz' // applying the grid theme
      style={{ height: '100%', width: '100%' }} // the grid will fill the size of the parent container
    >
      <AgGridReact<TData> {...props} ref={ref} />
    </div>
  );
}

// const gridRef = useRef<AgGridReact<T>>(null);
export const DataGrid = forwardRef(CustomAgGrid);

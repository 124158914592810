import { css } from '@emotion/react';

import { colors } from './colors';
// NOTE: All px values in comments are for when the body element is assigned fon-size 16px
// To calculate the rem value use this formula: (pixelValue / 16px = remValue)

export const generatedTokens = css`
  :root {
    // Sizes should be used to create space between or within elements.
    // Designers should use the px values within auto-layout frames for spacing between items and the padding around
    // items.
    // Engineers should use the rem values for style properties like 'height', 'width, 'padding', and 'margin'.
    --sizes-0: 0rem; // 0px
    --sizes-10: 0.25rem; // 4px
    --sizes-20: 0.5rem; // 8px
    --sizes-30: 0.75rem; // 12px
    --sizes-40: 1rem; // 16px
    --sizes-50: 1.25rem; // 20px
    --sizes-60: 1.5rem; // 24px
    --sizes-70: 2rem; // 32px
    --sizes-80: 2.5rem; // 40px
    --sizes-90: 3rem; // 48px
    --sizes-100: 3.5rem; // 56px
    --sizes-110: 4rem; // 64px

    // Color palette
    ${colors}

    --grid-12-columns-0px-margins-24px-gutters: auto 1fr / repeat(12, 1fr);
    --grid-12-columns-56px-margins-24px-gutters: auto 1fr / repeat(12, 1fr);
    --grid-4-columns-0px-margins-24px-gutters: auto 1fr / repeat(4, 1fr);
    --grid-4-columns-32px-margins-24px-gutters: auto 1fr / repeat(4, 1fr);
    --grid-6-columns-0px-margins-24px-gutters: auto 1fr / repeat(6, 1fr);
    --grid-6-columns-56px-margins-24px-gutters: auto 1fr / repeat(6, 1fr);
    --font-headings-heading-1: 700 1.5rem / 1.875rem var(--font-family-text);
    --font-headings-heading-2: 600 1.25rem / 1.5rem var(--font-family-text);
    --font-headings-heading-3: 600 1.125rem / 1.375rem var(--font-family-text);
    --font-large-16pt-normal: 400 1rem / 1.4rem var(--font-family-text);
    --font-large-16pt-semibold: 600 1rem / 1.5rem var(--font-family-text);
    --font-regular-14pt-normal: 400 0.875rem / 1.25rem var(--font-family-text);
    --font-regular-14pt-semibold: 600 0.875rem / 1.25rem var(--font-family-text);
    --font-regular-14pt-bold: 700 0.875rem / 1.25rem var(--font-family-text);
    --font-small-13pt-normal: 400 0.8125rem / 1.125rem var(--font-family-text);
    --font-small-13pt-semibold: 600 0.8125rem / 1.125rem var(--font-family-text);
    --font-small-13pt-bold: 700 0.8125rem / 1.125rem var(--font-family-text);
    --font-extra-small-12pt-normal: 400 0.75rem / 1rem var(--font-family-text);
    --font-extra-small-12pt-semibold: 600 0.75rem / 1rem var(--font-family-text);
    --font-extra-small-12pt-bold: 700 0.75rem / 1rem var(--font-family-text);
    --font-extra-small-12pt-bold-caps: 700 0.75rem / 1rem var(--font-family-text);
    --font-mini-11pt-semibold: 600 0.6875rem / 0.875rem var(--font-family-text);
    --font-micro-10pt-bold: 700 0.625rem / 0.875rem var(--font-family-text);
    --effect-modal-dropshadow: drop-shadow(0rem 0.375rem 1.25rem #00000033);

    // In most cases you’ll want to pair the font-size token you’re using with the matching step on the line-height
    // scale.
    // For example, if you use font-size-20, it should be paired with line-height-20.
    --typography-headings-heading-1-font-size: 1.5rem;
    --typography-headings-heading-1-text-decoration: none;
    --typography-headings-heading-1-font-family: var(--font-family-text);
    --typography-headings-heading-1-font-weight: 700;
    --typography-headings-heading-1-font-style: normal;
    --typography-headings-heading-1-font-stretch: normal;
    --typography-headings-heading-1-letter-spacing: 0rem;
    --typography-headings-heading-1-line-height: 1.875rem;
    --typography-headings-heading-1-paragraph-indent: 0rem;
    --typography-headings-heading-1-paragraph-spacing: 0rem;
    --typography-headings-heading-1-text-case: none;
    --typography-headings-heading-2-font-size: 1.25rem;
    --typography-headings-heading-2-text-decoration: none;
    --typography-headings-heading-2-font-family: var(--font-family-text);
    --typography-headings-heading-2-font-weight: 600;
    --typography-headings-heading-2-font-style: normal;
    --typography-headings-heading-2-font-stretch: normal;
    --typography-headings-heading-2-letter-spacing: 0rem;
    --typography-headings-heading-2-line-height: 1.5rem;
    --typography-headings-heading-2-paragraph-indent: 0rem;
    --typography-headings-heading-2-paragraph-spacing: 0rem;
    --typography-headings-heading-2-text-case: none;
    --typography-headings-heading-3-font-size: 1.125rem;
    --typography-headings-heading-3-text-decoration: none;
    --typography-headings-heading-3-font-family: var(--font-family-text);
    --typography-headings-heading-3-font-weight: 600;
    --typography-headings-heading-3-font-style: normal;
    --typography-headings-heading-3-font-stretch: normal;
    --typography-headings-heading-3-letter-spacing: 0rem;
    --typography-headings-heading-3-line-height: 1.375rem;
    --typography-headings-heading-3-paragraph-indent: 0rem;
    --typography-headings-heading-3-paragraph-spacing: 0rem;
    --typography-headings-heading-3-text-case: none;
    --typography-large-16pt-normal-font-size: 1rem;
    --typography-large-16pt-normal-text-decoration: none;
    --typography-large-16pt-normal-font-family: var(--font-family-text);
    --typography-large-16pt-normal-font-weight: 400;
    --typography-large-16pt-normal-font-style: normal;
    --typography-large-16pt-normal-font-stretch: normal;
    --typography-large-16pt-normal-letter-spacing: 0.005rem;
    --typography-large-16pt-normal-line-height: 1.5rem;
    --typography-large-16pt-normal-paragraph-indent: 0rem;
    --typography-large-16pt-normal-paragraph-spacing: 0rem;
    --typography-large-16pt-normal-text-case: none;
    --typography-large-16pt-semibold-font-size: 1rem;
    --typography-large-16pt-semibold-text-decoration: none;
    --typography-large-16pt-semibold-font-family: var(--font-family-text);
    --typography-large-16pt-semibold-font-weight: 600;
    --typography-large-16pt-semibold-font-style: normal;
    --typography-large-16pt-semibold-font-stretch: normal;
    --typography-large-16pt-semibold-letter-spacing: 0.005rem;
    --typography-large-16pt-semibold-line-height: 1.5rem;
    --typography-large-16pt-semibold-paragraph-indent: 0rem;
    --typography-large-16pt-semibold-paragraph-spacing: 0rem;
    --typography-large-16pt-semibold-text-case: none;
    --typography-regular-14pt-normal-font-size: 0.875rem;
    --typography-regular-14pt-normal-text-decoration: none;
    --typography-regular-14pt-normal-font-family: var(--font-family-text);
    --typography-regular-14pt-normal-font-weight: 400;
    --typography-regular-14pt-normal-font-style: normal;
    --typography-regular-14pt-normal-font-stretch: normal;
    --typography-regular-14pt-normal-letter-spacing: 0.004375rem;
    --typography-regular-14pt-normal-line-height: 1.25rem;
    --typography-regular-14pt-normal-paragraph-indent: 0rem;
    --typography-regular-14pt-normal-paragraph-spacing: 0rem;
    --typography-regular-14pt-normal-text-case: none;
    --typography-regular-14pt-semibold-font-size: 0.875rem;
    --typography-regular-14pt-semibold-text-decoration: none;
    --typography-regular-14pt-semibold-font-family: var(--font-family-text);
    --typography-regular-14pt-semibold-font-weight: 600;
    --typography-regular-14pt-semibold-font-style: normal;
    --typography-regular-14pt-semibold-font-stretch: normal;
    --typography-regular-14pt-semibold-letter-spacing: 0.004375rem;
    --typography-regular-14pt-semibold-line-height: 1.25rem;
    --typography-regular-14pt-semibold-paragraph-indent: 0rem;
    --typography-regular-14pt-semibold-paragraph-spacing: 0rem;
    --typography-regular-14pt-semibold-text-case: none;
    --typography-regular-14pt-bold-font-size: 0.875rem;
    --typography-regular-14pt-bold-text-decoration: none;
    --typography-regular-14pt-bold-font-family: var(--font-family-text);
    --typography-regular-14pt-bold-font-weight: 700;
    --typography-regular-14pt-bold-font-style: normal;
    --typography-regular-14pt-bold-font-stretch: normal;
    --typography-regular-14pt-bold-letter-spacing: 0.004375rem;
    --typography-regular-14pt-bold-line-height: 1.25rem;
    --typography-regular-14pt-bold-paragraph-indent: 0rem;
    --typography-regular-14pt-bold-paragraph-spacing: 0rem;
    --typography-regular-14pt-bold-text-case: none;
    --typography-small-13pt-normal-font-size: 0.8125rem;
    --typography-small-13pt-normal-text-decoration: none;
    --typography-small-13pt-normal-font-family: var(--font-family-text);
    --typography-small-13pt-normal-font-weight: 400;
    --typography-small-13pt-normal-font-style: normal;
    --typography-small-13pt-normal-font-stretch: normal;
    --typography-small-13pt-normal-letter-spacing: 0.0040625rem;
    --typography-small-13pt-normal-line-height: 1.125rem;
    --typography-small-13pt-normal-paragraph-indent: 0rem;
    --typography-small-13pt-normal-paragraph-spacing: 0rem;
    --typography-small-13pt-normal-text-case: none;
    --typography-small-13pt-semibold-font-size: 0.8125rem;
    --typography-small-13pt-semibold-text-decoration: none;
    --typography-small-13pt-semibold-font-family: var(--font-family-text);
    --typography-small-13pt-semibold-font-weight: 600;
    --typography-small-13pt-semibold-font-style: normal;
    --typography-small-13pt-semibold-font-stretch: normal;
    --typography-small-13pt-semibold-letter-spacing: 0.0040625rem;
    --typography-small-13pt-semibold-line-height: 1.125rem;
    --typography-small-13pt-semibold-paragraph-indent: 0rem;
    --typography-small-13pt-semibold-paragraph-spacing: 0rem;
    --typography-small-13pt-semibold-text-case: none;
    --typography-small-13pt-bold-font-size: 0.8125rem;
    --typography-small-13pt-bold-text-decoration: none;
    --typography-small-13pt-bold-font-family: var(--font-family-text);
    --typography-small-13pt-bold-font-weight: 700;
    --typography-small-13pt-bold-font-style: normal;
    --typography-small-13pt-bold-font-stretch: normal;
    --typography-small-13pt-bold-letter-spacing: 0.0040625rem;
    --typography-small-13pt-bold-line-height: 1.125rem;
    --typography-small-13pt-bold-paragraph-indent: 0rem;
    --typography-small-13pt-bold-paragraph-spacing: 0rem;
    --typography-small-13pt-bold-text-case: none;
    --typography-extra-small-12pt-normal-font-size: 0.75rem;
    --typography-extra-small-12pt-normal-text-decoration: none;
    --typography-extra-small-12pt-normal-font-family: var(--font-family-text);
    --typography-extra-small-12pt-normal-font-weight: 400;
    --typography-extra-small-12pt-normal-font-style: normal;
    --typography-extra-small-12pt-normal-font-stretch: normal;
    --typography-extra-small-12pt-normal-letter-spacing: 0.00375rem;
    --typography-extra-small-12pt-normal-line-height: 1rem;
    --typography-extra-small-12pt-normal-paragraph-indent: 0rem;
    --typography-extra-small-12pt-normal-paragraph-spacing: 0rem;
    --typography-extra-small-12pt-normal-text-case: none;
    --typography-extra-small-12pt-semibold-font-size: 0.75rem;
    --typography-extra-small-12pt-semibold-text-decoration: none;
    --typography-extra-small-12pt-semibold-font-family: var(--font-family-text);
    --typography-extra-small-12pt-semibold-font-weight: 600;
    --typography-extra-small-12pt-semibold-font-style: normal;
    --typography-extra-small-12pt-semibold-font-stretch: normal;
    --typography-extra-small-12pt-semibold-letter-spacing: 0.00375rem;
    --typography-extra-small-12pt-semibold-line-height: 1rem;
    --typography-extra-small-12pt-semibold-paragraph-indent: 0rem;
    --typography-extra-small-12pt-semibold-paragraph-spacing: 0rem;
    --typography-extra-small-12pt-semibold-text-case: none;
    --typography-extra-small-12pt-bold-font-size: 0.75rem;
    --typography-extra-small-12pt-bold-text-decoration: none;
    --typography-extra-small-12pt-bold-font-family: var(--font-family-text);
    --typography-extra-small-12pt-bold-font-weight: 700;
    --typography-extra-small-12pt-bold-font-style: normal;
    --typography-extra-small-12pt-bold-font-stretch: normal;
    --typography-extra-small-12pt-bold-letter-spacing: 0.00375rem;
    --typography-extra-small-12pt-bold-line-height: 1rem;
    --typography-extra-small-12pt-bold-paragraph-indent: 0rem;
    --typography-extra-small-12pt-bold-paragraph-spacing: 0rem;
    --typography-extra-small-12pt-bold-text-case: none;
    --typography-extra-small-12pt-bold-caps-font-size: 0.75rem;
    --typography-extra-small-12pt-bold-caps-text-decoration: none;
    --typography-extra-small-12pt-bold-caps-font-family: var(--font-family-text);
    --typography-extra-small-12pt-bold-caps-font-weight: 700;
    --typography-extra-small-12pt-bold-caps-font-style: normal;
    --typography-extra-small-12pt-bold-caps-font-stretch: normal;
    --typography-extra-small-12pt-bold-caps-letter-spacing: 0.00375rem;
    --typography-extra-small-12pt-bold-caps-line-height: 1rem;
    --typography-extra-small-12pt-bold-caps-paragraph-indent: 0rem;
    --typography-extra-small-12pt-bold-caps-paragraph-spacing: 0rem;
    --typography-extra-small-12pt-bold-caps-text-case: uppercase;
    --typography-mini-11pt-semibold-font-size: 0.6875rem;
    --typography-mini-11pt-semibold-text-decoration: none;
    --typography-mini-11pt-semibold-font-family: var(--font-family-text);
    --typography-mini-11pt-semibold-font-weight: 600;
    --typography-mini-11pt-semibold-font-style: normal;
    --typography-mini-11pt-semibold-font-stretch: normal;
    --typography-mini-11pt-semibold-letter-spacing: 0.0034375rem;
    --typography-mini-11pt-semibold-line-height: 0.875rem;
    --typography-mini-11pt-semibold-paragraph-indent: 0rem;
    --typography-mini-11pt-semibold-paragraph-spacing: 0rem;
    --typography-mini-11pt-semibold-text-case: none;
    --typography-micro-10pt-bold-font-size: 0.625rem;
    --typography-micro-10pt-bold-text-decoration: none;
    --typography-micro-10pt-bold-font-family: var(--font-family-text);
    --typography-micro-10pt-bold-font-weight: 700;
    --typography-micro-10pt-bold-font-style: normal;
    --typography-micro-10pt-bold-font-stretch: normal;
    --typography-micro-10pt-bold-letter-spacing: 0.003125rem;
    --typography-micro-10pt-bold-line-height: 0.875rem;
    --typography-micro-10pt-bold-paragraph-indent: 0rem;
    --typography-micro-10pt-bold-paragraph-spacing: 0rem;
    --typography-micro-10pt-bold-text-case: uppercase;
  }
`;

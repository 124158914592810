import { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { ScreenLoader } from '@dutchie-pos/components';

import type { GuestListType } from 'src/queries/curbside/guest-list';
import type { GetOrdersForRouteType } from 'src/queries/curbside/types';
import { useCarts } from 'src/queries/curbside/use-carts';
import {
  setDutchiePayErrorMessage,
  setIsCheckoutComplete,
  setIsDutchiePayError,
  setIsRethinkError,
  setRethinkErrorMessage,
} from 'src/store/actions/MobileCheckoutActions';
import { Path } from 'src/store/slices/user';
import { useAppDispatch, useAppSelector } from 'src/utils';

import { EndOfRoute } from '../components/end-of-route';
import { headerHeight } from '../components/header';
import { SelectCart } from '../components/select-cart';

function searchedTextChecker(cart: GetOrdersForRouteType | GuestListType, text: string) {
  return (
    cart.FullName?.toLowerCase().includes(text) ||
    cart.MJStateIDNo?.toLowerCase().includes(text) ||
    cart.TransactionStatus?.toLowerCase().includes(text) ||
    cart.TransactionReference?.toLowerCase().includes(text) ||
    ('open'.includes(text) && cart.TransactionStatus?.toLowerCase() === '')
  );
}

export const CartList = () => {
  // Global state
  const selectedPath = useAppSelector((state) => state.user.selectedPath);
  const selectedRegister = useAppSelector((state) => state.user.selectedRegister);

  // Local state
  const [searchText, setSearchText] = useState('');

  // Hooks
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { data: carts, isLoading } = useCarts();

  const handleOnSelection = (cart: GetOrdersForRouteType | GuestListType) => {
    dispatch(setIsDutchiePayError(false));
    dispatch(setDutchiePayErrorMessage(''));
    dispatch(setIsRethinkError(false));
    dispatch(setRethinkErrorMessage(''));
    dispatch(setIsCheckoutComplete(false));

    navigate(`/cart/${cart.ShipmentId}/${cart.Guest_id}`);
  };

  const filteredCarts =
    carts
      ?.filter((cart) => searchedTextChecker(cart, searchText.toLowerCase()))
      ?.filter((cart) => Number(cart.RegisterId) === selectedRegister?.id || cart.RegisterId === '0') ?? [];

  return (
    <div>
      <SelectCart
        isLoading={isLoading}
        list={filteredCarts}
        name='Cart'
        searchText={searchText}
        onSearchChange={(search: string) => setSearchText(search)}
        onSelection={(cart) => handleOnSelection(cart)}
      />
      {selectedPath === Path.Delivery && <EndOfRoute />}
      <ScreenLoader speed='fast' top={headerHeight} visible={isLoading} />
    </div>
  );
};

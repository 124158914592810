/**
 * TODO: copied from @dutchie/error-messaging jfrog package. Need to actually set up jfrog and delete this file.
 */
const dutchiePaySources = {
  AGGREGATOR: 'aggregator',
  CONSUMER_VELOCITY_CHECK: 'dutchie-pay-consumer-velocity-check',
  DUTCHIE_PAY: 'dutchie-pay',
  MX: 'mx',
  PLAID: 'plaid',
  SARDINE: 'sardine',
  SIFT: 'sift',
  TRANSACTION_VELOCITY_CHECK: 'dutchie-pay-transaction-velocity-check',
};

const cardSources = {
  PAYMENTS: 'payments',
  REGALA: 'regala',
};

const pinDebitSources = {
  DATA_CAP: 'Data Cap/LLX',
  DC_DIRECT: 'DC Direct',
  PAYNETWORX: 'Paynetworx',
  TRANCLOUD: 'Trancloud',
};

export const SOURCES = {
  ...cardSources,
  ...dutchiePaySources,
  ...pinDebitSources,
};

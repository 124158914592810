import { createReducer, type ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { showConfirmRemoveItemDrawer, closeDrawer } from '../actions/drawers-actions';

export type DrawersPayloads = {
  showConfirmRemoveItemDrawer: { onConfirm: () => Promise<void> };
};

export type DrawersState = {
  confirmRemoveItemDrawer: { onConfirm: () => Promise<void> } | null;
};

export const drawersInitialState: DrawersState = {
  confirmRemoveItemDrawer: null,
};

export const drawersReducer = createReducer(drawersInitialState, (builder: ActionReducerMapBuilder<DrawersState>) => {
  builder.addCase(showConfirmRemoveItemDrawer, (_state, action) => ({
    ...drawersInitialState,
    confirmRemoveItemDrawer: action.payload,
  }));
  builder.addCase(closeDrawer, (state, action) => {
    if (Object.keys(drawersInitialState).includes(action.payload)) {
      return {
        ...state,
        [action.payload]: null,
      };
    } 
    
    return state;
  });
});

import { useMemo } from 'react';

import { type AppliedDiscountTemp } from '@dutchie-pos/types';

export const useTotalDiscountAmount = (rolledUpDiscounts: AppliedDiscountTemp[]) =>
  useMemo(
    () =>
      rolledUpDiscounts?.length > 0
        ? rolledUpDiscounts.reduce((accum, item) => accum + item.DiscountAmt, 0)
        : rolledUpDiscounts[0]?.DiscountAmt ?? 0,
    [rolledUpDiscounts]
  );

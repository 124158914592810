import styled from 'styled-components';

import CheckMarkSvg from 'src/assets/checkmark-green.svg?react';
import { Chevron } from 'src/assets/chevron';
import { colors } from 'src/css/theme';

type CheckCardProps = {
  isCompleted: boolean;
  label: string;
  onClick: () => void;
};

export const CheckCard = ({ label, isCompleted, onClick }: CheckCardProps): JSX.Element => (
  <Container data-testid="check-card" onClick={onClick}>
    <StyledChevron height={16} width={16} />
    <Label>{label}</Label>
    {isCompleted ? (
  <CompletedPill data-testid='check-status'>
        <CheckMarkIcon />
        COMPLETE
      </CompletedPill>
        ) : (
  <StatusPill data-testid='check-status'>INCOMPLETE</StatusPill>
        )}
  </Container>
);

const Container = styled.div`
  border: 1px solid ${colors.dutchie.gray30};
  box-shadow: 0px 2px 2px 0px #0000000f;
  border-radius: 8px;
  padding: 16px 20px 16px 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  cursor: pointer;
`;

const Label = styled.div`
  font-weight: 600;
`;

const StatusPill = styled.div`
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.5%;
  color: ${colors.dutchie.gray90};
  background: ${colors.dutchie.opal10};
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem 0.75rem;
  border-radius: 0.875rem;
  width: fit-content;
`;

const CompletedPill = styled(StatusPill)`
  color: white;
  background: ${colors.dutchie.green};
`;

const CheckMarkIcon = styled(CheckMarkSvg)`
  width: 12px;
  height: 12px;

  path {
    fill: white;
  }
`;

const StyledChevron = styled(Chevron)`
  position: absolute;
  right: 20px;
  top: 0;
  bottom: 0;
  margin: auto 0;
`;

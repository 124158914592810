import React, { type FC, type SVGProps } from 'react';

import NumberFormat, { type NumberFormatPropsBase } from 'react-number-format';
import styled from 'styled-components';

import { colors } from 'src/css/theme';

import { DutchieLabel } from './dutchie-label';

type InputProps = {
  $containerMargin?: string;
  $containerWidth?: string;
  automationId?: string;
};

type DutchieInputOwnProps = {
  autoComplete?: string;
  automationId?: string;
  containerMargin?: string;
  containerWidth?: string;
  disabled?: boolean;
  endAdornment?: React.FC<SVGProps<SVGSVGElement>> | React.ReactElement;
  hasError?: boolean;
  inputHeight?: string;
  label?: string;
  startAdornment?: React.FC<SVGProps<SVGSVGElement>> | React.ReactElement;
  type?: 'number' | 'password' | 'text';
  withDarkBackground?: boolean;
};

export type DutchieInputProps = DutchieInputOwnProps &
  Omit<NumberFormatPropsBase<any>, 'type'> &
  Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'>;
export const DutchieInput: FC<DutchieInputProps> = (props) => {
  const {
    autoComplete = 'off',
    automationId,
    containerWidth,
    containerMargin = '0',
    inputHeight,
    label,
    type = 'text',
    endAdornment,
    startAdornment,
    disabled,
    hasError,
    withDarkBackground,
    ...restProps
  } = props;
  const inputId = label?.replace(/ /g, '-');
  const automationIdTitle = automationId ?? (inputId ? `input_${inputId}` : `input-base`);

  return (
    <DutchieLabelInputContainer
      $containerMargin={containerMargin}
      $containerWidth={containerWidth}
      automationId={automationIdTitle}
    >
      {label?.length && (
        <DutchieLabel $hasError={hasError} htmlFor={inputId}>
          {label}
        </DutchieLabel>
      )}
      <DutchieInputContainer
        $hasError={hasError}
        $inputHeight={inputHeight}
        $startAdornment={Boolean(startAdornment)}
        $withDarkBackground={withDarkBackground}
        disabled={disabled}
      >
        {startAdornment}
        {type === 'number' ? (
          <NumberFormat disabled={disabled} id={inputId} inputMode='numeric' {...restProps} />
        ) : (
          <input autoComplete={autoComplete} {...restProps} disabled={disabled} id={inputId} type={type} />
        )}
        {endAdornment}
      </DutchieInputContainer>
    </DutchieLabelInputContainer>
  );
};

export const DutchieLabelInputContainer = styled(({ automationId, ...rest }: InputProps) => (
  <div {...rest} data-testid={automationId} />
))<InputProps>`
  display: flex;
  flex-direction: column;
  width: ${({ $containerWidth }) => $containerWidth ?? '100%'};
  margin: ${({ $containerMargin }) => $containerMargin ?? '0.75rem 0'};
`;

const DutchieInputContainer = styled.div<{
  $hasError?: boolean;
  $inputHeight?: string;
  $startAdornment?: boolean;
  $withDarkBackground?: boolean;
  disabled?: boolean;
}>`
  width: 100%;
  font-size: 1rem;
  border: 1px solid ${({ $hasError }) => ($hasError ? colors.dutchie.flora : colors.dutchie.borderGrey)};
  border-radius: 0.375rem;
  padding: 0.75rem;
  height: ${({ $inputHeight }) => $inputHeight ?? '3rem'};
  display: flex;
  align-items: center;
  background-color: ${({ disabled, $withDarkBackground }) =>
    $withDarkBackground ? 'white' : disabled ? colors.dutchie.lightGrey : 'rgba(0,0,0,0)'};
  color: ${({ $hasError }) => ($hasError ? colors.dutchie.flora : colors.realBlack)};

  &:focus {
    box-shadow: none;
    border-color: ${colors.primary};
    outline: none;
  }

  & input {
    flex: 1;
    min-width: 0;
    height: 100%;
    font-size: 1rem;
    border: none;
    padding: ${({ $startAdornment }) => ($startAdornment ? '0 0 0 .5rem' : '0')};
    &:focus {
      outline: none;
    }
    &:disabled {
      background-color: ${colors.dutchie.lightGrey};
      & + button:disabled {
        color: ${colors.dutchie.gray50} !important;
      }
    }
    ${({ $hasError }) => $hasError && `::placeholder {color: ${colors.dutchie.flora};}`}
  }

  & svg {
    max-height: 30px;
    max-width: 30px;
    margin: 0;
  }
`;

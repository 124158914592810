import { useNavigate, useParams } from 'react-router';
import styled, { css } from 'styled-components';

import { BypassDelivery } from 'src/app/components/checkout/bypass-delivery';
import { CartHeader } from 'src/app/components/checkout/cart-header';
import { CheckoutSuccess } from 'src/app/components/checkout/checkout-success';
import { PaymentForm } from 'src/app/components/checkout/payment-form';

import { type CartParams } from '../cart';

import { usePayment } from './use-payment';

export const Payment = (): JSX.Element => {
  const { totals, showCheckoutSuccess, handlePaymentSuccess, handlePaymentFailure } = usePayment();
  const navigate = useNavigate();
  const { customerId, shipmentId } = useParams<CartParams>();

  if (showCheckoutSuccess) {
    return <CheckoutSuccess {...totals} />;
  }

  return (
    <Container>
      <CartHeader onGoBack={() => navigate(`/checks/${shipmentId}/${customerId}`)} />
      <SectionLabel>Bypass State System</SectionLabel>
      <BypassDelivery />
      <SectionLabel>Payment</SectionLabel>
      <PaymentForm totals={totals} onFailure={handlePaymentFailure} onPaymentSuccess={handlePaymentSuccess} />
    </Container>
  );
};

// overflow-y: scroll;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 24px;
`;

const LabelCss = css`
  color: black;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 2rem;
`;

const SectionLabel = styled.div`
  ${LabelCss}
  margin-top: 1rem;
  margin-bottom: 0.5rem;
`;

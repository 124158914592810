import { useQuery } from '@tanstack/react-query';

import { useAppSelector } from 'src/utils';
import { post } from 'src/utils/helpers/http';

import { useUser } from './user-details';

export const locationDetailsKey = 'location-details';
export const locationIdKey = 'location-id';

const LOCATION_DETAILS_URL = '/api/curbside/location-details';

export enum DeliveryProvider {
  OnFleet = 1,
  Tookan = 2,
  WorkWave = 3,
}

export type LocationFeature = {
  Category: string;
  DisplayName: string;
  FeatureDescription?: string;
  FeatureId: number;
  FeatureName: string;
  IsEnabled: boolean;
};

export type LocationIntegrations = {
  AcceptCanPay: boolean;
  AcceptHypur: boolean;
  AcceptLinx: boolean;
  CanUseMassDPHIntegration: boolean;
  DebitProcessor?: string;
  EnableMETRCUpdatePatients: boolean;
  HasMETRC: boolean;
  HasPlanetPayments: boolean;
  HasReThink: boolean;
  IntegratedDebitProvider?: string;
  IntegratedState?: string;
  IsLeaflyEnabled: boolean;
  IsWeedMapsEnabled: boolean;
  OnFleetChangeGuestListStatus: boolean;
  UseAlpineIQ: boolean;
  UseAlpineIQBypass2FA: boolean;
  UseAlpineIQBypassEntry: boolean;
  UseBaker: boolean;
  UseBioMauris: boolean;
  UseBioTrack: boolean;
  UseBioTrackPOS: boolean;
  UseDutchiePay: boolean;
  UseExternalName: boolean;
  UseFyllo: boolean;
  UseIntegratedDebit: boolean;
  UseJory: boolean;
  UseLeaf: boolean;
  UseMCDMS: boolean;
  UseMETRC: boolean;
  UseMETRCTransferTemplates: boolean;
  UseMETRCUseByDate: boolean;
  UseMMUR: boolean;
  UseMn: boolean;
  UseOnFleet: boolean;
  UsePaymentsHub: boolean;
  UsePaynetworx: boolean;
  UsePlanetPayments: boolean;
  UseReportBuilder: boolean;
  UseReThink: boolean;
  UseRethink: boolean;
  UseSpringBig: boolean;
};

export type LocationSettings = {
  ActualWeightThreshold: number | null;
  ActualWeightThresholdUnitId: number | null;
  CartPhoneDisclaimer: string;
  DefaultCustomerTypeId: number;
  DefaultLabel: number;
  DefaultMinorAge: number | null;
  DefaultRecMinorAge: number | null;
  DeliveryConcurrency: number | null;
  DeliveryMaxTotalInventoryDollars: number | null;
  DeliveryProvider: DeliveryProvider;
  GuestListRefreshRate: number;
  OfAge: number | null;
  PlaceholderDirections: string;
  SessionTimeoutMinutes: number;
  SessionTimeoutMinutesForRegister: number;
  State: string;
  StorefrontUrl: string;
  TimeOffSet: number;
  UsePrintNode: boolean;
};

export type LocationSettingsResponse = {
  DeliveryTitleFormat: string;
  EmbeddedMapsKey: string;
  Features: LocationFeature[];
  Integrations: LocationIntegrations;
  Lat?: number;
  Lng?: number;
  MapboxKey: string;
  MassDphValid?: boolean;
  Settings: LocationSettings;
};

export type Location = Omit<LocationSettingsResponse, 'Features'> & {
  Features: Record<string, boolean>;
  locationId?: number;
};

export function useLocation() {
  const { data: user } = useUser();
  const selectedLocationId = useAppSelector((state) => state.user.selectedLocationId);
  return useQuery({
    queryKey: [locationDetailsKey, selectedLocationId],
    queryFn: async (): Promise<Location> => {
      const res: any = await post(LOCATION_DETAILS_URL, {
        LspId: user?.LspId,
        LocId: selectedLocationId,
        UserId: user?.UserId,
      });

      const features: Record<string, boolean> = res.Features.reduce(
        (acc, feature) => {
          acc[feature.FeatureName] = feature.IsEnabled;
          return acc;
        },
        {} as Record<string, boolean>,
      );

      return { locationId: selectedLocationId, ...res, Features: features };
    },
    enabled: !!selectedLocationId,
  });
}

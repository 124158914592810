import styled from '@emotion/styled';

import { Loader } from './loader';

import type { LoaderProps } from './loader';

type ScreenLoaderProps = {
  size?: LoaderProps['size'];
  speed?: 'fast' | 'normal' | 'slow';
  top?: string;
  variant?: LoaderProps['variant'];
  visible?: boolean;
};

export function ScreenLoader(props: ScreenLoaderProps) {
  const { visible = false, variant = 'blue', size = '3x', speed = 'normal', top = '0' } = props;

  return (
    <Container $speed={speed} $top={top} className={visible ? 'visible' : 'hidden'}>
      <Loader size={size} variant={variant} />
    </Container>
  );
}

const Container = styled.div<{ $speed: string; $top: string }>`
  position: absolute;
  top: ${({ $top }) => $top};
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgb(255, 255, 255);
  z-index: 10000;

  ${({ $speed }) => {
    let speed = '0.3s';
    switch ($speed) {
      case 'fast':
        speed = '0.1s';
        break;
      case 'slow':
        speed = '0.5s';
        break;
    }

    return `
      &.visible {
        visibility: visible;
        opacity: 1;
        transition: opacity ${speed} linear;
      }

      &.hidden {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s ${speed}, opacity ${speed} linear;
      }
    `;
  }}
`;

import { useLocation } from 'src/queries/curbside/location-details';
import { useUser } from 'src/queries/curbside/user-details';

export const useBaseAPIParams = () => {
  const { data: user } = useUser();
  const { data: location } = useLocation();

  return {
    LspId: user?.LspId,
    LocId: location?.locationId,
    UserId: user?.UserId,
  };
};

import { createSlice } from '@reduxjs/toolkit';

import { type RegisterInfo } from 'src/queries/curbside/get-registers';
import { type LoginResponse } from 'src/queries/curbside/login';

import type { PayloadAction } from '@reduxjs/toolkit';

export enum Path {
  Curbside = 'Curbside',
  Delivery = 'Delivery',
}

export type SetUserActionPayload = Pick<LoginResponse, 'Id' | 'SessionGId'>;

export type UserState = {
  id?: LoginResponse['Id'];
  selectedLocationId?: number;
  selectedPath?: Path;
  selectedRegister?: RegisterInfo;
  selectedRouteId?: number;
  session?: LoginResponse['SessionGId'];
};

const initialState: UserState = {};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: () => initialState,
    setUser: (state, action: PayloadAction<SetUserActionPayload>) => {
      state.id = action.payload.Id;
      state.session = action.payload.SessionGId;
    },
    resetUser: (state) => {
      state.id = undefined;
      state.session = undefined;
      state.selectedLocationId = undefined;
      state.selectedPath = undefined;
      state.selectedRegister = undefined;
    },
    setLocation: (state, action: PayloadAction<number>) => {
      state.selectedLocationId = action.payload;
    },
    setPath: (state, action: PayloadAction<Path>) => {
      state.selectedPath = action.payload;
    },
    setRegister: (state, action: PayloadAction<RegisterInfo>) => {
      state.selectedRegister = action.payload;
    },
    setSelectedRouteId: (state, action: PayloadAction<number>) => {
      state.selectedRouteId = action.payload;
    },
  },
});

export const { setUser, resetUser, setLocation, logout, setPath, setRegister, setSelectedRouteId } = userSlice.actions;

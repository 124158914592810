export const cleanScanString = (data: string, selectedLspId: number | undefined) => {
  // Why do we clean the scanned bardcode?
  // scanners like to add spaces or ~ to the front of the barcode
  // or other characters depending on how the scanner is programmed or what one they use
  let serialCleaned = data.replace(/^[^a-zA-Z0-9(]*|[^a-zA-Z0-9)]*$/g, '');

  // GREEN HOUSE 1041 BLAME TOM
  // compensate for scanner, it adds '2' in front of some barcodes
  if (selectedLspId === 1041 && serialCleaned.length === 17 && serialCleaned.startsWith('2')) {
    serialCleaned = serialCleaned.substring(1);
  }

  return serialCleaned;
};

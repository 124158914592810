import { useCallback, useContext, useEffect, useState } from 'react';

import { useParams } from 'react-router';

import type { CartParams } from 'src/app/types/params';
import { useSaveSignature } from 'src/queries/curbside/save-signature';
import { successNotification, warningNotification } from 'src/store/actions/notifications-actions';
import { useAppDispatch } from 'src/utils';

import { signatureContext, type SignatureContextProps } from './provide-signature';
import { emptySignatureState, type SignatureState } from './signature-state';

export type Signature = {
  current: SignatureState;
  hide: () => void;
  onSuccess: (handleOnSuccess: () => void) => void;
  show: () => void;
  submit: (imgData: string) => void;
};

export const useSignature = () => {
  // Local state
  const [imgData, setImgData] = useState<string>();

  // Context
  const maybeGetAndSetScannerState = useContext(signatureContext);

  // Hooks
  const dispatch = useAppDispatch();
  const { shipmentId } = useParams<CartParams>();
  // TODO 57212: how to handle loading state here?
  const {
    mutate: saveSignature,
    isSuccess: signatureSaved,
    isError: signatureFailed,
  } = useSaveSignature({
    ShipmentId: Number(shipmentId),
    Signature: imgData ?? '',
  });

  if (!maybeGetAndSetScannerState) {
    throw new Error('Context not available, did you use ProvideSignature');
  }

  const createSignature = useCallback(
    ([signatureState, setSignatureState]: SignatureContextProps): Signature => {
      const updateState = (change: Partial<SignatureState>) => setSignatureState({ ...signatureState, ...change });

      return {
        show: () => updateState({ show: true }),
        hide: () => setSignatureState(emptySignatureState),
        submit: (img: string) => {
          if (!shipmentId) {
            throw new Error('Please initialize the scanner with a shipmentId to submit');
          }

          setImgData(img);
        },
        onSuccess: (handleOnSuccess) => updateState({ onSuccess: handleOnSuccess }),
        current: signatureState,
      };
    },
    [shipmentId]
  );

  const [signature, setSignature] = useState<Signature>(createSignature(maybeGetAndSetScannerState));

  useEffect(() => {
    if (imgData) {
      saveSignature();
    }
  }, [imgData, saveSignature]);

  useEffect(() => {
    if (signatureSaved && signature.current.show) {
      void dispatch(successNotification('Signature saved'));
      if (signature.current.onSuccess) {
        signature.current.onSuccess();
      }
      signature.hide();
    } else if (signatureFailed) {
      void dispatch(warningNotification('Could not save signature. Try again'));
    }
  }, [signatureSaved, signatureFailed, signature, dispatch]);

  useEffect(() => {
    setSignature(createSignature(maybeGetAndSetScannerState));
  }, [maybeGetAndSetScannerState, createSignature]);

  return signature;
};

export const withSignature = (WrappedComponent) => (props) =>
  <WrappedComponent {...props} signature={useSignature()} />;

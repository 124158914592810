import { type FC, useState } from 'react';

import styled from 'styled-components';

import { DutchieButton } from 'src/components/dutchie-button';
import { colors } from 'src/css/theme';

import { ContentTitle } from './content-title';
import { DutchieDatePicker } from './date/date-picker';

type VerifyDobProps = {
  onCancel: () => void;
  onSubmit: (item: any) => void;
};

export const VerifyDOB: FC<VerifyDobProps> = ({ onSubmit, onCancel }) => {
  const [dob, setDob] = useState<Date | null>(null);

  return (
    <Container>
      <ContentTitle onGoBack={onCancel}>Confirm Date of Birth</ContentTitle>
      <DatePicker placeholderText='mm/dd/yyyy' selected={dob} onChange={(val) => setDob(val)} />
      <ButtonContainer>
        <ClearButton onClick={onCancel} ddActionName='Cancel (DOB check)'>Cancel</ClearButton>
        <DutchieButton disabled={dob === null} onClick={() => onSubmit(dob)} ddActionName='Submit (DOB check)'>
          Submit
        </DutchieButton>
      </ButtonContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const DatePicker = styled(DutchieDatePicker)`
  width: 100%;
  margin: auto 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 2.5rem 0;
  border-top: 1px solid ${colors.dutchie.borderGrey};
`;

const ClearButton = styled(DutchieButton)`
  padding: 0;
  border: none;
  background: none;
  color: ${colors.dutchie.almostBlack};
  text-decoration: underline;
`;

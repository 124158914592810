import { useMemo } from 'react';

import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import { store } from 'src/store';

import { useLogger } from './logger';

export function ReduxProvider({ children }: { children: React.ReactNode }) {
  const { getAllMiddleware } = useLogger();
  const loggers = getAllMiddleware();

  const reduxStore = useMemo(() => {
    const createdStore = store(loggers);
    return {
      store: createdStore,
      persistor: persistStore(createdStore),
    };
  }, [loggers]);

  return (
    <Provider store={reduxStore.store}>
      <PersistGate loading={null} persistor={reduxStore.persistor}>
        {children}
      </PersistGate>
    </Provider>
  );
}

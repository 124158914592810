import { useMutation } from '@tanstack/react-query';

import { post } from 'src/utils/helpers/http';
import { useBaseAPIParams } from 'src/utils/hooks/useBaseAPIParams';

const EMAIL_RECEIPT_URL = '/v2/cart/email_receipt';

type useEmailReceiptMutationType = {
  Email: string;
  onSuccess: (resp: any) => void;
  ShipmentId?: number;
};

export function useEmailReceipt({ onSuccess, Email, ShipmentId }: useEmailReceiptMutationType) {
  const baseAPIParams = useBaseAPIParams();

  return useMutation({
    mutationFn: async () => {
      const resp = await post(EMAIL_RECEIPT_URL, {
        ...baseAPIParams,
        Email,
        ShipmentId,
      });

      return resp;
    },
    onSuccess,
  });
}

export type GuestListResponseObjectType = {
  Data: GuestListType[];
  Message: string;
  Result: boolean;
};

export type GuestListType = {
  Attestation: string;
  AttestationExpirationDate?: string;
  avatar: string;
  CarName?: string;
  CellPhone?: string;
  CheckInDate: string;
  CheckInDateUTC: string;
  City: string;
  DeliveryArrivalDate?: string;
  DeliveryDepartureDate?: string;
  DiscountGroup1?: string;
  DiscountGroup2?: any;
  DiscountGroup3?: any;
  DiscountGroup4?: any;
  DiscountGroup5?: any;
  Dob: string;
  doctor?: number;
  EmailAddress?: string;
  feePaymentMethodIds?: number[];
  FullName: string;
  Guest_id: number;
  HasDutchiePay: boolean;
  HasPreorder: boolean;
  HasRethinkPreAuth: boolean;
  identifications: GuestListIdentificationType[];
  IsAnonymous: boolean;
  IsDutchiePayUser: boolean;
  IsLoyaltyMember: boolean;
  IsMedical: boolean;
  IsNewCustomer: boolean;
  IsPrePaid: boolean;
  LastPurchaseDate?: string;
  ManifestTitle: string;
  MJStateIDNo: string;
  nickname: string;
  OrderSource: string;
  OrderTotal: number;
  OrderType: string;
  PatientPhone?: string;
  PatientType: string;
  PostalCode?: string;
  PreOrderCnt: number;
  Pronoun?: string;
  Register: string;
  RegisterId: string;
  RemotePayTransactionId?: any;
  Room: string;
  ScheduleId: string;
  ShipmentDateUTC: string;
  ShipmentId: string;
  State?: string;
  Street1: string;
  Street2?: string;
  TimeWindowEndDate?: string;
  TimeWindowStartDate?: string;
  TotalItems: number;
  TransactionCaregiver: string;
  TransactionReference?: string;
  TransactionStatus: string;
  ValidPrescription: boolean;
};

type GuestListIdentificationType = {
  additionalStateIdentifiers?: any;
  ExpirationDate: string;
  image_url: string;
  number: string;
  StartDate?: any;
  type: string;
};

export type AllotmentCategory = {
  AllowDeviceDispensation?: boolean;
  Category: string;
  CustomerTypeId: number;
  InCart: number;
  Limit: number;
  OrderId?: number;
  OrderType?: string;
  UnitId?: number;
  Used: number;
};

export type Allotment = {
  CurrentLimit: number;
  Details: AllotmentCategory[];
  Error: boolean;
  ErrorMessage?: string;
  MaxLimit: number;
  TotalInCart: number;
  TotalLimit: number;
  TotalRemaining: number;
  TotalUsed: number;
  ValidFrom?: Date;
  ValidUntil?: Date;
  Warning: boolean;
  WarningMessage?: string;
};

export type CartItem = {
  AllowSaleToMinor?: number;
  AutoPrintLabel?: number;
  BatchId?: number;
  BrandId?: number;
  BrandName?: string;
  CannbisProduct?: string;
  Customer?: number;
  DaysSupply?: number;
  DaysSupplyPerItem?: number;
  DefaultLabelId?: number;
  DefaultUnitId?: number;
  DiscountAmt?: number;
  Equivalent?: number;
  Grams?: number;
  Id: number;
  Instructions?: string;
  InventoryId?: number;
  LoyaltyAmt?: number;
  MMURDeviceId?: number;
  MMURFormId?: number;
  MMUROrderId?: number;
  Multiplier?: number;
  OrderDate?: number;
  OrderNo: string;
  PackageItemId?: number;
  PackageItemSerialNumber?: string;
  PricedQuantity?: number;
  Product: string;
  ProductCategory?: string;
  ProductId: number;
  QtyAllocated: number;
  QtySelected: number;
  RowInventoryIds?: number[];
  SerialNo: string;
  Taxable?: number;
  TaxAmt?: number;
  TotalCost: number;
  WgtCnt: 'Qty' | 'Wgt';
};

export type AppliedDiscount = {
  AppliedDiscountId: number;
  CheckBox: string;
  DiscountAmt: number;
  DiscountId: number;
  DiscountReason: string;
  InvId: number;
  IsLoyalty: number; // not a boolean but tinyint, it's either 0 or 1
  Items: AppliedDiscount[];
  ProductName: string;
  SerialNumber: string;
};

export enum PaymentType {
  Cash,
  Credit,
  Debit,
  Check,
  'Gift Card',
  Digital,
  Prepayment,
  SplitPayment,
  Dutchie, // Ecomm-based DutchiePay
  DutchiePay, // In-store based DutchiePay
  MMAP,
}

export type HypurCheckoutRequest = {
  CheckInISN: string;
  IndividualISN: string;
  Pin?: string;
};

export type LinxCheckoutRequest = {
  CardNumber: string;
  CustomerState: string;
  DriversLicenseNumber: string;
};

export type CanPayCheckoutRequest = {
  Token?: string;
};

export type PreauthInfo = {
  Currency: string;
  FinalAmount?: number;
  OrderId: number;
  PaymentType: string;
  PreauthAmount: number;
  PreauthDate?: Date;
  PreauthId: string;
  PreauthTipAmount: number;
  ShipmentId: number;
};

export type PaymentMethod = {
  amount: number;
  authCode?: string;
  data?: CanPayCheckoutRequest | HypurCheckoutRequest | LinxCheckoutRequest | PreauthInfo;
  finalized?: boolean;
  id: string;
  name: string;
  originalAmount?: number;
  PaymentProviderType?: string;
  tipAmount?: number;
  type: PaymentType;
};

export type ExistingPaymentMethod = PaymentMethod & {
  Last4: string;
  PaymentProviderType: string;
  PollingComplete: boolean;
};

export enum FeePaymentMethod {
  DutchiePay = 1,
  PinDebit = 2,
}

export type AppliedFeeDonation = {
  AppliedFeeDonationId: number;
  CalculationMethod: number;
  CashValue: number;
  FeeDonationId: number;
  FeePaymentMethod: FeePaymentMethod;
  IsAutomatic: boolean;
  Name: string;
};

export type PaymentTypeFee = {
  CalculationMethodId: number;
  CalculationValue: number;
  CashValue: number;
  Description: string;
  FeeDonationId: number;
  FeePaymentMethod: number;
  ShipmentId: number;
};

export type Loyalty = {
  AppliedLoyaltyPoints: number;
  TransactionId: number;
};

export type Payment = {
  ElectronicAmount: number;
  ElectronicType: string;
};

export type PrepaymentPayInfo = {
  Amount: number;
  OrderId: number;
  PrepaidOn?: Date;
  PrepaymentId: string;
  PrepaymentType: string;
  ShipmentId: number;
};

export type PreOrderRedemption = {
  AlpineId: string;
  CallbackUrl: string;
  FylloId: number;
  IsRedeemed: boolean;
  LeafLogixCode: string;
  OrderId: number;
  OrderRedemptionId: number;
  ProductId?: number;
  RedemptionDescription: string;
  SpringBigId: string;
  SpringBigType: string;
};

export type Preorder = {
  Abbreviation: string;
  BrandId?: number;
  BrandName?: string;
  Category: string;
  Customer: string;
  Grams: number;
  Grower: string;
  Id: number;
  InventoryDate: Date;
  Location: string;
  OrderDate: Date;
  OrderId: number;
  OrderItemId: number;
  OrderNo: string;
  OrderStatus: string;
  PackageItemSerialNumber?: string;
  Product: string;
  ProductId: number;
  ProductImageURL?: string;
  QtySelected: number;
  SerialNo: string;
  Strain: string;
  TotalCost: number;
  UnitId: number;
  UnitName: number;
};

export type PrescriptionData = {
  CaregiverName?: string;
  Dosage?: string[];
  Expiration?: Date;
  Notes?: string;
  PrescriberName: string;
  PrescriberPhone?: string;
  Recommendation: string;
  RequiresCaregiver: boolean;
};

export type Cart = {
  Allotment: Allotment;
  Cart: CartItem[];
  CurrentRegister: number;
  CurrentRoom: string;
  CustomerId: number;
  Deliverable: boolean;
  Discounts: AppliedDiscount[];
  ExistingPayments: ExistingPaymentMethod[];
  /** The dollar value of all fees and donations applied to a cart */
  FeesAndDonations: number;
  /** Reference list of all fees that could be applied to cart.  Enables display of available fees and associated values in UI */
  FeesByPaymentType: PaymentTypeFee[];
  /** List of all fees that have been applied to a cart */
  FeesDonations: AppliedFeeDonation[];
  GrandTotal: number;
  GrandTotalRounded: number;
  Locked: boolean;
  Loyalty?: Loyalty;
  Payment: Payment;
  PreauthInfo: PreauthInfo;
  PreOrderRedemptions: PreOrderRedemption[];
  PreOrders: Preorder[];
  PrepaymentPayInfo?: PrepaymentPayInfo[];
  PrescriptionData?: PrescriptionData;
  RoundingAmount: number;
  ScheduleId: string;
  ShipmentId: number;
  SubTotal: number;
  Tax: number;
  TipAmount?: number;
  TotalDiscount: number;
  TotalDiscountAndLoyalty: number;
  TotalItems: number;
  TransactionReference: string;
  TransactionStatus: string;
  VerifiedBy?: number;
};

export type DeliveryRouteResponseType = {
  Data: DeliveryRouteType[];
  Message?: any;
  Result: boolean;
};

export type DeliveryRouteType = {
  CarId: number;
  CarName: string;
  DeliveryRouteId: number;
  DeliveryStatus: string;
  DeliveryStatusId: number;
  DrawerStatusId: number;
  Driver1Id: number;
  Driver1Name: string;
  Driver2Id?: any;
  Driver2Name?: any;
  EndDate: string;
  Note?: any;
  NumberOfOrders: number;
  RegisterId: number;
  RegisterName: string;
  StartDate: string;
};

export type GetOrdersForRouteResponseType = {
  Data: GetOrdersForRouteType[];
  Message?: any;
  Result: boolean;
};

export type GetOrdersForRouteType = {
  AddressId: number;
  ArrivalDate?: any;
  AssignedToRouteBy?: any;
  AssignedToRouteByName?: any;
  CarId: number;
  CarMake: string;
  CarModel: string;
  CellPhone: string;
  CheckInDate: string;
  CheckInDateUTC: string;
  city: string;
  Comments: string;
  CreditPaid: number;
  DebitPaid: number;
  DeliveryRouteId: number;
  DeliveryStatus: string;
  DeliveryStatusId: number;
  DepartureDate?: any;
  Dob: string;
  DriverId: number;
  EmailAddress?: string;
  ExternalDeliveryId?: any;
  feePaymentMethodIds?: number[];
  FulfilledBy: string;
  FullName: string;
  GeoCodeError?: any;
  GiftPaid: number;
  Guest_id: number;
  InvoiceTotal: number;
  IsAutoDispatched: boolean;
  Lat: number;
  Lng: number;
  ManifestHasSignature: boolean;
  ManifestStopNumber: number;
  ManifestTitle: string;
  MJStateIDNo: string;
  MMAPPaid: number;
  nickname: string;
  OrderTotal: string;
  PatientPhone: string;
  PatientType: string;
  PosId: string;
  postal_code: string;
  PreOrderCnt: number;
  Register?: any;
  RegisterId: string;
  RemotePayTransactionId?: any;
  Room: string;
  ScheduleId: string;
  SecondaryDriverId?: any;
  ShipmentDelivered?: any;
  ShipmentId: string;
  ShipmentStatusId: number;
  state: string;
  Status?: any;
  street: string;
  street2?: any;
  TimeWindowEndDate?: any;
  TimeWindowStartDate?: any;
  TipAmount: number;
  TotalDueCustomer: number;
  TotalItems: number;
  TotalPaid: number;
  TransactionReference?: any;
  TransactionStatus: string;
};

export type Customer = {
  Address?: Address;
  Dob: string;
  Email?: string;
  FullName: string;
  Guest_id: number;
  Instructions?: string;
  IsDelivery: boolean;
  Phone?: string;
  RegisterId: number;
  ShipmentId: number;
};

export type DeliveryInfo = {
  Address: Address;
  Instructions: string;
  IsDelivery: boolean;
};

export type Address = {
  additionalStateIdentifiers: string;
  AddressId: number;
  city: string;
  Country_Code: string;
  County: string;
  CustomerId: number;
  Latitude: number;
  Longitude: number;
  postal_code: string;
  state: string;
  street: string;
  street2: string;
};

export type CarDetails = {
  CarId: number;
  CarMake: string;
  CarModel: string;
  CarYear: string;
  CashOnHand: number;
  DeliveryMaxICTInventoryDollars?: any;
  DeliveryMaxTotalInventoryDollars?: any;
  OrderedInventoryDollarValue: number;
  UnorderedInventory: number;
};

import { type FC } from 'react';

import styled from 'styled-components';

import CheckMarkSvg from 'src/assets/checkmark-green.svg?react';
import { colors } from 'src/css/theme';

type AssignedPackagePillProps = {
  packageId: string;
};

export const AssignedPackagePill: FC<AssignedPackagePillProps> = ({ packageId }) => (
  <AssignedPackageIdPill>
    <CheckMarkIcon data-testid='cart-line-item_check-mark-icon_packageId' />
    {packageId}
  </AssignedPackageIdPill>
);

const PackageIdPill = styled.div`
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.5%;
  color: ${colors.dutchie.gray90};
  background: ${colors.dutchie.opal10};
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem 0.75rem;
  border-radius: 0.875rem;
`;

const AssignedPackageIdPill = styled(PackageIdPill)`
  color: white;
  background: ${colors.dutchie.green};
`;

const CheckMarkIcon = styled(CheckMarkSvg)`
  width: 12px;
  height: 12px;

  path {
    fill: white;
  }
`;

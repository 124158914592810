const baseUrl = '/api/';
const host = import.meta.env.VITE_API_URL || '';

export const getUrl = (url: string): string => {
  if (url.includes('/api')) {
    return host + url;
  }

  let base = baseUrl;
  let route = url;
  if (base && base.length > 0 && !base.endsWith('/')) {
    base = `${base}/`;
  }

  if (route && route.length > 0 && route.startsWith('/')) {
    route = route.substring(1);
  }

  return `${host}${base}${route}`;
};

export * from './get-environment-details';

/**
 * TODO: copied from @dutchie/error-messaging jfrog package. Need to actually set up jfrog and delete this file.
 */
export type Message = { ecomm: string; pos: string; source?: string };
export type MessageMap = Map<string, Message>;

export function getMessage(messageMap: MessageMap, defaultMsg: string, key?: string): Message {
  const defaultResponse = { ecomm: defaultMsg, pos: defaultMsg, source: undefined };
  if (!key) {
    return defaultResponse;
  }

  const message = messageMap.get(key);
  return message !== undefined ? message : defaultResponse;
}

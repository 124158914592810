import * as React from "react";
const SvgCheckboxOn = (props) => /* @__PURE__ */ React.createElement("svg", { width: "100%", height: "100%", viewBox: "0 0 16 16", xmlns: "http://www.w3.org/2000/svg", style: {
  fillRule: "evenodd",
  clipRule: "evenodd",
  strokeLinejoin: "round",
  strokeMiterlimit: 2
}, ...props }, /* @__PURE__ */ React.createElement("path", { d: "M0,3C0,1.343 1.343,0 3,0L13,0C14.657,0 16,1.343 16,3L16,13C16,14.657 14.657,16 13,16L3,16C1.343,16 0,14.657 0,13L0,3Z", style: {
  fill: "rgb(0,117,224)",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M12.207,4.793C12.395,4.981 12.5,5.235 12.5,5.5C12.5,5.765 12.395,6.02 12.207,6.207L7.207,11.207C7.02,11.395 6.766,11.5 6.5,11.5C6.235,11.5 5.981,11.395 5.793,11.207L3.793,9.207C3.611,9.018 3.51,8.766 3.513,8.504C3.515,8.241 3.62,7.991 3.806,7.805C3.991,7.62 4.242,7.515 4.504,7.512C4.766,7.51 5.019,7.611 5.207,7.793L6.5,9.086L10.793,4.793C10.981,4.606 11.235,4.5 11.5,4.5C11.766,4.5 12.02,4.606 12.207,4.793Z", style: {
  fill: "white",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("g", { transform: "matrix(0.85347,0,0,0.85347,1.17224,1.17224)" }, /* @__PURE__ */ React.createElement("path", { d: "M3,1L13,1L13,-1L3,-1L3,1ZM15,3L15,13L17,13L17,3L15,3ZM13,15L3,15L3,17L13,17L13,15ZM1,13L1,3L-1,3L-1,13L1,13ZM3,15C1.895,15 1,14.105 1,13L-1,13C-1,15.209 0.791,17 3,17L3,15ZM15,13C15,14.105 14.105,15 13,15L13,17C15.209,17 17,15.209 17,13L15,13ZM13,1C14.105,1 15,1.895 15,3L17,3C17,0.791 15.209,-1 13,-1L13,1ZM3,-1C0.791,-1 -1,0.791 -1,3L1,3C1,1.895 1.895,1 3,1L3,-1Z", style: {
  fill: "rgb(0,117,224)",
  fillRule: "nonzero"
} })));
export default SvgCheckboxOn;

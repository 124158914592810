import { useQuery } from '@tanstack/react-query';

import { Path } from 'src/store/slices/user';
import { useAppSelector } from 'src/utils';
import { post } from 'src/utils/helpers/http';
import { useBaseAPIParams } from 'src/utils/hooks/useBaseAPIParams';

export const GuestListKey = 'guest-list-key';

export const GET_GUEST_LIST_URL = '/v2/guest/checked-in';
const REFETCH_INTERVAL = 10000;

export type GuestListResponseObjectType = {
  Data: GuestListType[];
  Message: string;
  Result: boolean;
};

export type GuestListType = {
  Attestation: string;
  AttestationExpirationDate?: string;
  avatar: string;
  CarName?: string;
  CellPhone?: string;
  CheckInDate: string;
  CheckInDateUTC: string;
  City: string;
  DeliveryArrivalDate?: string;
  DeliveryDepartureDate?: string;
  DiscountGroup1?: string;
  DiscountGroup2?: any;
  DiscountGroup3?: any;
  DiscountGroup4?: any;
  DiscountGroup5?: any;
  Dob: string;
  doctor?: number;
  EmailAddress?: string;
  feePaymentMethodIds?: number[];
  FullName: string;
  Guest_id: number;
  HasDutchiePay: boolean;
  HasPreorder: boolean;
  HasRethinkPreAuth: boolean;
  identifications: GuestListIdentificationType[];
  IsAnonymous: boolean;
  IsDutchiePayUser: boolean;
  IsLoyaltyMember: boolean;
  IsMedical: boolean;
  IsNewCustomer: boolean;
  IsPrePaid: boolean;
  LastPurchaseDate?: string;
  ManifestTitle: string;
  MJStateIDNo: string;
  nickname: string;
  OrderSource: string;
  OrderTotal: number;
  OrderType: string;
  PatientPhone?: string;
  PatientType: string;
  PostalCode?: string;
  PreOrderCnt: number;
  Pronoun?: string;
  Register: string;
  RegisterId: string;
  RemotePayTransactionId?: any;
  Room: string;
  ScheduleId: string;
  ShipmentDateUTC: string;
  ShipmentId: string;
  State?: string;
  Street1: string;
  Street2?: string;
  TimeWindowEndDate?: string;
  TimeWindowStartDate?: string;
  TotalItems: number;
  TransactionCaregiver: string;
  TransactionReference?: string;
  TransactionStatus: string;
  ValidPrescription: boolean;
};

type GuestListIdentificationType = {
  additionalStateIdentifiers?: any;
  ExpirationDate: string;
  image_url: string;
  number: string;
  StartDate?: any;
  type: string;
};

export function useGuestList() {
  const baseAPIParams = useBaseAPIParams();
  const selectedRegister = useAppSelector((state) => state.user.selectedRegister?.id);
  const selectedPath = useAppSelector((state) => state.user.selectedPath);
  const registerId = selectedPath === Path.Curbside ? selectedRegister : 0;

  return useQuery({
    queryKey: [GuestListKey, registerId],
    queryFn: async () => {
      const res = await post<GuestListType[]>(GET_GUEST_LIST_URL, baseAPIParams);

      return res;
    },
    enabled: selectedPath === Path.Curbside,
    refetchInterval: REFETCH_INTERVAL,
    staleTime: REFETCH_INTERVAL || 1000 * 60 * 10,
    gcTime: REFETCH_INTERVAL || 1000 * 60 * 10,
  });
}

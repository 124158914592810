import { type QueryKey, useMutation } from '@tanstack/react-query';

import { post } from 'src/utils/helpers/http';
import { useBaseAPIParams } from 'src/utils/hooks/useBaseAPIParams';

export const VerifyDOB: QueryKey = ['verify-dob'];

const VERIFY_DOB_URL = '/v2/cart/verify-customer-birthdate';

export type useVerifyDOBQueryType = {
  onError: () => void;
  onSuccess: () => void;
};

type VerifyDOBPayload = {
  birthdate: Date;
  shipmentId: number;
};

export function useVerifyDOB({ onSuccess, onError }: useVerifyDOBQueryType) {
  const baseAPIParams = useBaseAPIParams();

  return useMutation({
    mutationFn: async ({ birthdate, shipmentId }: VerifyDOBPayload) => {
      const res = await post(VERIFY_DOB_URL, {
        ...baseAPIParams,
        Birthdate: birthdate,
        ShipmentId: shipmentId,
      });
      return res;
    },
    mutationKey: VerifyDOB,
    onSuccess,
    onError,
  });
}

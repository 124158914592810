import { css } from '@emotion/react';

import { additionalTokens } from './additional-tokens';
import { fontFaces } from './font-faces';
import { generatedTokens } from './generated-tokens';

export const tokens = css`
  ${fontFaces}
  ${additionalTokens}
  ${generatedTokens}
`;

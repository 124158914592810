import { useState } from 'react';

import { useNavigate, useParams } from 'react-router';
import styled from 'styled-components';

import { ScreenLoader } from '@dutchie-pos/components';
import { LinkButton } from 'src/components/link-button';
import { colors } from 'src/css/theme';
import { useDeliveryInfo } from 'src/queries/curbside/get-delivery-info';
import { useActiveCustomer } from 'src/queries/curbside/use-carts';
import { getManifestBytes } from 'src/queries/v2/manifest/get-manifest-bytes';
import { Path } from 'src/store/slices/user';
import { useAppSelector } from 'src/utils';
import { useBaseAPIParams } from 'src/utils/hooks/useBaseAPIParams';

import { ContentTitle } from '../../content-title';
import { useDocumentViewer } from '../../document-viewer';
import { headerHeight } from '../../header';

import type { CartParams } from 'src/app/types/params';
import type { DeliveryInfo } from 'src/queries/curbside/types';

export const getAddressQuery = (deliveryInfo: DeliveryInfo | undefined) => encodeURI(getFormattedAddress(deliveryInfo));

export const getFormattedAddress = (deliveryInfo: DeliveryInfo | undefined) => {
  let address = deliveryInfo?.Address?.street ?? '';
  address += deliveryInfo?.Address?.city ? `, ${deliveryInfo.Address.city}` : '';
  address += deliveryInfo?.Address?.state ? `, ${deliveryInfo.Address.state}` : '';
  address += deliveryInfo?.Address?.postal_code ? ` ${deliveryInfo.Address.postal_code}` : '';
  return address;
};

export type CartHeaderProps = {
  onGoBack: () => void;
};

export const CartHeader = (props: CartHeaderProps): JSX.Element => {
  // Global state
  const baseAPIParams = useBaseAPIParams();
  const selectedPath = useAppSelector((state) => state.user.selectedPath);

  // Local state
  const [isFetchingManifest, setFetchingManifest] = useState(false);

  // Hooks
  const { data: activeCustomer } = useActiveCustomer();
  const documentViewer = useDocumentViewer();
  const { shipmentId } = useParams<CartParams>();
  const { data: deliveryInfo } = useDeliveryInfo();

  // Computed
  const dateOfBirth = new Date(activeCustomer?.Dob ?? '');

  // Handlers
  const getAndShowManifest = async () => {
    setFetchingManifest(true);
    try {
      const manifestData = await getManifestBytes(Number(shipmentId), baseAPIParams);
      if (manifestData) {
        documentViewer.show(manifestData);
      }
    } finally {
      setFetchingManifest(false);
    }
  };

  const navigate = useNavigate();
  // seems like if we do not have an active customer but this page is refreshed we should grab that info not redirect...
  if (!activeCustomer) {
    navigate('/carts');
  }

  return (
    <>
      <ScreenLoader speed='fast' top={headerHeight} visible={isFetchingManifest} />
      <Container>
        <ContentTitle onGoBack={props.onGoBack}>{activeCustomer?.FullName}</ContentTitle>
        <Row>
          {activeCustomer?.Phone && (
            <>
              Phone #: <a href={`tel:${activeCustomer?.Phone}`}>{activeCustomer?.Phone}</a>{' '}
            </>
          )}
          {activeCustomer?.IsDelivery && (
            <LinkButton label='View Manifest' sx={{ float: 'right' }} onClick={() => getAndShowManifest()} />
          )}
        </Row>
        <Row>
          DOB: {dateOfBirth.getMonth() + 1}/{dateOfBirth.getDate()}/{dateOfBirth.getFullYear()}
        </Row>
        <Row>TxnID: {shipmentId}</Row>
        {selectedPath === Path.Delivery && (
          <Row>
            <a href={`https://www.google.com/maps/dir/?api=1&destination=${getAddressQuery(deliveryInfo)}`}>
              {getFormattedAddress(deliveryInfo)}
            </a>
          </Row>
        )}
        {deliveryInfo?.Instructions && <Row>Delivery Instructions: {deliveryInfo?.Instructions}</Row>}
      </Container>
    </>
  );
};

const Container = styled.div`
  margin-bottom: 0.5rem;
`;

const Row = styled.div`
  color: ${colors.dutchie.grey35};
  font-size: 1rem;
  line-height: 162%;

  & > a {
    color: ${colors.dutchie.blue};
    text-decoration: underline;
  }
`;

import { useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router';
import styled from 'styled-components';

import { Button } from '@dutchie-pos/components';

import { BottomDrawer } from 'src/components/bottom-drawer';
import { DutchieInput } from 'src/components/dutchie-input';
import { GetOrdersForRouteKey } from 'src/queries/curbside/get-orders-for-route';
import { GuestListKey } from 'src/queries/curbside/guest-list';
import { useSkipDeliveryMutation } from 'src/queries/curbside/skip-delivery';
import { useGetCancellationReasonsQuery } from 'src/queries/posv3/maintenance/get-cancellation-reasons';
import { Path } from 'src/store/slices/user';
import { useAppSelector } from 'src/utils';

export function SkipDeliveryDrawer({ open, onClose }) {
  const [reasonString, setReasonString] = useState('');
  const queryClient = useQueryClient();
  const selectedRoute = useAppSelector((state) => state.user.selectedRouteId);
  const selectedPath = useAppSelector((state) => state.user.selectedPath);
  const { mutateAsync: skipDelivery } = useSkipDeliveryMutation();
  const { data: cancellationReasons } = useGetCancellationReasonsQuery({ enabled: open });
  const hasCancellationReasons = !!cancellationReasons?.length;
  const navigate = useNavigate();

  async function handleSkipDelivery(reason: string) {
    await skipDelivery(reason);
    // invalidate guest list
    await queryClient.invalidateQueries({ queryKey: [GuestListKey] });
    // invalidate orders for route
    await queryClient.invalidateQueries({ queryKey: [GetOrdersForRouteKey] });
    if (selectedPath === Path.Curbside) {
      navigate('/carts');
    } else if (selectedPath === Path.Delivery) {
      navigate(`/route/delivery/${selectedRoute}`);
    }
  }

  return (
    <BottomDrawer height={600} marginX={0} open={open} onClose={onClose}>
      <DrawerContainer>
        <DrawerTitle>Skip Delivery</DrawerTitle>
        {hasCancellationReasons && (
          <ReasonsContainer>
            <ReasonsDescription>Click a reason button below to skip this delivery</ReasonsDescription>
            {cancellationReasons.map((reason) => (
              <Button
                automationId='skip-delivery-button'
                key={reason.ReasonId}
                label={reason.Reason}
                onClick={() => handleSkipDelivery(reason.Reason)}
              />
            ))}
          </ReasonsContainer>
        )}
        {!hasCancellationReasons && (
          <ReasonsContainer>
            <ReasonsDescription>Enter a reason below to skip this delivery</ReasonsDescription>
            <DutchieInput
              placeholder='...'
              value={reasonString}
              onChange={({ target }) => setReasonString(target.value)}
            />
            <Button
              disabled={reasonString.length < 2}
              label='Skip delivery'
              onClick={() => handleSkipDelivery(reasonString)}
            />
          </ReasonsContainer>
        )}
      </DrawerContainer>
    </BottomDrawer>
  );
}

const DrawerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
`;

const DrawerTitle = styled.h2`
  margin-top: 0px;
`;

const ReasonsDescription = styled.p`
  margin: 0 0 0.25rem 0;
`;

const ReasonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

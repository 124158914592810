import { useCallback } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router';

import type { CartParams } from 'src/app/types/params';
import { GetCart } from 'src/queries/curbside/get-cart';
import { useAddScannedItemToCart } from 'src/utils/hooks/use-add-scanned-item-to-cart';

type UseScanBarcodeDrawerParams = {
  onClose: () => void;
};

export const useAddScanItem = ({ onClose }: UseScanBarcodeDrawerParams) => {
  // Hooks
  const { shipmentId = 0 } = useParams<CartParams>();
  const queryClient = useQueryClient();
  const addScannedItemToCart = useAddScannedItemToCart();

  const handleScan = useCallback(
    async (scannedString: string) => {
      await addScannedItemToCart({
        scannerId: 0,
        serialNumber: scannedString,
      });
      await queryClient.invalidateQueries({ queryKey: [GetCart, shipmentId] });

      onClose();
    },
    [onClose],
  );

  return {
    handleScan,
  };
};

import styled from '@emotion/styled';
import { IconButton as MUIIconButton } from '@mui/material';

export const StyledIconButton = styled(MUIIconButton)`
  :hover {
    background: none;
  }

  :focus {
    outline: 0;
  }
`;

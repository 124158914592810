import React, { type FC } from 'react';

import NumberFormat from 'react-number-format';
import styled, { css, createGlobalStyle } from 'styled-components';

type AmountProps = {
  automationId?: string;
  className?: string;
  customComponent?: (formattedValue: string) => React.ReactNode;
  empty?: boolean;
  hideValue?: boolean;
  loading?: boolean;
  value?: number;
};

export const CashAmount: FC<AmountProps> = ({
  className,
  value,
  empty,
  loading = false,
  hideValue,
  automationId,
  customComponent,
}) => {
  if (empty) {
    return (
      <>
        <PulseAnimationKeyframes />
        <LoadingContainer data-testid='cash-amount_loading-value' loading={loading}>
          $--.--
        </LoadingContainer>
      </>
    );
  }
  return (
    <NumberFormat
      className={className}
      data-testid={automationId}
      decimalScale={2}
      displayType='text'
      fixedDecimalScale
      prefix={hideValue ? '' : '$'}
      renderText={customComponent}
      value={hideValue ? '-' : value}
    />
  );
};

const PulseAnimationKeyframes = createGlobalStyle`
    @keyframes pulse-cash-amount {
        0% { opacity: 1 }
        50% { opacity: 0.5 }
        100% { opacity: 1 }
    }
`;

const PulseAnimation = css`
  animation-name: pulse-cash-amount;
  animation-duration: 1.2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
`;

const LoadingContainer = styled.div<{ loading?: boolean }>`
  ${({ loading }) => loading && PulseAnimation}
`;

import { type FC, useEffect, useRef, useState } from 'react';

import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import Chevron from 'src/assets/chevron-left.svg?react';
import CloseIcon from 'src/assets/icon-close.svg?react';
import { DutchieButtonWithIcon } from 'src/components/dutchie-button';
import { colors, zIndices } from 'src/css/theme';
import { type SwipeDirection, useSwipeGestures } from 'src/utils/hooks/use-swipe-gestures';

import { useDocumentViewer } from './use-document-viewer';

const DarkBlurredBackground = css`
  background: ${rgba(colors.dutchie.almostBlack, 0.4)};
  backdrop-filter: blur(0.5rem);
`;

const ShadowStyles = css`
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4), 0 4px 20px rgba(0, 0, 0, 0.5);
`;

const DocumentViewerContainer = styled.div<{ $height?: number; $show: boolean }>`
  display: ${({ $show }) => ($show ? 'flex' : 'none')};
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: ${({ $height }) => ($height ? `${$height}px` : '100vh')};
  background: ${rgba(colors.dutchie.grey30, 0.8)};
  backdrop-filter: blur(0.5rem);
  z-index: ${zIndices.overlay + 1};
`;

const DocumentContent = styled.div<{ $height?: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ $height }) => ($height ? `${$height}px` : '100%')};
  padding: 0.5rem;
`;

const DocumentImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  border-radius: 0.5rem;
`;

const PageControls = styled.div`
  ${DarkBlurredBackground}
  ${ShadowStyles}
    position: fixed;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 0.8rem;
  padding: 0.25rem;
  border-radius: 1.5rem;
`;

const PageCount = styled.div`
  color: ${colors.dutchie.backgroundGrey};
  font-size: 1.125rem;
  text-align: center;
`;

const ButtonOverDarkBackGroundStyles = css`
  &&& {
    font-size: 1.125rem;
    color: ${colors.dutchie.backgroundGrey};
    background: none;
    border-color: ${colors.dutchie.backgroundGrey};
    width: 2.5rem;
    height: 2.5rem;
    padding: 0.5rem;
    border-radius: 50%;

    svg {
      width: 100%;
      height: 100%;

      path {
        fill: ${colors.dutchie.backgroundGrey};
      }
    }

    &:disabled {
      opacity: 0.4;
    }
  }
`;

const CloseButton = styled(DutchieButtonWithIcon)`
  ${ButtonOverDarkBackGroundStyles}
  ${ShadowStyles}
    &&& {
    ${DarkBlurredBackground}
    border: none;
    position: fixed;
    top: 1rem;
    right: 1rem;
  }
`;

const ArrowButton = styled(DutchieButtonWithIcon)`
  ${ButtonOverDarkBackGroundStyles}
  &&& {
    padding: 0.25rem;
  }
`;

const NextArrow = styled(Chevron)`
  transform: rotate(180deg);
`;

export const DocumentViewer: FC = () => {
  const documentViewer = useDocumentViewer();
  const { show, docs } = documentViewer.current;
  const [vh, setVh] = useState<number>(window.innerHeight);
  const [zooming, setZooming] = useState(false);
  const [openWindows, setOpenWindows] = useState<Window[]>();
  const [currentPage, setCurrentPage] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  const closeViewer = () => {
    setCurrentPage(0);
    documentViewer.hide();
  };

  useEffect(() => {
    const closeOpenWindows = () => {
      if (openWindows) {
        for (const window of openWindows) {
          window.close();
        }
      }
    };

    window.addEventListener('focus', closeOpenWindows);
    return () => {
      window.removeEventListener('focus', closeOpenWindows);
    };
  }, [openWindows]);

  useEffect(() => {
    const updateHeight = () => setVh(window.innerHeight);
    updateHeight();

    // If zooming mode is not active, listen for resize to adjust size of document
    if (!zooming) {
      window.addEventListener('resize', updateHeight);
      return () => {
        window.removeEventListener('resize', updateHeight);
      };
    }
  }, [zooming]);

  const openDocInNewTab = (doc: string) => {
    const Img = new Image();
    Img.src = `data:image/png;base64,${doc}`;
    Img.style.width = '100%';

    const Win = window.open('');
    Win?.document.write(Img.outerHTML);
    if (Win) {
      setOpenWindows(openWindows !== undefined ? [...openWindows, Win] : [Win]);
    }
  };

  useSwipeGestures(
    ref,
    (direction: SwipeDirection) => {
      // onSwipe handler
      if (direction === 'left') {
        if (currentPage !== docs.length - 1) {
          setCurrentPage(currentPage + 1);
        }
      } else if (direction === 'right') {
        if (currentPage !== 0) {
          setCurrentPage(currentPage - 1);
        }
      } else if (direction === 'up' || direction === 'down') {
        closeViewer();
      }
    },
    () => {
      // onMultitouch handler
      // If multitouch is active, always set zooming mode on
      setZooming(true);
    },
    () => {
      // onTouchEnd handler
      // Check for attempted zooming on Android and open image in new tab
      const androidRegEx = new RegExp(/Android/gi);
      if (zooming && androidRegEx.test(navigator.userAgent)) {
        openDocInNewTab(docs[currentPage]);
      }

      // iOS only: Keep mode set to zooming only if the current viewport is already zoomed in
      const zoomedIn = window.screen.width / window.innerWidth !== 1;
      setZooming(zoomedIn);
    }
  );

  return (
    <DocumentViewerContainer $height={vh} $show={show}>
      <DocumentContent $height={vh} ref={ref}>
        {docs.length > 0 && <DocumentImage alt='Manifest' src={`data:image/png;base64,${docs[currentPage]}`} />}
      </DocumentContent>
      <CloseButton icon={CloseIcon} onClick={closeViewer} />
      <PageControls>
        <ArrowButton disabled={currentPage === 0} icon={Chevron} onClick={() => setCurrentPage(currentPage - 1)} />
        <PageCount>
          {currentPage + 1} of {docs.length}
        </PageCount>
        <ArrowButton
          disabled={currentPage === docs.length - 1}
          icon={NextArrow}
          onClick={() => setCurrentPage(currentPage + 1)}
        />
      </PageControls>
    </DocumentViewerContainer>
  );
};

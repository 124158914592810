import { useMutation } from '@tanstack/react-query';

import { post } from 'src/utils/helpers/http';
import { useBaseAPIParams } from 'src/utils/hooks/useBaseAPIParams';

const RESTOCK_URL = '/inventory-kit/restock-from-room';

export type RestockMutation = {
  onError: (ex: any) => void;
  onSuccess: (data: any) => void;
};

export type RestockRequest = {
  RegisterId: number;
  RoomId: number;
  SubRoomId: number;
};

export function useRestockMutation({ onSuccess, onError }: RestockMutation) {
  const baseAPIParams = useBaseAPIParams();
  return useMutation({
    mutationFn: async (req: RestockRequest) => {
      const resp: any = await post(
        RESTOCK_URL,
        {
          ...baseAPIParams,
          ...req,
        },
        { ignoreFormat: true }
      );

      if (!resp.Result) {
        throw resp.Message;
      }

      return resp;
    },
    mutationKey: ['restock'],
    onError,
    onSuccess,
  });
}

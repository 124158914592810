import { type FC } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import styled from 'styled-components';

import { LinkButton } from '@dutchie-pos/components';

import DutchieLogo from 'src/assets/dutchie-logo.svg?react';
import { colors, zIndices } from 'src/css/theme';
import { useLogoutMutation } from 'src/queries/posv3/user/logout';
import { logout } from 'src/store/slices/user';
import { useAppDispatch } from 'src/utils';

import { Alerts } from './alerts';

export const Header: FC = () => {
  // Hooks
  const dispatch = useAppDispatch();
  const { mutateAsync: logoutMutator } = useLogoutMutation();
  const queryClient = useQueryClient();

  // Handlers
  async function handleClickLogout() {
    await logoutMutator({ Origin: 'UserInitiatedLogout' });
    dispatch(logout());
    queryClient.clear();
  }

  return (
    <>
      <Alerts />
      <Nav data-testid='header_div_container'>
        <Logo />
        <RightContainer>
          <LinkButton label='Logout' variant='white' onClick={handleClickLogout} />
        </RightContainer>
      </Nav>
    </>
  );
};

export const headerHeight = '80px';

const Nav = styled.header`
  background: ${colors.dutchie.opal12};
  position: fixed;
  top: 0;
  left: 0;
  height: 5rem;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem;
  z-index: ${zIndices.header};
`;

const Logo = styled(DutchieLogo)`
  margin-right: auto;
  height: 1.875rem;
  width: auto;
`;

const RightContainer = styled.div`
  display: flex;
  gap: 2px;
`;

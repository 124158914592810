import { createAction } from '@reduxjs/toolkit';

import { type DrawersPayloads } from 'src/store/reducers/drawers-reducer';

export const closeDrawer = createAction('closeDrawer', (payload: string) => ({ payload }));

export const showConfirmRemoveItemDrawer = createAction(
  'showConfirmRemoveItemDrawer',
  (payload: DrawersPayloads['showConfirmRemoveItemDrawer']) => ({
    payload,
  })
);

import { useState } from 'react';

import type { PaymentFormTotals } from 'src/app/components/checkout/payment-form';
import { useCart } from 'src/queries/curbside/get-cart';
import { useAppSelector } from 'src/utils';
import { useHasPreauth } from 'src/utils/hooks/use-has-preauth';
import { useIsDutchiePay } from 'src/utils/hooks/use-is-dutchie-pay';

export enum FeePaymentMethod {
  DutchiePay = 1,
  PinDebit = 2,
  Credit = 3,
}

type UsePaymentReturn = {
  handlePaymentFailure: () => void;
  handlePaymentSuccess: () => void;
  showCheckoutSuccess: boolean;
  totals: PaymentFormTotals;
};

export const usePayment = (): UsePaymentReturn => {
  // Global state
  const changeAmountDue = useAppSelector((state) => state.mobileCheckout.changeAmountDue) ?? 0;
  const totalAmountPaid = useAppSelector((state) => state.mobileCheckout.totalAmountPaid) ?? 0;
  const isCheckoutComplete = useAppSelector((state) => state.mobileCheckout.isCheckoutComplete);

  // Local state
  const [showCheckoutSuccess, setShowCheckoutSuccess] = useState(isCheckoutComplete);

  // Hooks
  const hasPreauth = useHasPreauth();
  const isDutchiePay = useIsDutchiePay();
  const { data: cart } = useCart();

  // Computed
  const totals: PaymentFormTotals = {
    total: cart?.GrandTotalRounded ?? 0,
    subtotal: cart?.SubTotal ?? 0,
    tax: cart?.Tax ?? 0,
    discount: cart?.TotalDiscountAndLoyalty ?? 0,
    rounding: cart?.RoundingAmount ?? 0,
    feesDonations: cart?.FeesAndDonations ?? 0,
    changeDue: hasPreauth ? 0 : changeAmountDue,
    totalPaid: hasPreauth ? cart?.GrandTotalRounded ?? 0 : totalAmountPaid,
    tip: cart?.TipAmount ?? 0,
    dutchiePayFees: isDutchiePay
      ? ((cart?.FeesDonations ?? [])
          .filter((fd) => fd.FeePaymentMethod === FeePaymentMethod.DutchiePay)
          .map((fd) => fd.CashValue)
          .reduce((sum, cv) => sum + cv, 0)) ?? 0
      : 0,
    nonCashAdjustmentFees:
      ((cart?.FeesByPaymentType ?? [])
        .filter((fd) => fd.FeePaymentMethod === FeePaymentMethod.PinDebit)
        .map((fd) => fd.CashValue)
        .reduce((sum, cv) => sum + cv, 0)) ?? 0,
    creditNonCashAdjustmentFees:
      ((cart?.FeesByPaymentType ?? [])
        .filter((fd) => fd.FeePaymentMethod === FeePaymentMethod.Credit)
        .map((fd) => fd.CashValue)
        .reduce((sum, cv) => sum + cv, 0)) ?? 0,
  };

  // Handlers
  const handlePaymentSuccess = () => {
    setShowCheckoutSuccess(true);
  };

  const handlePaymentFailure = () => {
    setShowCheckoutSuccess(false);
  };

  return {
    totals,
    showCheckoutSuccess,
    handlePaymentSuccess,
    handlePaymentFailure,
  };
};

import React, { type FC } from 'react';

import { format } from 'date-fns';
import { range } from 'lodash-es';

import {
  DatePickerHeaderDiv,
  YearWrapperDiv,
  DateSpan,
  YearSelect,
  ArrowWrapperDiv,
  MonthArrow,
} from './styled-date-picker';

export type Range = {
  end: number;
  start: number;
};

type DatePickerHeaderProps = {
  changeMonth: (month: number) => void;
  changeYear: (year: number) => void;
  date: Date;
  decreaseMonth: () => void;
  decreaseYear: () => void;
  increaseMonth: () => void;
  increaseYear: () => void;
  nextMonthButtonDisabled: boolean;
  nextYearButtonDisabled: boolean;
  prevMonthButtonDisabled: boolean;
  prevYearButtonDisabled: boolean;
  yearRange: Range;
};

export const DutchieDatePickerHeader: FC<DatePickerHeaderProps> = ({
  date,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  changeYear,
  yearRange,
}) => {
  const handleSelectYear = (e: React.ChangeEvent<HTMLSelectElement>) => changeYear(parseInt(e.target.value));

  // Because _.range is a non-inclusive range, we need to add an
  // addition year back in time on the start range
  const reverseYearRange = range(yearRange.end, yearRange.start - 1);

  return (
    <DatePickerHeaderDiv>
      <YearWrapperDiv>
        <DateSpan>{format(date, 'MMMM yyyy')}</DateSpan>
        <YearSelect value={date.getFullYear()} onChange={handleSelectYear}>
          {reverseYearRange.map((value) => (
            <option key={value} value={value}>
              {value}
            </option>
          ))}
        </YearSelect>
      </YearWrapperDiv>
      <ArrowWrapperDiv>
        <MonthArrow
          disabled={prevMonthButtonDisabled}
          side='left'
          onClick={prevMonthButtonDisabled ? undefined : decreaseMonth}
        />
        <MonthArrow
          disabled={nextMonthButtonDisabled}
          side='right'
          onClick={nextMonthButtonDisabled ? undefined : increaseMonth}
        />
      </ArrowWrapperDiv>
    </DatePickerHeaderDiv>
  );
};

import { type FC, type ReactNode } from 'react';

import { createPortal } from 'react-dom';
import styled, { createGlobalStyle } from 'styled-components';

import { NotificationsContainerID } from 'src/app/components/notifications';
import { colors, zIndices } from 'src/css/theme';

const getHeight = (fullHeight: boolean, open?: boolean, height?: number): string => {
  if (!open) {
    return '0';
  }

  if (height) {
    return `${height}px`;
  }

  if (fullHeight) {
    return '100%';
  }
  return 'auto';
};

type BottomDrawerProps = {
  children: ReactNode;
  fullHeight?: boolean;
  height?: number;
  marginX?: number;
  marginY?: number;
  onClose: () => void;
  open?: boolean;
  portalId?: string;
};

export const BottomDrawer: FC<BottomDrawerProps> = ({
  portalId,
  children,
  open,
  onClose,
  fullHeight = false,
  marginX = 20,
  marginY = 20,
  height,
}) => {
  const component = (
    <>
      {open && <AdjustedNotificationContainer />}
      <OverlayDiv open={open} onClick={onClose} />
      <DrawerDiv height={getHeight(fullHeight, open, height)} marginx={marginX} marginy={marginY} open={open}>
        {children}
      </DrawerDiv>
    </>
  );

  const portalEl = document.getElementById(portalId || '');
  if (portalEl) {
    return createPortal(component, portalEl);
  }

  return component;
};

const AdjustedNotificationContainer = createGlobalStyle`
  #${NotificationsContainerID} {
    top: 115px;
  }
`;

const OverlayDiv = styled.div<{ open?: boolean }>`
  height: ${({ open }) => (open ? '80%' : '0')};
  position: absolute;
  width: 100%;
  z-index: ${zIndices.bottomDrawer};
  pointer-events: auto;
`;

const DrawerDiv = styled.div<{ height: string; marginx: number; marginy: number; open?: boolean }>`
  box-shadow: 0 0 18px rgba(33, 33, 33, 0.2);
  background-color: ${colors.background};
  bottom: 0;
  height: ${({ height }) => height};
  max-height: calc(100% - ${({ marginy: marginY }) => marginY}px);
  overflow: auto;
  position: absolute;
  transition: 0.35s ease-in-out;
  width: calc(100% - ${({ marginx: marginX }) => marginX * 2}px);
  left: ${({ marginx: marginX }) => marginX}px;
  z-index: ${zIndices.bottomDrawer + 1};
  pointer-events: auto;
  border-radius: 20px 20px 0 0;
`;

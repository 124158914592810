import { createAction } from '@reduxjs/toolkit';

import { type PopupsPayloads } from 'src/store/reducers/popups-reducer';

export const closePopup = createAction('closePopup', (payload: string) => ({ payload }));

export const showConfirmRemoveItemPopup = createAction(
  'showLast4Popup',
  (payload: PopupsPayloads['showConfirmRemoveItemPopup']) => ({
    payload,
  })
);

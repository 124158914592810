import { createAction } from '@reduxjs/toolkit';

export const setActiveCart = createAction('setActiveCart', (payload) => ({ payload }));

export const setIsCheckoutComplete = createAction('setIsCheckoutComplete', (payload) => ({ payload }));

export const setTotalAmountPaid = createAction('setTotalAmountPaid', (payload) => ({ payload }));

export const setChangeAmountDue = createAction('setChangeAmountDue', (payload) => ({ payload }));

export const setIsDutchiePayError = createAction('setIsDutchiePayError', (payload) => ({ payload }));

export const setDutchiePayErrorMessage = createAction('setDutchiePayErrorMessage', (payload) => ({ payload }));

export const setIsRethinkError = createAction('setIsRethinkError', (payload) => ({ payload }));

export const setRethinkErrorMessage = createAction('setRethinkErrorMessage', (payload) => ({ payload }));

export const setSelectedLocation = createAction('setSelectedLocation', (payload) => ({ payload }));

import { useCallback } from 'react';

import { useParams } from 'react-router';

import { type CartParams } from 'src/app/types/params';
import { convertScanResponseToProductSearchResult } from 'src/models/cart';
import { useScannedItem } from 'src/queries/v2/product/scan-product';
import { errorNotification } from 'src/store/actions/notifications-actions';

import { useAddQtyItemToCart } from './use-add-qty-item-to-cart';
import { useBaseAPIParams } from './useBaseAPIParams';

import { useAppDispatch } from '.';

type AddScannedItemToCartProps = {
  scannerId?: number | string;
  serialNumber?: string;
};

type UseAddScannedItemToCartReturn = (params: AddScannedItemToCartProps) => Promise<void>;

export const useAddScannedItemToCart = (): UseAddScannedItemToCartReturn => {
  // Global state
  // Hooks
  const { shipmentId = 0, customerId = 0 } = useParams<CartParams>();
  const dispatch = useAppDispatch();
  const addQtyItemToCart = useAddQtyItemToCart();
  const { mutateAsync: getScannedItem } = useScannedItem();
  const baseAPIParams = useBaseAPIParams();

  // Return
  return useCallback(
    async ({ serialNumber, scannerId }: AddScannedItemToCartProps) => {
      if (!serialNumber) {
        dispatch(errorNotification('Scanner did not recognize a valid barcode. Try scanning again'));

        return;
      }

      if (!shipmentId || !customerId) {
        dispatch(errorNotification('Error scanning barcode. Please refresh and try again.'));

        return;
      }

      try {
        const [foundItem] = await getScannedItem(serialNumber);

        if (!foundItem) {
          dispatch(errorNotification(`Package ${serialNumber} not found`));
          return;
        }

        const productToAdd = convertScanResponseToProductSearchResult(foundItem);

        if (productToAdd) {
          if (productToAdd.productType !== 'Qty') {
            // Can only add qty items in mobile checkout, display error
            dispatch(errorNotification('Product not configured correctly'));
            return;
          }

          await addQtyItemToCart({
            availableOz: productToAdd.totalAvailable,
            count: 1,
            defaultLabelId: productToAdd.defaultLabelId,
            defaultUnitId: productToAdd.defaultUnitId,
            inventoryId: productToAdd.invId,
            packageItemId: typeof productToAdd.packageItemId === 'number' ? productToAdd.packageItemId : null,
            productDescription: productToAdd.productDescription,
            productId: productToAdd.productId,
            recUnitPrice: productToAdd.recUnitPrice,
            serialNo: productToAdd.serialNo,
            unitPrice: productToAdd.unitPrice,
          });
        }
      } catch (e) {
        dispatch(errorNotification(`Error adding item to cart: ${e}`));
      }
    },
    [addQtyItemToCart, baseAPIParams, customerId, dispatch, shipmentId]
  );
};

import { useCallback } from 'react';

import { format } from 'date-fns';

export const DATE_PICKER_DATE_FORMAT = 'MM/dd/yyyy';
export const DATE_PICKER_DATETIME_FORMAT = 'MM/dd/yyyy - hh:mm aa';

export type DatePickerMode = 'date' | 'datetime';

export const useNumberFormatPattern = (mode: DatePickerMode, selected?: Date | null) => {
  const numberFormatPattern = useCallback(() => {
    if (mode === 'datetime') {
      const suffix = selected ? format(selected, 'aa') : 'PM';

      return `##/##/#### ##:## ${suffix}`;
    }

    return '##/##/####';
  }, [mode, selected]);

  return numberFormatPattern();
};

export const useDateFormat = (mode: DatePickerMode) => {
  const dateFormat = useCallback(() => mode === 'datetime' ? DATE_PICKER_DATETIME_FORMAT : DATE_PICKER_DATE_FORMAT, [mode]);

  return dateFormat();
};

export type DocumentViewerState = {
  /** Toggle between showing and hiding the doc/docs */
  show: boolean;
  /** An array of strings containing the bytes of the image to render */
  docs: string[];
};

export const defaultDocumentViewerState: DocumentViewerState = {
  show: false,
  docs: [],
};

import React, { type FC, type SVGProps } from 'react';

import styled from 'styled-components';

import ChevronLeft from 'src/assets/chevron-left.svg?react';
import { colors } from 'src/css/theme';

export type ButtonProps = {
  automationId?: string;
  ddActionName?: string;
  cta?: boolean;
  dutchiePayThemed?: boolean;
  fontSize?: string;
  fullWidth?: boolean;
  height?: string;
  isAdornment?: boolean;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  marginTop?: number;
  padding?: string;
  secondary?: boolean;
  tertiary?: boolean;
  width?: string;
};

export const DutchieButton = styled(
  ({
    automationId,
    ddActionName,
    cta,
    dutchiePayThemed,
    fontSize,
    fullWidth,
    height,
    isAdornment,
    marginBottom,
    marginLeft,
    marginRight,
    marginTop,
    padding,
    secondary,
    tertiary,
    width,
    ...rest
  }: ButtonProps) => <button {...rest} data-testid={automationId} data-dd-action-name={ddActionName} />
) <ButtonProps>`
  background-color: ${colors.dutchie.blue};
  border: solid 1px ${colors.dutchie.blue};
  color: ${colors.dutchie.primaryWhite};
  cursor: pointer;
  border-radius: 6px;
  padding: ${({ padding }) => padding ?? '0 20px'};
  font-size: ${({ fontSize }) => fontSize ?? '14px'};
  line-height: 20px;
  font-weight: 600;
  text-align: center;
  height: ${({ height }) => height ?? '44px'};

  ${({ width }) => width && `width: ${width};`}
  ${({ fullWidth }) => fullWidth && 'width: 100%;'}

    &:disabled {
    pointer-events: none;
    color: ${colors.dutchie.primaryWhite};
    background: ${colors.dutchie.gray50};
    border-color: ${colors.dutchie.gray50};
  }

  ${({ secondary }) =>
    secondary &&
    `
        color: ${colors.dutchie.darkGrey};
        background-color: ${colors.white};
        border: solid 1px ${colors.dutchie.borderGrey};
        &:hover {
            color: ${colors.dutchie.almostBlack};
            border-color: ${colors.dutchie.almostBlack};
        }
        &:disabled {
            color: ${colors.dutchie.gray60};
            background: ${colors.dutchie.primaryWhite};
            border-color: ${colors.dutchie.gray60};
        }
    `}

  ${({ tertiary }) =>
    tertiary &&
    `
        color: ${colors.dutchie.gunmetal};
        background-color: ${colors.dutchie.backgroundGrey};
        border: solid 1px ${colors.dutchie.backgroundGrey};
        &:disabled {
            color: ${colors.dutchie.gray70};
            background: ${colors.dutchie.gray20};
            border-color: ${colors.dutchie.gray20};
        }
    `}

    ${({ dutchiePayThemed }) =>
    dutchiePayThemed &&
    `
        background-color: ${colors.dutchie.green};
        border: solid 1px ${colors.dutchie.green};
    `}

    ${({ dutchiePayThemed }) =>
    dutchiePayThemed &&
    `
        background-color: ${colors.dutchie.green};
        border: solid 1px ${colors.dutchie.green};
    `}

    ${({ cta, height }) =>
    cta &&
    `
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-height: ${height ?? '3.5rem'};
        font-size: 1rem;
        line-height: 1.5rem;
        padding: 1rem 1.5rem;
        letter-spacing: 0.5%;
    `}

    ${({ marginBottom }) =>
    marginBottom &&
    `
        margin-bottom: ${marginBottom}px;
    `}
    ${({ marginTop }) =>
    marginTop &&
    `
        margin-top: ${marginTop}px;
    `}
    ${({ marginLeft }) =>
    marginLeft &&
    `
        margin-left: ${marginLeft}px;
    `}
    ${({ marginRight }) =>
    marginRight &&
    `
        margin-right: ${marginRight}px;
    `}

    ${({ isAdornment }) =>
    isAdornment &&
    `
        &&& {
            font-weight: 500;
            font-size: inherit;
            line-height: inherit;
            height: unset;
            padding: 0;
            margin: 0;
            background: none;
            border: none;
            color: ${colors.dutchie.blue};

            &:disabled {
                color: ${colors.dutchie.gray60};
            }
        }
    `}
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
`;

export const DutchieCancelButton = styled(DutchieButton)`
  font-size: 0.875rem;
  font-weight: normal;
  color: ${colors.dutchie.almostBlack};
  text-decoration-line: underline;
  background-color: ${colors.dutchie.primaryWhite};
  border: none;
`;

type ButtonWithIconBaseProps = {
  height?: number;
  label?: string;
  large?: boolean;
  side?: 'left' | 'right';
  width?: number;
};

const DutchieButtonWithIconBase = styled(DutchieButton) <ButtonProps & ButtonWithIconBaseProps>`
  position: relative;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ large }) => (large ? '44px' : '32px')};
  min-width: 32px;
  ${({ side }) => side === 'right' && 'padding-left: 12px;'}

  & > svg {
    margin-right: ${({ label }) => (label ? 7 : 3)}px;
    margin-left: ${({ label }) => (label ? 7 : 3)}px;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

type ButtonWithIconProps = ButtonWithIconBaseProps &
  Omit<ButtonProps, 'height' | 'width'> & {
    className?: string;
    icon: React.FC<SVGProps<SVGSVGElement>>;
  };

export const DutchieButtonWithIcon: FC<ButtonWithIconProps & React.ButtonHTMLAttributes<HTMLElement>> = (props) => {
  const { icon: Icon, side = 'left', label, title, height, width, secondary, className, large, ddActionName, ...otherProps } = props;

  const tag = `${label ?? ''}${label && title && '-'}${title ?? ''}`;
  const automationId = `button_button-with-icon-base${tag ? `_${tag}` : ''}`;

  return (
    <DutchieButtonWithIconBase
      automationId={automationId}
      ddActionName={ddActionName}
      className={className}
      label={label}
      large={large}
      secondary={secondary}
      side={side}
      onClick={props.onClick}
      {...otherProps}
    >
      {side === 'left' && <Icon height={height ?? 26} width={width ?? 34} />}
      {label}
      {side === 'right' && <Icon />}
    </DutchieButtonWithIconBase>
  );
};

const BackButton = styled(DutchieButtonWithIcon)`
  border: 1px solid ${colors.dutchie.borderGrey};
  border-radius: 50%;
  width: 40px;
  height: 40px;
  flex: 0 0 40px;
  & svg {
    height: 20px;
  }
`;

export const DutchieBackButton: FC<React.ButtonHTMLAttributes<HTMLElement>> = (props) => (
  <BackButton automationId='back-button' ddActionName='back button' icon={ChevronLeft} secondary onClick={props.onClick} />
);

import { type CartItem, type Preorder } from 'src/models/cart';

export enum CartItemType {
  Preorder,
  Cart,
}

export type CartDisplayItem =
  | (CartItem & { isPreorderItem?: boolean; type: CartItemType.Cart })
  | (Preorder & { isPreorderItem?: boolean; type: CartItemType.Preorder });

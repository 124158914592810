import { useQuery } from '@tanstack/react-query';

import { useAppSelector } from 'src/utils';
import { post } from 'src/utils/helpers/http';
import { useBaseAPIParams } from 'src/utils/hooks/useBaseAPIParams';

import type { DeliveryRouteType } from './types';

export const GetRoutesKey = 'get-my-routes';

const GET_MY_ROUTES_URL = '/curbside/get-my-routes';

export function useRoutes(refetch: boolean) {
  const selectedRegister = useAppSelector((state) => state.user.selectedRegister);
  const baseAPIParams = useBaseAPIParams();

  return useQuery({
    queryKey: [GetRoutesKey, selectedRegister?.id ?? 0],
    queryFn: async () => {
      const res = await post<DeliveryRouteType[]>(GET_MY_ROUTES_URL, {
        ...baseAPIParams,
        RegisterId: selectedRegister?.id ?? 0,
      });
      return res;
    },
    refetchInterval: refetch ? 10000 : undefined,
    enabled: !!selectedRegister,
  });
}

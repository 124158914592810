import { useContext } from 'react';

import { DocumentViewerContext } from 'src/root/providers/document-viewer';

import { type DocumentViewerState } from './document-viewer-state';

type DocOrDocs = string[] | string;

export type DocumentViewerControls = {
  clearDocs: () => void;
  current: DocumentViewerState;
  hide: () => void;
  setDocs: (docs: DocOrDocs) => void;
  show: (docs?: DocOrDocs) => void;
};

const getControllers = ([
  documentViewerState,
  setDocumentViewerState,
]: DocumentViewerContextValue): DocumentViewerControls => {
  const getDocsArray = (docs: DocOrDocs) => (typeof docs === 'object' ? docs : [docs]);
  const updateState = (props: Partial<DocumentViewerState>) => {
    setDocumentViewerState({ ...documentViewerState, ...props });
  };
  return {
    show: (docs?: DocOrDocs) => updateState({ show: true, docs: getDocsArray(docs ?? documentViewerState.docs) }),
    hide: () => updateState({ show: false, docs: [] }),
    setDocs: (docs: DocOrDocs) => updateState({ docs: getDocsArray(docs) }),
    clearDocs: () => updateState({ docs: [] }),
    current: documentViewerState,
  };
};

export const useDocumentViewer = () => {
  const maybeGetAndSetDocumentViewer = useContext(DocumentViewerContext);
  if (!maybeGetAndSetDocumentViewer) {
    throw new Error('Context not available, did you use ProvideDocumentViewer?');
  }
  return getControllers(maybeGetAndSetDocumentViewer);
};

export const withDocumentViewer = (WrappedComponent) => (props) =>
  <WrappedComponent {...props} documentViewer={useDocumentViewer()} />;

import { menuClasses } from '@mui/material/Menu';
import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Palette {
    green: Palette['primary'];
    red: Palette['primary'];
  }

  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface PaletteOptions {
    green?: PaletteOptions['primary'];
    red?: PaletteOptions['primary'];
  }
}
declare module '@mui/material/Chip' {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface ChipPropsColorOverrides {
    green: true;
    red: true;
  }
}

export const defaultTheme = createTheme({
  typography: {
    fontFamily: "Matter, -apple-system, 'Helvetica Neue', Helvetica, Arial, sans-serif",
  },
  components: {
    MuiMenu: {
      styleOverrides: {
        paper: {
          [`.${menuClasses.root}[aria-hidden] &`]: {
            pointerEvents: 'none',
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  transitions: {
    create: () => 'none',
  },
});

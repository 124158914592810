import { useQuery } from '@tanstack/react-query';

import { Path } from 'src/store/slices/user';
import { useAppSelector } from 'src/utils';
import { post } from 'src/utils/helpers/http';
import { useBaseAPIParams } from 'src/utils/hooks/useBaseAPIParams';

import type { GetOrdersForRouteType } from './types';

export const GetOrdersForRouteKey = 'get-orders-for-route';

const GET_ORDERS_FOR_ROUTE_URL = '/curbside/get-orders-for-route';
const REFETCH_INTERVAL = 10000;

export function useOrdersForRoute() {
  const baseAPIParams = useBaseAPIParams();
  const selectedRoute = useAppSelector((state) => state.user.selectedRouteId);
  const selectedPath = useAppSelector((state) => state.user.selectedPath);

  return useQuery({
    queryKey: [GetOrdersForRouteKey, selectedRoute],
    queryFn: async () => {
      const res = await post<GetOrdersForRouteType[]>(GET_ORDERS_FOR_ROUTE_URL, {
        ...baseAPIParams,
        routeId: Number(selectedRoute) || 0,
        DeliveryRouteId: selectedRoute,
      });

      return res;
    },
    enabled: selectedPath === Path.Delivery && !!selectedRoute,
    refetchInterval: REFETCH_INTERVAL,
    // staleTime: REFETCH_INTERVAL || 1000 * 60 * 10,
    // cacheTime: REFETCH_INTERVAL || 1000 * 60 * 10,
  });
}

import { type Dispatch, type SetStateAction, createContext, useContext, useEffect, useMemo, useState } from 'react';

import LogRocket from 'logrocket';

import { type AppReady, MAX_3RD_PARTY_WAIT_TIME } from '..';

type LogRocketContextType = {
  logRocket?: typeof LogRocket;
};

export const LogRocketContext = createContext<LogRocketContextType>({});

export function useLogRocket() {
  const logRocket = useContext(LogRocketContext);
  return logRocket;
}

export async function initLogRocket(appKey: string) {
  // eslint-disable-next-line no-return-await
  return await new Promise<void>((resolve) => {
    try {
      if (import.meta.env.PROD) {
        LogRocket.init(appKey, {
          network: {
            requestSanitizer: (request) => {
              const url = request.url.toLowerCase();

              if (url.includes('/posv3/user/employeelogin')) {
                request.body = undefined;
              }

              if (url.includes('/posv3/user/resetpassword')) {
                request.body = undefined;
              }

              if (url.includes('/curbside/login')) {
                request.body = undefined;
              }

              return request;
            },
          },
        });
        resolve();
      } else if (import.meta.env.DEV) {
        resolve();
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to initialize LogRocket', error);

      // We will still load the page if LogRocket fails to initialize
      resolve();
    }
  });
}

export type LogRocketProviderProps = {
  appKey: string;
  children: React.ReactNode;
  setReady: Dispatch<SetStateAction<AppReady>>;
  ready: AppReady;
};

export function LogRocketProvider({ children, appKey, setReady, ready }: LogRocketProviderProps) {
  const [logRocketReady, setLogRocketReady] = useState(false);
  const [logRocketForceReady, setLogRocketForceReady] = useState(false);
  // TODO: something like this..
  // const { data: user } = useUser();

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLogRocketForceReady(true);
    }, MAX_3RD_PARTY_WAIT_TIME);

    async function init() {
      await initLogRocket(appKey);
      setLogRocketReady(true);
    }
    void init();

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    // Check that the logrocket ready state is not already set so the screen doesn't blip after the timeout
    if (!ready.logrocket && (logRocketReady || logRocketForceReady)) {
      // eslint-disable-next-line no-console
      // TODO: something like this..
      // LogRocket.identify(action.data.user.Id, {
      //   ...action.data.user,
      //   displayName: action.data.user.FullName,
      //   email: action.data.user.UserName,
      // });
      setReady((prev) => ({
        ...prev,
        logrocket: true,
      }));
    }
  }, [ready.logrocket, logRocketReady, logRocketForceReady]);

  const value = useMemo(() => ({ logRocket: LogRocket }), [logRocketReady]);

  if (!logRocketReady && !logRocketForceReady) {
    return null;
  }

  return <LogRocketContext.Provider value={value}>{children}</LogRocketContext.Provider>;
}

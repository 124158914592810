import { useQuery } from '@tanstack/react-query';

import { useAppSelector } from 'src/utils';
import { post } from 'src/utils/helpers/http';

export const userDetailsKey = 'user-details';

const USER_DETAILS_URL = '/api/curbside/user-details';

export type UserDetailsType = {
  AcctType: string;
  Administrator: string;
  Cultivation: string;
  Dispensary: string;
  ExportReports: string;
  ForcePasswordChange: boolean;
  FullName: string;
  Inspector: string;
  Inventory: string;
  InventoryReadOnly: string;
  IsAssignedLocationActive: boolean;
  locations: LocationType[];
  LocId: number;
  LspId: number;
  ManagerFlag: string;
  Region: string;
  UserId: number;
  userId: number;
  UserIsInactive: boolean;
  UserName: string;
};

export type LocationType = {
  BioTrackIntegration: boolean;
  CompanyFlag: string;
  location_id: number;
  location_name: string;
  LspId?: number | null;
  registersAvailable: boolean;
};

export function useUser() {
  const user = useAppSelector((state) => state.user);

  return useQuery<UserDetailsType | null, unknown, UserDetailsType | null, any>({
    queryKey: [userDetailsKey, user?.id],
    queryFn: async () => {
      const res: UserDetailsType | null = await post(USER_DETAILS_URL, {
        SessionId: user?.session,
        UserId: user?.id,
      });
      return res;
    },
    enabled: !!user?.session,
  });
}

import { createContext, useContext, useMemo } from 'react';

import { useLDClient } from 'launchdarkly-react-client-sdk';

import { LoggingProviders, logger } from 'src/utils/logger';

type LoggerContextType = {
  debug: typeof logger.debug;
  error: typeof logger.error;
  getAllMiddleware: typeof logger.getAllMiddleware;
  info: typeof logger.info;
  setGlobalProperty: typeof logger.setGlobalProperty;
  warn: typeof logger.warn;
};

export const LoggerContext = createContext<LoggerContextType>({});

export function useLogger() {
  const datadog = useContext(LoggerContext);
  return datadog;
}

export function LoggerProvider({ children }: { children: React.ReactNode }) {
  const ldClient = useLDClient();
  const providersToUse: LoggingProviders = ldClient?.variation('pos.register.logging-providers', LoggingProviders.ALL);

  const logTool = useMemo(() => {
    logger.init({ turnOnForDevelopment: import.meta.env.DEV, providersToUse });
    return logger;
  }, [providersToUse]);

  return <LoggerContext.Provider value={logTool}>{children}</LoggerContext.Provider>;
}

import { type BaseCustomEventData } from './custom-event-keys';

import type { AnyAction, Dispatch, Middleware } from '@reduxjs/toolkit';

export enum LoggingLevel {
  DEBUG = 'DEBUG',
  ERROR = 'ERROR',
  INFO = 'INFO',
  WARN = 'WARN',
}

export type LoggingProviderInitProps = {
  turnOnForDevelopment?: boolean;
};

export type UserData = Record<string, boolean | number | string>;

export abstract class LoggingProvider {
  public providerId = 'provider_id';

  // Implement any needed initialization steps for providers
  public init = (_props?: LoggingProviderInitProps) => {};

  // Implement to use a Redux middleware for logging to providers
  public getMiddleware = (): Middleware => (_store: unknown) => (next: Dispatch<AnyAction>) => (action: AnyAction) =>
    // Add optional Redux middleware steps here
    next(action);

  // Implement for providers that use global context properties
  public setGlobalProperty = (_key: string, _value: unknown) => {};

  // Implement the setUser method for providers that use user data
  public setUser = (_userId: string, _userData?: UserData) => {};

  // Implement the track method for providers that use custom event tracking
  public track = <CustomEventData extends BaseCustomEventData>(
    _customEventDescription: string,
    _data?: CustomEventData & { level?: LoggingLevel }
  ) => {};

  // Implement the error method to log errors to providers
  public error = (_e: unknown, _data?: object) => {};
}

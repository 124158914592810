import { useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router';

import { Loader } from '@dutchie-pos/components';

import type { CartParams } from 'src/app/types/params';
import DeleteIcon from 'src/assets/icon-trash.svg?react';
import { CashAmount } from 'src/components/cash-amount';
import type { Preorder } from 'src/models/cart';
import { useRemovePreorderItemFromCart } from 'src/queries/cart/remove-preorder-item-from-cart';
import { GetCart } from 'src/queries/curbside/get-cart';
import { useLocation } from 'src/queries/curbside/location-details';
import { showConfirmRemoveItemDrawer } from 'src/store/actions/drawers-actions';
import { useAppDispatch } from 'src/utils';

import { ScanPackageTag } from './scan-package-tag';
import { BottomSection, BrandName, Container, ItemTitle, Quantity, TopSection, TrashContainer } from './styles';

export type PreorderItemProps = {
  item: Preorder;
};

export const PreorderItem = ({ item }: PreorderItemProps): JSX.Element => {
  // Hooks
  const [deleteIsLoading, setDeleteIsLoading] = useState<boolean>(false);
  const { mutateAsync: removePreorderItemFromCart } = useRemovePreorderItemFromCart();
  const { customerId, shipmentId } = useParams<CartParams>();
  const queryClient = useQueryClient();
  const { data: location } = useLocation();
  const dispatch = useAppDispatch();

  // Computed
  const isRemoveItemsFFEnabled = !!location?.Features.RemoveItemsInMobileCheckout;

  // Handlers
  const handleClickDeleteButton = async () => {
    dispatch(
      showConfirmRemoveItemDrawer({
        onConfirm: async () => {
          setDeleteIsLoading(true);

          await removePreorderItemFromCart({
            productId: item.ProductId,
            customerId: Number(customerId ?? 0),
            shipmentId: Number(shipmentId ?? 0),
          });
          await queryClient.invalidateQueries({ queryKey: [GetCart, shipmentId] });

          setDeleteIsLoading(false);
        },
      }),
    );
  };

  return (
    <Container data-testid='preorder-item'>
      <TopSection>
        {isRemoveItemsFFEnabled && (
          <TrashContainer data-testid='trash-icon' onClick={handleClickDeleteButton} data-dd-action-name={`trash icon for preorder item - ${item.Product}`}>
            {deleteIsLoading && <Loader size='sm' variant='blue' />}
            {!deleteIsLoading && <DeleteIcon />}
          </TrashContainer>
        )}
        <BrandName>{item.BrandName}</BrandName>
        <ItemTitle>{item.Product}</ItemTitle>
        <ScanPackageTag />
      </TopSection>
      <BottomSection>
        <Quantity data-testid='quantity'>
          Qty:
          {item.QtySelected}
        </Quantity>
        <CashAmount value={item.TotalCost / item.QtySelected} />
      </BottomSection>
    </Container>
  );
};

import { createContext, useState } from 'react';

import { SignaturePopup } from './signature-popup';
import { type SignatureState, emptySignatureState } from './signature-state';

export type SignatureContextProps = [SignatureState, (signatureState: SignatureState) => void];
export const signatureContext = createContext<SignatureContextProps | null>(null);

export const ProvideSignature = ({ children }) => {
  const getAndSetSignatureState = useState(emptySignatureState);
  return (
    <signatureContext.Provider value={getAndSetSignatureState}>
      <SignaturePopup />
      {children}
    </signatureContext.Provider>
  );
};

import { useMutation } from '@tanstack/react-query';

import { doPostWithoutParams } from 'src/utils/helpers/http';

const LOGIN_URL = '/api/curbside/login';

export type LoginRequest = {
  password: string;
  username: string;
};

export type LoginResponseRootObject = {
  Data: LoginResponse;
  Message: string;
  Result: boolean;
};

export type LoginResponse = {
  FullName: string;
  Id: number;
  LoginFailureCount: number;
  LoginType: string;
  MaxLoginFailureCount: number;
  MaxRetryMinutes: number;
  OnlineState: string;
  OnlineStatus: string;
  PatientAcct: number;
  RetryMinutes: number;
  SessionGId: string;
  SessionId: string;
  TraceId: string;
  UserName: string;
};

export const loginMutationKey = 'user-login';

export function useLoginMutation() {
  return useMutation({
    mutationFn: async (payload: LoginRequest) => await doPostWithoutParams<LoginResponseRootObject>(LOGIN_URL, payload),
    mutationKey: [loginMutationKey],
  });
}

import { type FC } from 'react';

import styled from 'styled-components';

import { DutchieBackButton, DutchieButton } from 'src/components/dutchie-button';
import { colors } from 'src/css/theme';
import { POPUP_MARGIN } from 'src/utils/constants';

import { SignatureMode } from './signature-popup';

const MaxPortraitAspectRatio = 0.65;

export const isPortraitAspectRatio = (aspectRatio: number) => aspectRatio < MaxPortraitAspectRatio;

const BasePadding = 1.5;
const ToolbarHeight = 4.75;

const remToPx = (rem: number) => rem * 16;

export const getCanvasHeight = (totalAvailableHeight: number) => {
  const totalMargin = 2 * remToPx(POPUP_MARGIN);
  const totalToolbarHeight = 2 * remToPx(ToolbarHeight);
  return totalAvailableHeight - totalMargin - totalToolbarHeight;
};

export const getCanvasWidth = (totalAvailableWidth: number) => {
  const totalMargin = 2 * remToPx(POPUP_MARGIN);
  const totalPopupPadding = 2 * remToPx(BasePadding);
  return totalAvailableWidth - totalMargin - totalPopupPadding;
};

export const Container = styled.div<{ mode: SignatureMode }>`
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: '0.5rem';
  background: ${colors.dutchie.primaryWhite};
  position: relative;

  ${({ mode }) => `
        padding: ${
          mode === SignatureMode.Portrait
            ? `${BasePadding}rem ${ToolbarHeight}rem`
            : `${ToolbarHeight}rem ${BasePadding}rem`
        };
        ${mode !== SignatureMode.Portrait && 'flex-direction: column;'}
        ${
          mode === SignatureMode.LandscapePadded &&
          `
            max-height: 400px;
            max-width: 80%;
            min-width: 400px;
        `
        }
    `}

  .signature-popup-canvas {
    flex-grow: 1;
    width: 100%;
    height: 100%;
  }
`;

export const HeaderContainer = styled.div<{ mode: SignatureMode }>`
  display: flex;
  position: absolute;
  top: 0;

  ${({ mode }) =>
    mode === SignatureMode.Portrait
      ? `
        right: 0;
        width: ${ToolbarHeight}rem;
        height: 100%;
        padding: ${BasePadding}rem 0;
    `
      : `
        left: 0;
        width: 100%;
        height: ${ToolbarHeight}rem;
        padding: 0 ${BasePadding}rem;
    `}
`;

export const Header = styled.div<{ mode: SignatureMode; width?: number }>`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  height: ${ToolbarHeight}rem;
  width: 100%;

  ${({ mode, width }) =>
    mode === SignatureMode.Portrait &&
    `
        ${
          width &&
          `
            width: ${getCanvasWidth(width)}px;
            flex: 0 0 ${getCanvasWidth(width)}px;
        `
        }
        transform-origin: top left;
        transform: rotate(90deg) translate(0, -${ToolbarHeight}rem);
    `}
`;

type ThemedButtonProps = {
  disabled?: boolean;
  onClick?: () => void;
};

export const BackButton: FC<ThemedButtonProps> = ({ ...props }) => <DutchieBackButton {...props} />;

export const Title = styled.div`
  color: black;
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 700;
`;

export const Disclaimer = styled.div`
  color: ${colors.dutchie.grey40};
  font-size: 0.875rem;
  line-height: 1rem;
`;

export const TitleBar = styled.div<{ compact?: boolean }>`
  display: flex;
  flex-grow: 1;

  ${({ compact }) =>
    compact
      ? `flex-direction: column;`
      : `
        align-items: center;
        justify-content: space-between;

        & > ${Disclaimer} {
            max-width: 60%;
            text-align: right;
        }
    `}
`;

export const ButtonContainer = styled.div<{ mode: SignatureMode }>`
  display: flex;
  position: absolute;
  left: 0;

  ${({ mode }) =>
    mode === SignatureMode.Portrait
      ? `
        top: 0;
        width: ${ToolbarHeight}rem;
        height: 100%;
        padding: ${BasePadding}rem 0;
    `
      : `
        bottom: 0;
        width: 100%;
        height: ${ToolbarHeight}rem;
        padding: 0 ${BasePadding}rem;
    `}
`;

export const Buttons = styled.div<{ mode: SignatureMode; width?: number }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${colors.dutchie.borderGrey};
  height: ${ToolbarHeight}rem;
  width: 100%;

  ${({ mode, width }) =>
    mode === SignatureMode.Portrait &&
    `
        ${
          width &&
          `
            width: ${getCanvasWidth(width)}px;
            flex: 0 0 ${getCanvasWidth(width)}px;
        `
        }
        transform-origin: top left;
        transform: rotate(90deg) translate(0, -${ToolbarHeight}rem);
    `}
`;

export const ClearButton = styled(DutchieButton)`
  &&& {
    padding: 0;
    border: none;
    background: none;
    color: ${colors.dutchie.almostBlack};
    text-decoration: underline;
  }
`;

export const SubmitButton: FC<ThemedButtonProps> = ({ children, ...props }) => (
  <DutchieButton {...props}>{children}</DutchieButton>
);

export const PopupContainer = styled.div<{ height?: number }>`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: ${({ height }) => (height ? `${height}px` : '100vh')};
  z-index: 1000;
  pointer-events: none;

  & > div {
    padding: 1rem;
    pointer-events: all;
  }
`;

export const ConfirmationMessage = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${colors.dutchie.almostBlack};
  font-size: 1rem;
  text-align: center;
  padding: 0 1rem;

  p {
    margin: 0;
    padding: 0;
  }
`;

export const SignaturePreview = styled.img`
  width: 100%;
  max-width: 350px;
  margin: 1rem 0;
  border: 1px solid ${colors.dutchie.borderGrey};
  border-radius: 0.5rem;
`;

export const popupWidth = {
  small: '31.25rem',
  medium: '43.5rem',
  large: '80%',
};

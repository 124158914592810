import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';

import { type CartParams } from 'src/app/pages/cart';
import { post } from 'src/utils/helpers/http';
import { useBaseAPIParams } from 'src/utils/hooks/useBaseAPIParams';

import type { Cart } from './types';

export const GetCart = 'get-cart';

const GET_CART_URL = '/v2/cart/get-curbside-cart';

type UseGetCartQueryType = {
  enabled?: boolean;
  FeePaymentMethodIds?: number[];
};

export function useCart(props?: UseGetCartQueryType) {
  const baseAPIParams = useBaseAPIParams();
  const { shipmentId, customerId } = useParams<CartParams>();

  return useQuery({
    queryKey: [GetCart, shipmentId],
    queryFn: async () => {
      const res = await post<Cart>(GET_CART_URL, {
        ...baseAPIParams,

        AcctId: Number(customerId),
        ShipmentId: Number(shipmentId),

        Timestamp: 0,
        FeePaymentMethodIds: props?.FeePaymentMethodIds,
      });
      return res;
    },
    enabled: props?.enabled ?? !!(customerId && shipmentId),
    staleTime: 0,
    gcTime: 0,
  });
}

import { type FC } from 'react';

import ReactDatepicker, { type ReactDatePickerProps } from 'react-datepicker';

import CalendarIcon from 'src/assets/calendar.svg?react';

import 'react-datepicker/src/stylesheets/datepicker.scss';
import { DutchieDatePickerHeader, type Range } from './date-picker-header';
import { CalendarInput, DatePickerBase } from './styled-date-picker';
import { type DatePickerMode, useDateFormat, useNumberFormatPattern } from './utils';

type PermittedReactDatePickerProps = Omit<ReactDatePickerProps, 'dateFormat' | 'showTimeSelect'>;

type DutchieDatePickerProps = PermittedReactDatePickerProps & {
  automationId?: string;
  endAdornment?: JSX.Element;
  hasError?: boolean;
  hideCalendarIcon?: boolean;
  mode?: DatePickerMode;
  placeholder?: string;
  width?: string;
  yearRange?: Range;
};

export const DutchieDatePicker: FC<DutchieDatePickerProps> = ({
  automationId,
  className,
  endAdornment,
  hasError = false,
  hideCalendarIcon = false,
  mode = 'date',
  onChange,
  placeholder,
  selected,
  width = '206px',
  yearRange = { start: 1930, end: 2025 },
  ...restProps
}) => {
  const dateFormat = useDateFormat(mode);
  const numberFormatPattern = useNumberFormatPattern(mode, selected);

  const calendarInput = (
    <CalendarInput
      containerMargin='0'
      data-testid={automationId}
      endAdornment={endAdornment ?? <></>}
      format={numberFormatPattern}
      hasError={hasError}
      mask='_'
      startAdornment={!hideCalendarIcon ? <CalendarIcon /> : <></>}
      type='number'
      width={width}
    />
  );

  return (
    <DatePickerBase>
      <ReactDatepicker
        calendarClassName={className}
        customInput={calendarInput}
        dateFormat={dateFormat}
        placeholderText={placeholder ?? dateFormat}
        popperPlacement='auto'
        renderCustomHeader={(dateHeaderProps) => <DutchieDatePickerHeader yearRange={yearRange} {...dateHeaderProps} />}
        selected={selected}
        showTimeSelect={mode === 'datetime'}
        onChange={onChange}
        {...restProps}
      />
    </DatePickerBase>
  );
};

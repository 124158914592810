import React from 'react';

import i18next from 'i18next';

import { omit } from '@dutchie-pos/utils';

import { Loader } from '../loader';
import { Tooltip } from '../tooltip';

// import { useDarkMode } from "src/app/state/dark-mode";
import { StyledButton, ToolTipContainer } from './button.styles';
import { LinkButton } from './link-button';

import type { LoadingSpinnerSizeProp } from '../loader/loader';
import type { SxProps } from '@mui/material';

export type ButtonProps = {
  automationId?: string;
  ddActionName?: string;
  buttonSize?: 'extra-large' | 'extra-small' | 'large' | 'medium' | 'small';
  className?: string;
  disabled?: boolean;
  endIcon?: React.ReactNode;
  fontSize?: string;
  fullWidth?: boolean;
  id?: string;
  label?: React.ReactNode | string;
  loading?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  spinnerSize?: keyof typeof LoadingSpinnerSizeProp;
  startIcon?: React.ReactNode;
  sx?: SxProps;
  tooltipPlacement?: 'bottom' | 'left' | 'right' | 'top';
  tooltipText?: string;
  type?: 'button' | 'reset' | 'submit';
  variant?:
  | 'danger'
  | 'darkBlue'
  | 'lightBlue'
  | 'link-secondary'
  | 'link'
  | 'outlineBlue'
  | 'primary'
  | 'secondary'
  | 'white'
  | 'yellow';
  visible?: boolean;
};

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref): JSX.Element => {
  const {
    variant = 'primary',
    buttonSize = 'medium',
    disabled,
    onClick,
    startIcon,
    endIcon,
    label,
    sx,
    fontSize = '1rem',
    spinnerSize = '1x',
    loading = false,
    automationId = '',
    ddActionName,
    id = '',
    className,
    tooltipText,
    tooltipPlacement = 'top',
    type = 'button',
    fullWidth = false,
  } = props;
  // const darkMode = useDarkMode();
  const validLabel = typeof label === 'string' ? i18next.t(label) ?? label : label;

  const getValidLoader = () => {
    // the loader must be the white variant when a primary or danger button is disabled in order to be visible
    if (disabled && (variant === 'primary' || variant === 'danger')) {
      return <Loader size={spinnerSize} variant='default' />;
    }

    return <Loader size={spinnerSize} variant='grey' />;
  };

  const validLoader = getValidLoader();

  const buttonLabel = loading ? validLoader : validLabel;

  if (variant === 'link') {
    return (
      <LinkButton
        buttonSize={buttonSize}
        data-testid={automationId}
        data-dd-action-name={ddActionName}
        disabled={disabled}
        endIcon={endIcon}
        fontSize={fontSize}
        id={id}
        label={label}
        startIcon={startIcon}
        sx={sx}
        type={type}
        onClick={onClick}
      />
    );
  }

  return tooltipText ? (
    <Tooltip placement={tooltipPlacement} title={tooltipText}>
      <ToolTipContainer>
        <StyledButton
          $fontSize={fontSize}
          $fullWidth={fullWidth}
          $variant={variant}
          className={className}
          data-testid={automationId}
          disabled={disabled}
          endIcon={endIcon}
          id={id}
          ref={ref}
          startIcon={startIcon}
          sx={sx}
          type={type}
          variant='contained'
          onClick={onClick}
          $buttonSize={buttonSize}
          // $darkMode={darkMode}
          $darkMode={false}
        >
          {buttonLabel}
        </StyledButton>
      </ToolTipContainer>
    </Tooltip>
  ) : (
    <StyledButton
      {...omit(props, ['automationId', 'loading', 'buttonSize'])}
      $fontSize={fontSize}
      $fullWidth={fullWidth}
      $variant={variant}
      className={className}
      data-testid={automationId}
      disabled={disabled}
      endIcon={endIcon}
      id={id}
      ref={ref}
      startIcon={startIcon}
      sx={sx}
      type={type}
      variant='contained'
      onClick={onClick}
      $buttonSize={buttonSize}
      // $darkMode={$darkMode}
      $darkMode={false}
    >
      {buttonLabel}
    </StyledButton>
  );
});

import { PaymentType } from 'src/app/types/payments';
import { useLocation } from 'src/queries/curbside/location-details';

import { useAppSelector } from './useAppSelector';

export function useIsDutchiePay(): boolean {
  const activeCart = useAppSelector((state) => state.mobileCheckout.activeCart);
  const isDutchiePayError = useAppSelector((state) => state.mobileCheckout.isDutchiePayError);

  const { data: location } = useLocation();
  const isAllowDutchiePayDeliveryFFEnabled = location?.Features.AllowDutchiePayDelivery;

  return !!(
    (activeCart?.PreauthInfo?.PreauthAmount ?? 0) > 0 &&
    activeCart?.PreauthInfo?.PaymentType === PaymentType.Dutchiepay &&
    isAllowDutchiePayDeliveryFFEnabled &&
    !isDutchiePayError
  );
}

import { useMemo } from 'react';

import { type AppliedDiscountTemp } from '@dutchie-pos/types';

export const useRolledUpDiscounts = (itemDiscounts: AppliedDiscountTemp[]) =>
  useMemo(() => {
    const rolledUpDiscounts: AppliedDiscountTemp[] = [];
    itemDiscounts.forEach((toAdd) => {
      let match = -1;
      rolledUpDiscounts.forEach((existing, i) => {
        if (existing.DiscountId === toAdd.DiscountId) {
          match = i;
        }
      });

      if (match === -1) {
        rolledUpDiscounts.push({ ...toAdd, Items: [toAdd] });
      } else {
        rolledUpDiscounts[match].DiscountAmt += toAdd.DiscountAmt;
        rolledUpDiscounts[match].Items.push(toAdd);
      }
    });
    return rolledUpDiscounts;
  }, [itemDiscounts]);

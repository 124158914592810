import { type FC, useEffect, useRef, useState } from 'react';

import styled from 'styled-components';

import { LinkButton } from '@dutchie-pos/components';
import { DutchieConfirmationPopup } from 'src/components/popups/confirmation-popup';
import { zIndices } from 'src/css/theme';
import { useActiveCustomer } from 'src/queries/curbside/use-carts';
import { getManifestBytes, useGetManifestBytesQuery } from 'src/queries/v2/manifest/get-manifest-bytes';
import { errorNotification } from 'src/store/actions/notifications-actions';
import { useAppSelector, useAppDispatch } from 'src/utils';
import { POPUP_MARGIN } from 'src/utils/constants';
import { useBaseAPIParams } from 'src/utils/hooks/useBaseAPIParams';

import { useDocumentViewer } from '../document-viewer';

import {
  SignatureCanvas,
  type SignatureCanvasRef,
  type SignatureData,
  checkIsValidSignature,
} from './signature-canvas';
import {
  BackButton,
  ButtonContainer,
  Buttons,
  ClearButton,
  ConfirmationMessage,
  Container,
  Disclaimer,
  Header,
  HeaderContainer,
  PopupContainer,
  SignaturePreview,
  SubmitButton,
  Title,
  TitleBar,
  getCanvasHeight,
  getCanvasWidth,
  isPortraitAspectRatio,
  popupWidth,
} from './styles';
import { useSignature } from './use-signature';

type Dimensions = {
  height?: number;
  width?: number;
};

export enum SignatureMode {
  Landscape = 'landscape',
  LandscapePadded = 'landscape-padded',
  Portrait = 'portrait',
}

const getLandscapeMode = (availableHeight: number) => {
  const MaxMobileDeviceHeight = 500;
  return availableHeight < MaxMobileDeviceHeight ? SignatureMode.Landscape : SignatureMode.LandscapePadded;
};

const Overlay = styled.div<{ height?: number; mode: SignatureMode; show: boolean }>`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: ${({ height }) => (height ? `${height}px` : '100vh')};
  padding: ${POPUP_MARGIN}rem;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(0.5rem);
  z-index: ${zIndices.overlay};

  & .hidden-signature-canvas {
    ${({ mode }) =>
      mode !== SignatureMode.Portrait &&
      `
            display: none;
        `}
    position: fixed;
    top: -9999px;
    left: -9999px;
  }
`;

const rotateSignature = (data?: SignatureData) =>
  data
    ? [
        ...data.pointSet.map(({ points, ...pointGroupProps }) => ({
          points: points.map(({ x, y, ...rest }) => ({
            x: y,
            y: (data.dimensions?.width ?? 0) - x,
            ...rest,
          })),
          ...pointGroupProps,
        })),
      ]
    : [];

/**
 * Parses an image string and removes any included image metadata
 * @param imgData A string with or without metadata prefixed to image data
 * @returns image data string without metadata attached
 */
const parseImg = (imgData: string | undefined) => {
  const metaDataRegEx = new RegExp(/data:.*,/g);
  if (imgData?.match(metaDataRegEx)) {
    const [, imgString] = imgData.split(metaDataRegEx);
    return imgString;
  }
  return imgData ?? '';
};

export const SignaturePopup: FC = () => {
  // Global state
  const { data: activeCustomer } = useActiveCustomer();

  // Local state
  const [mode, setMode] = useState<SignatureMode>(SignatureMode.Portrait);
  const [canvasDimensions, setCanvasDimensions] = useState<Dimensions>({ width: undefined, height: undefined });
  const [signatureData, setSignatureData] = useState<SignatureData>();
  const [isValid, setIsValid] = useState(false);
  const [vh, setVh] = useState<number>(window.innerHeight);
  const [showConfirmSubmission, setShowConfirmSubmission] = useState(false);
  const [savedImageData, setSavedImageData] = useState<string>();

  // Refs
  const hiddenSigRef = useRef<SignatureCanvasRef>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  // Hooks
  const dispatch = useAppDispatch();
  const signature = useSignature();
  const documentViewer = useDocumentViewer();

  // Computed
  const isPortrait = mode === SignatureMode.Portrait;

  // const {
  //   data: manifestData,
  //   isFetching: isFetchingManifestData,
  // } = useGetManifestBytesQuery({
  //   enabled: !!activeCustomer?.ShipmentId,
  //   PosId: activeCustomer?.ShipmentId ?? 0,
  // });
  const baseAPIParams = useBaseAPIParams();

  const showManifest = async () => {
    const manifestData = await getManifestBytes(Number(activeCustomer?.ShipmentId ?? 0), baseAPIParams);
    if (manifestData) {
      documentViewer.show(manifestData);
    } else {
      dispatch(errorNotification('No manifests found'));
    }
  };

  const onSave = () => {
    const imgDataURI = isPortrait ? hiddenSigRef.current?.getData().imgDataURI ?? '' : signatureData?.imgDataURI ?? '';
    signature.submit(parseImg(imgDataURI));
    setSignatureData(undefined);
  };

  useEffect(() => {
    // Update valid state
    const valid = checkIsValidSignature(signatureData?.pointSet, 30);
    setIsValid(valid);

    // Update backup image if valid
    if (signatureData && valid) {
      // This is a bit of a hack, setTimeout is necessary to allow the
      // signature data to re-render and retrieve the updated image from the canvas
      setTimeout(() => {
        const aspectRatio = window.innerWidth / window.innerHeight;
        const imgDataURI = isPortraitAspectRatio(aspectRatio)
          ? hiddenSigRef.current?.getData().imgDataURI ?? ''
          : signatureData.imgDataURI;
        setSavedImageData(imgDataURI);
      }, 1);
    }
  }, [signatureData]);

  useEffect(() => {
    const updateMode = () => {
      const winWidth = window.innerWidth;
      const winHeight = window.innerHeight;
      const aspectRatio = winWidth / winHeight;
      const newMode = isPortraitAspectRatio(aspectRatio) ? SignatureMode.Portrait : getLandscapeMode(winHeight);

      setMode(newMode);
      setVh(winHeight);

      if (newMode === SignatureMode.LandscapePadded) {
        // Leave dimensions undefined and let
        // SignatureCanvas automatically determine dimensions
        setCanvasDimensions({});
      } else {
        const width = newMode === SignatureMode.Portrait ? getCanvasHeight(winWidth) : getCanvasWidth(winWidth);
        const height = newMode === SignatureMode.Portrait ? getCanvasWidth(winHeight) : getCanvasHeight(winHeight);
        setCanvasDimensions({ width, height });
      }

      if (mode !== newMode && savedImageData) {
        setShowConfirmSubmission(true);
      }
    };
    updateMode();

    window.addEventListener('resize', updateMode);
    return () => {
      window.removeEventListener('resize', updateMode);
    };
  }, [mode, savedImageData]);

  const handleAbandonSignature = () => {
    setShowConfirmSubmission(false);
    setSavedImageData(undefined);
    setSignatureData(undefined);
  };

  const handleSaveCachedSignature = () => {
    signature.submit(parseImg(savedImageData));
    setShowConfirmSubmission(false);
    setSavedImageData(undefined);
  };

  return (
    <>
      {/* {isFetchingManifestData && <LoadingScreen />} */}
      <Overlay height={vh} mode={mode} show={signature.current.show}>
        <Container mode={mode}>
          <HeaderContainer mode={mode}>
            <Header mode={mode} width={vh}>
              <BackButton
                onClick={() => {
                  signature.hide();
                  setSignatureData(undefined);
                }}
              />
              <TitleBar compact={mode === SignatureMode.LandscapePadded}>
                <Title>Customer Signature</Title>
                <Disclaimer>I acknowledge my signature will be applied to the delivery manifest.</Disclaimer>
              </TitleBar>
            </Header>
          </HeaderContainer>
          <SignatureCanvas
            className='signature-popup-canvas'
            data={signatureData?.pointSet}
            isVisible={signature.current.show}
            onChange={setSignatureData}
            {...canvasDimensions}
          />
          {isPortrait && (
            <SignatureCanvas
              className='hidden-signature-canvas'
              data={rotateSignature(signatureData)}
              height={canvasDimensions.width}
              ref={hiddenSigRef}
              width={canvasDimensions.height}
            />
          )}
          <ButtonContainer mode={mode}>
            <Buttons mode={mode} width={vh}>
              <ClearButton onClick={() => setSignatureData(undefined)}>Clear</ClearButton>
              <LinkButton label='View Manifest' onClick={showManifest} />
              <SubmitButton disabled={!isValid} onClick={onSave}>
                Save
              </SubmitButton>
            </Buttons>
          </ButtonContainer>
        </Container>
        <PopupContainer height={isPortrait ? undefined : vh} ref={containerRef}>
          <DutchieConfirmationPopup
            cancel={{
              text: 'No',
              onClick: handleAbandonSignature,
            }}
            confirm={{
              text: 'Yes',
              onClick: handleSaveCachedSignature,
            }}
            contentMaxHeight='100%'
            hide={() => setShowConfirmSubmission(false)}
            isVisible={showConfirmSubmission && savedImageData !== undefined}
            portalContainer={containerRef?.current ?? undefined}
            title='Submit Signature'
            width={mode === SignatureMode.LandscapePadded ? popupWidth.small : '100%'}
          >
            <ConfirmationMessage>
              <p>
                <b>Rotating this device clears your signature.</b>
              </p>
              <p>Do you want to submit this signature first?</p>
              <SignaturePreview alt='Unsaved Signature' src={savedImageData} />
            </ConfirmationMessage>
          </DutchieConfirmationPopup>
        </PopupContainer>
      </Overlay>
    </>
  );
};

import { type FC, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import Card from 'src/assets/card.svg?react';
import { DutchieButton } from 'src/components/dutchie-button';
import { DutchieInput } from 'src/components/dutchie-input';
import { colors } from 'src/css/theme';
import { useLocation } from 'src/queries/curbside/location-details';
import { useActiveCustomer } from 'src/queries/curbside/use-carts';
import { useEmailReceipt } from 'src/queries/v2/cart/email_receipt';
import { setActiveCart } from 'src/store/actions/MobileCheckoutActions';
import { successNotification, errorNotification } from 'src/store/actions/notifications-actions';
import { Path } from 'src/store/slices/user';
import { useAppSelector, useAppDispatch } from 'src/utils';
import { cartHasRounding } from 'src/utils/helpers/cart/cart-has-rounding';
import { getFeeNameFromProcessor } from 'src/utils/helpers/payments/get-fee-name-from-processor';
import { useIsRethink } from 'src/utils/hooks/use-is-rethink';

import { ContentLayout } from '../content-layout';
import { ContentTitle } from '../content-title';
import { LoadingScreen } from '../loading';

import { type PaymentFormTotals } from './payment-form';

export const CheckoutSuccess: FC<PaymentFormTotals> = ({
  total,
  totalPaid,
  subtotal,
  tip,
  dutchiePayFees,
  discount,
  changeDue,
  rounding,
  nonCashAdjustmentFees,
  creditNonCashAdjustmentFees,
  feesDonations,
  tax,
}) => {
  // Global state
  const activeCart = useAppSelector((state) => state.mobileCheckout.activeCart);
  const selectedPath = useAppSelector((state) => state.user.selectedPath);
  const selectedRouteId = useAppSelector((state) => state.user.selectedRouteId);

  // Hooks
  const { data: activeCustomer } = useActiveCustomer();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isRethink = useIsRethink();
  const { data: location } = useLocation();

  // Local state
  const [email, setEmail] = useState<string>(activeCustomer?.EmailAddress ?? '');

  const { mutate: emailReceipt, isPending: isEmailingReceipt } = useEmailReceipt({
    Email: email,
    ShipmentId: activeCart?.ShipmentId,
    onSuccess: (resp) => {
      if (resp) {
        void dispatch(successNotification('Receipt Emailed'));
      }
    },
  });

  const isRoundDownToQuarterEnabled = !!location?.Features.RoundDownToQuarter;
  const isRoundDownToDollarEnabled = !!location?.Features.RoundDownToDollar;
  const isRoundPercentDiscountsEnabled = !!location?.Features.RoundPercentDiscounts;
  const isRoundToNearestNickelEnabled = !!location?.Features.RoundToNearestNickel;
  const isRoundToNearestDollarEnabled = !!location?.Features.RoundToNearestDollar;
  const isRoundDownToNearestFiverEnabled = !!location?.Features.RoundDownToNearestFiver;
  const isCurbsideRoundDownToNearestFiverEnabled = !!location?.Features.CurbsideRoundDownToNearestFiver;
  const isUseFeesDonationsEnabled = !!location?.Features.UseFeesDonations;
  const debitProcessor = location?.Integrations?.DebitProcessor;

  const hasRounding = cartHasRounding({
    RoundDownToQuarter: isRoundDownToQuarterEnabled,
    RoundDownToDollar: isRoundDownToDollarEnabled,
    RoundPercentDiscounts: isRoundPercentDiscountsEnabled,
    RoundToNearestNickel: isRoundToNearestNickelEnabled,
    RoundToNearestDollar: isRoundToNearestDollarEnabled,
    RoundDownToNearestFiver: isRoundDownToNearestFiverEnabled,
    CurbsideRoundDownToNearestFiver: isCurbsideRoundDownToNearestFiverEnabled,
  });

  const sendEmail = () => {
    if (
      !email ||
      email.length < 3 ||
      !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)
    ) {
      dispatch(errorNotification('Invalid email'));
      return;
    }
    emailReceipt();
  };

  const displayTotal = total + (tip || 0);
  const displayTotalPaid = totalPaid + (tip || 0);
  const paymentFeeName = getFeeNameFromProcessor(debitProcessor);

  return (
    <ContentLayout>
      {isEmailingReceipt && <LoadingScreen />}
      <Container>
        <ContentTitle noButton>Checkout Complete</ContentTitle>
        <Row>
          <Label>Subtotal</Label>
          <Value data-testid='checkout-success-subtotal'>${subtotal.toFixed(2)}</Value>
        </Row>
        <Row>
          <Label>Discounts</Label>
          <Value data-testid='checkout-success-discounts'>${discount.toFixed(2)}</Value>
        </Row>
        <Row>
          <Label>Tax</Label>
          <Value data-testid='checkout-success-tax'>${tax.toFixed(2)}</Value>
        </Row>
        {isUseFeesDonationsEnabled && (
          <Row>
            <Label>Fees & Donations</Label>
            <Value data-testid='checkout-success-fees&donations'>${feesDonations.toFixed(2)}</Value>
          </Row>
        )}
        {dutchiePayFees > 0 && (
          <Row>
            <Label>Dutchie Pay Fees</Label>
            <Value data-testid='checkout-success-dutchie-pay-fees'>${dutchiePayFees.toFixed(2)}</Value>
          </Row>
        )}
        {nonCashAdjustmentFees > 0 && (
          <Row>
            <Label>{paymentFeeName} Fees</Label>
            <Value data-testid='checkout-success-non-cash-adjustment-fees'>${nonCashAdjustmentFees.toFixed(2)}</Value>
          </Row>
        )}
        {isRethink && creditNonCashAdjustmentFees > 0 && (
          <Row>
            <Label>{paymentFeeName} Fees</Label>
            <Value data-testid='checkout-success-credit-non-cash-adjustment-fees'>
              ${creditNonCashAdjustmentFees.toFixed(2)}
            </Value>
          </Row>
        )}
        {tip !== null && tip !== 0 && (
          <Row>
            <Label>Tip</Label>
            <Value data-testid='checkout-success-tip'>${Math.abs(tip).toFixed(2)}</Value>
          </Row>
        )}
        {hasRounding && (
          <Row>
            <Label>Rounding</Label>
            <Value data-testid='checkout-success-rounding'>${Math.abs(rounding).toFixed(2)}</Value>
          </Row>
        )}
        <Row>
          <Label>Total</Label>
          <Value data-testid='checkout-success-total'>${displayTotal.toFixed(2)}</Value>
        </Row>
        <SectionHeader>Summary</SectionHeader>
        {isRethink ? (
          <>
            <Row>
              <Label>Order Total</Label>
              <Value data-testid='checkout-success-rethink-order-total'>${displayTotal.toFixed(2)}</Value>
            </Row>
            <Row>
              <IconLabel>
                <Card />
                Card
              </IconLabel>
              <Value data-testid='checkout-success-card'>${displayTotal.toFixed(2)}</Value>
            </Row>
          </>
        ) : (
          <>
            <Row>
              <Label>Paid</Label>
              <Value data-testid='checkout-success-paid'>${displayTotalPaid.toFixed(2)}</Value>
            </Row>
            <Row>
              <Label>Change Due</Label>
              <Value data-testid='checkout-success-change-due'>${changeDue.toFixed(2)}</Value>
            </Row>
          </>
        )}
        <EmailSection>
          <DutchieInput
            endAdornment={
              <DutchieButton disabled={email === ''} isAdornment onClick={sendEmail}>
                Email Receipt
              </DutchieButton>
            }
            placeholder='...'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </EmailSection>
        <ActionButton
          onClick={() => {
            dispatch(setActiveCart(null));

            if (selectedPath === Path.Curbside) {
              navigate('/carts');
            } else {
              navigate(`/route/delivery/${selectedRouteId}`);
            }
          }}
          ddActionName='Done (Checkout Complete)'
        >
          Done
        </ActionButton>
      </Container>
    </ContentLayout>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Label = styled.div`
  color: ${colors.dutchie.grey35};
  font-size: 1rem;
  line-height: 1.5rem;
`;

const IconLabel = styled(Label)`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;

const Value = styled(Label)`
  text-align: right;
`;

const SectionHeader = styled.div`
  color: black;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 2rem;
  margin: 1rem 0 0.2rem;
`;

const EmailSection = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: flex-start;
  padding: 1rem 0;
`;

const ActionButton = styled(DutchieButton)`
  height: 5rem;
  font-size: 1.25rem;
  font-weight: 700;
  margin: 2rem 0;
`;

import type React from 'react';
import { useEffect, useRef, useCallback } from 'react';

export type SwipeDirection = 'down' | 'left' | 'right' | 'up';

type TouchEventChanges = {
    curX: number;
    curY: number;
    fingerCount: number;
    startX: number;
    startY: number;
};

const InitialTouch: TouchEventChanges = {
    fingerCount: 0,
    startX: 0,
    startY: 0,
    curX: 0,
    curY: 0,
};

export const useSwipeGestures = <T extends HTMLElement>(
    ref: React.RefObject<T>,
    onSwipe: (direction: SwipeDirection) => void,
    onMultitouch?: () => void,
    onTouchEnd?: () => void
) => {
    const minSwipeLength = 30;
    const touchChanges = useRef<TouchEventChanges>(InitialTouch);

    const updateChanges = (changes: Partial<TouchEventChanges>) => {
        touchChanges.current = { ...touchChanges.current, ...changes };
    };

    const touchCancel = () => {
        touchChanges.current = InitialTouch;
    };

    const touchStart = useCallback((e: TouchEvent) => {
        if (e.touches.length === 1) {
            e.preventDefault();
            updateChanges({
                fingerCount: e.touches.length,
                startX: e.touches[0].pageX,
                startY: e.touches[0].pageY,
            });
        } else {
            onMultitouch?.();
            touchCancel();
        }
    }, [onMultitouch]);

    const touchMove = useCallback((e: TouchEvent) => {
        if (touchChanges.current.fingerCount === 1) {
            e.preventDefault();
            updateChanges({
                curX: e.touches[0].pageX,
                curY: e.touches[0].pageY,
            });
        } else {
            onMultitouch?.();
            touchCancel();
        }
    }, [onMultitouch]);

    const getAngle = () => {
        const diffX = touchChanges.current.startX - touchChanges.current.curX;
        const diffY = touchChanges.current.startY - touchChanges.current.curY;
        const r = Math.atan2(diffY, diffX);
        const angle = Math.round(r * 180 / Math.PI);

        return angle < 0 ? 360 - Math.abs(angle) : angle;
    };

    const getDirection = useCallback(() => {
        const angle = getAngle();
        if (angle <= 45 && angle >= 0) {
            return 'left';
        } if (angle <= 360 && angle >= 315) {
            return 'left';
        } if (angle >= 135 && angle <= 225) {
            return 'right';
        } if (angle > 45 && angle < 135) {
            return 'up';
        } 
            return 'down';
        
    }, []);

    const touchEnd = useCallback((e: TouchEvent) => {
        e.preventDefault();

        const { fingerCount, startX, startY, curX, curY } = touchChanges.current;

        if (fingerCount === 1 && curX !== 0) {
            const swipeLength = Math.round(Math.sqrt((curX - startX)**2 + (curY - startY)**2));
            if (swipeLength >= minSwipeLength) {
                const direction = getDirection();
                onSwipe(direction);
            } else {
                touchCancel();
            }
        } else {
            touchCancel();
        }

        onTouchEnd?.();
    }, [getDirection, onSwipe, onTouchEnd]);

    useEffect(() => {
        if (!ref.current) {
            return;
        }

        touchCancel();
        const element = ref.current;
        element.addEventListener('touchstart', touchStart);
        element.addEventListener('touchmove', touchMove);
        element.addEventListener('touchend', touchEnd);
        element.addEventListener('touchcancel', touchCancel);

        // Remove event listeners on cleanup
        return () => {
            element.removeEventListener('touchstart', touchStart);
            element.removeEventListener('touchmove', touchMove);
            element.removeEventListener('touchend', touchEnd);
            element.removeEventListener('touchcancel', touchCancel);
        };
    }, [ref, touchEnd, touchMove, touchStart]);
};

import styled from 'styled-components';

type Props = {
  cash: number;
  change: number;
  debit: number;
  displayTotal: number;
  paymentMethodFallback: boolean;
  remaining: number;
};

export const CartOrderTotal = ({ displayTotal, paymentMethodFallback, cash, debit, remaining, change }: Props) => (
    <OrderTotalContainer>
      <OrderTotalDescWrapper>
        <OrderTotalLabel>Order Total</OrderTotalLabel>
        <OrderTotalValue data-testid='order-total-order-total'>${displayTotal.toFixed(2)}</OrderTotalValue>
      </OrderTotalDescWrapper>
      {paymentMethodFallback && (
        <>
          {cash > 0 && (
            <TotalDescWrapper>
              <TotalDescLabel>Cash payment</TotalDescLabel>
              <TotalDescLabel data-testid='order-total-cash-payment'>${cash.toFixed(2)}</TotalDescLabel>
            </TotalDescWrapper>
          )}
          {debit > 0 && (
            <TotalDescWrapper>
              <TotalDescLabel>Debit payment</TotalDescLabel>
              <TotalDescLabel data-testid='order-total-debit-payment'>${debit.toFixed(2)}</TotalDescLabel>
            </TotalDescWrapper>
          )}
          {remaining > 0 && (
            <TotalDescWrapper>
              <TotalDescLabel>Remaining</TotalDescLabel>
              <TotalDescLabel color='red' data-testid='order-total-remaining'>${remaining.toFixed(2)}</TotalDescLabel>
            </TotalDescWrapper>
          )}
          {change > 0 && (
            <TotalDescWrapper>
              <TotalDescLabel>Change due</TotalDescLabel>
              <TotalDescLabel color='green' data-testid='order-total-change-due'>${change.toFixed(2)}</TotalDescLabel>
            </TotalDescWrapper>
          )}
        </>
      )}
    </OrderTotalContainer>
  );

const OrderTotalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const OrderTotalLabel = styled.div`
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  text-transform: uppercase;
`;

const OrderTotalValue = styled.div`
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.5rem;
`;

const TotalDescWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const OrderTotalDescWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`;

const TotalDescLabel = styled.div<{ color?: string }>`
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #171a1c;

  ${({ color }) =>
    color &&
    `
    color: ${color === 'red' ? '#BE0517' : '#008363'};
  `}
`;

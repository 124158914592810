import { useMutation } from '@tanstack/react-query';

import { post } from 'src/utils/helpers/http';

const FORGOT_PASSWORD_URL = '/posv3/user/ForgotPassword';

export const useUpdateForgotPasswordMutation = () =>
  useMutation({
    mutationFn: async (payload: { username: string }) => await post(FORGOT_PASSWORD_URL, payload),
    mutationKey: ['forgot-password'],
  });

import { useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router';

import { type CartParams } from 'src/app/types/params';
import { type ScanResponse } from 'src/models/cart';
import { useUser } from 'src/queries/curbside/user-details';
import { errorNotification } from 'src/store/actions/notifications-actions';
import { useAppDispatch, useAppSelector } from 'src/utils';
import { cleanScanString } from 'src/utils/helpers/clean-scan-string';
import { post } from 'src/utils/helpers/http';
import { useBaseAPIParams } from 'src/utils/hooks/useBaseAPIParams';

const SCAN_PRODUCT_URL = '/v2/product/scan_product';

export function useScannedItem() {
  const registerId = useAppSelector((state) => state.user.selectedRegister?.id);
  const baseAPIParams = useBaseAPIParams();
  const dispatch = useAppDispatch();
  const { data: user } = useUser();
  const { customerId } = useParams<CartParams>();
  const lspId = user?.LspId;

  return useMutation({
    mutationKey: ['scanned-item'],
    mutationFn: async (scannedString: string) => {
      const serialCleaned = cleanScanString(scannedString, lspId);
      const resp = await post<ScanResponse[]>(SCAN_PRODUCT_URL, {
        ...baseAPIParams,
        SerialNumber: serialCleaned,
        CustomerId: Number(customerId ?? 0),
        Register: registerId ?? 0,
      });

      return resp;
    },
    onError: (e) => {
      dispatch(errorNotification(`Error scanning item: ${e}`));
    },
  });
}

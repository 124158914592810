import { type QueryKey, useMutation } from '@tanstack/react-query';

import { errorNotification } from 'src/store/actions/notifications-actions';
import { useAppDispatch } from 'src/utils';
import { type BaseAPIResponse, post } from 'src/utils/helpers/http';
import { useBaseAPIParams } from 'src/utils/hooks/useBaseAPIParams';

export const FinishRouteKey: QueryKey = ['finish-route'];

const FINISH_ROUTE_URL = '/curbside/finish-route';

type useFinishRouteQueryType = {
  onSuccess: (resp: BaseAPIResponse<any>) => void;
  routeId: number;
};

export function useFinishRoute({ onSuccess, routeId }: useFinishRouteQueryType) {
  const baseAPIParams = useBaseAPIParams();
  const dispatch = useAppDispatch();

  return useMutation({
    mutationKey: FinishRouteKey,
    mutationFn: async () => {
      const res = await post(FINISH_ROUTE_URL, {
        ...baseAPIParams,
        DeliveryRouteId: routeId,
      });
      return res;
    },
    onSuccess,
    onError: (resp: string) => dispatch(errorNotification(resp ?? 'Failed to start route')),
  });
}

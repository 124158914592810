import { useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router';
import styled from 'styled-components';

import { Loader } from '@dutchie-pos/components';
import { useApplicableItemDiscounts, useRolledUpDiscounts, useTotalDiscountAmount } from '@dutchie-pos/utils';

import type { CartParams } from 'src/app/types/params';
import DeleteIcon from 'src/assets/icon-trash.svg?react';
import { CashAmount } from 'src/components/cash-amount';
import { colors } from 'src/css/theme';
import type { CartItem as CartItemType } from 'src/models/cart';
import { useRemoveItemFromCart } from 'src/queries/cart/remove-item-from-cart';
import { GetCart, useCart } from 'src/queries/curbside/get-cart';
import { useLocation } from 'src/queries/curbside/location-details';
import { useAppSelector } from 'src/utils';

import { AssignedPackagePill } from './assigned-package-pill';
import { Container, TopSection, TrashContainer, BrandName, ItemTitle, Quantity, BottomSection } from './styles';

export type CartItemProps = {
  item: CartItemType;
};

export const CartItem = ({ item }: CartItemProps): JSX.Element => {
  const [deleteIsLoading, setDeleteIsLoading] = useState<boolean>(false);
  const selectedRegisterId = useAppSelector((state) => state.user.selectedRegister?.id);

  const { mutateAsync: removeItemFromCart } = useRemoveItemFromCart();
  const { data: location } = useLocation();
  const { data: cart } = useCart();
  const { customerId, shipmentId } = useParams<CartParams>();
  const queryClient = useQueryClient();

  const itemDiscounts = useApplicableItemDiscounts(item, cart?.Discounts);
  const rolledUpDiscounts = useRolledUpDiscounts(itemDiscounts);
  const totalDiscountAmount = useTotalDiscountAmount(rolledUpDiscounts);

  const isPackageItemIdsFeatureActive = location?.Features?.PackageItemIds;
  const isLoyaltyAsDiscountEnabled = location?.Features?.LoyaltyAsDiscount;
  const isRemoveItemsFFEnabled = !!location?.Features?.RemoveItemsInMobileCheckout;
  const packageId = String(
    isPackageItemIdsFeatureActive && item.PackageItemSerialNumber ? item.PackageItemSerialNumber : item.SerialNo,
  );

  const handleClickDeleteButton = async () => {
    setDeleteIsLoading(true);
    await removeItemFromCart({
      count: 1,
      guestId: Number(customerId),
      inventoryId: item.InventoryId,
      isLoyaltyAsDiscountEnabled: isLoyaltyAsDiscountEnabled ?? false,
      productId: item.ProductId,
      registerId: selectedRegisterId ?? 0,
      serialNo: item.SerialNo,
      shipmentId: Number(shipmentId),
    });
    await queryClient.invalidateQueries({ queryKey: [GetCart, shipmentId] });
    setDeleteIsLoading(false);
  };

  const totalCost = item.TotalCost;
  const discountedCost = totalCost - totalDiscountAmount;

  return (
    <Container data-testid='cart-item'>
      <TopSection>
        {isRemoveItemsFFEnabled && (
          <TrashContainer data-testid='trash-icon' onClick={handleClickDeleteButton} data-dd-action-name={`trash icon for cart item - ${item.Product}, ${packageId}`}>
            {deleteIsLoading && <Loader size='sm' variant='blue' />}
            {!deleteIsLoading && <DeleteIcon />}
          </TrashContainer>
        )}
        <BrandName>{item.BrandName}</BrandName>
        <ItemTitle>{item.Product}</ItemTitle>
        <AssignedPackagePill packageId={packageId} />
      </TopSection>
      <BottomSection>
        <Quantity data-testid='quantity'>
          Qty:
          {item.QtySelected}
        </Quantity>
        <ItemCostWrapper>
          {totalDiscountAmount ? (
            <>
              <ItemsCostStrike>
                <CashAmount value={totalCost} />
              </ItemsCostStrike>
              <ItemsCost>
                <CashAmount value={discountedCost} />
              </ItemsCost>
            </>
          ) : (
            <ItemsCost>
              <CashAmount value={totalCost} />
            </ItemsCost>
          )}
        </ItemCostWrapper>
      </BottomSection>
    </Container>
  );
};

const ItemCostWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  gap: 8px;
  min-width: 67px;
  text-align: right;
`;

const ItemsCost = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${colors.dutchie.almostBlack};
`;

const ItemsCostStrike = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${colors.dutchie.grey50};
  text-decoration: line-through;
`;

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Button as MuiButton } from '@mui/material';

export const StyledLinkButton = styled(MuiButton, {
  shouldForwardProp: (prop) => !prop.startsWith('$'),
})<{ $variant: string }>`
  text-transform: none !important;
  font: var(--font-small-13pt-semibold);
  ${({ $variant }) => css`
    ${$variant === 'primary' && primary}
    ${$variant === 'secondary' && secondary}
    ${$variant === 'white' && white}
    ${$variant === 'danger' && danger}
  `}

  &:hover {
    background: none;
  }
  &:focus {
    outline: none;
  }

  :disabled {
    color: var(--color-gray-50);
  }
`;

const primary = css`
  color: var(--color-brand-secondary-sapphire);
  background-color: transparent;
`;

const secondary = css`
  color: var(--color-grayscale-black);
  background-color: transparent;
  text-decoration: underline;
`;

const white = css`
  color: var(--color-grayscale-white);
  background-color: transparent;
  text-decoration: underline;
`;

const danger = css`
  color: var(--color-red-60);
  background-color: transparent;
  text-decoration: underline;
`;

import { useEffect, useRef, useState } from 'react';

import { type Configuration, BarcodeReader as Strich, StrichSDK } from '@pixelverse/strichjs-sdk';

import { useEnableAllBarcodeSymbologies } from 'src/utils/hooks/launch-darkly/use-enable-all-barcode-symbologies';
import { useDuplicateScanInterval } from 'src/utils/hooks/launch-darkly/use-duplicate-scan-interval';
import { logger } from 'src/utils/logger';

/**
 * See https://github.com/pixelverse-llc/strich-next-sample/blob/main/src/pages/scanner.tsx
 */

enum InitializationStatus {
  ERROR = 'error',
  INITIALIZED = 'initialized',
  INITIALIZING = 'initializing',
}

const getConfig = (
  hostElem: HTMLElement | null,
  areAllBarcodeSymbologiesEnabled: boolean,
  duplicateScanInterval: number,
): Configuration => ({
  // the CSS selector identifying the host element
  selector: hostElem ?? '',
  engine: {
    // restrict to the required symbologies
    symbologies: areAllBarcodeSymbologiesEnabled ? [] : ['code128'],
    // filter duplicate results
    duplicateInterval: duplicateScanInterval,
  },
  locator: {
    regionOfInterest: {
      // restrict active area to a horizontal bar in the center
      left: 0.05,
      right: 0.05,
      top: 0.35,
      bottom: 0.35,
    },
  },
  overlay: {
    // Overrides Strich logo with nothing. Can't pass empty string, so passing a small transparent gif
    customLogoSrc: 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==',
  },
  feedback: {
    // get audio and vibration feedback for scans
    audio: true,
    vibration: true,
  },
});

const getQrConfig = (hostElem: HTMLElement | null, duplicateScanInterval: number): Configuration => ({
  // the CSS selector identifying the host element
  selector: hostElem ?? '',
  engine: {
    // restrict to the required symbologies
    symbologies: ['qr'],
    // filter duplicate results
    duplicateInterval: duplicateScanInterval,
  },
  locator: {
    chromaReject: true,
    regionOfInterest: {
      // restrict active area to a horizontal bar in the center
      left: 0.15,
      right: 0.15,
      top: 0.15,
      bottom: 0.15,
    },
  },
  overlay: {
    // Overrides Strich logo with nothing. Can't pass empty string, so passing a small transparent gif
    customLogoSrc: 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==',
  },
  feedback: {
    // get audio and vibration feedback for scans
    audio: true,
    vibration: true,
  },
});

type UseBarcodeReaderParams = {
  onScan: (scannedString: string) => Promise<void>;
  qrCode: boolean;
};

export const useBarcodeReader = ({ onScan, qrCode }: UseBarcodeReaderParams) => {
  const [strichInitializationStatus, setStrichInitializationStatus] = useState<InitializationStatus | undefined>();

  const barcodeReaderRef = useRef<Strich | null>(null);
  const hostElemRef = useRef<HTMLDivElement | null>(null);

  const areAllBarcodeSymbologiesEnabled = useEnableAllBarcodeSymbologies();
  const duplicateScanInterval = useDuplicateScanInterval();

  useEffect(() => {
    const initializeSDK = async () => {
      if (StrichSDK.isInitialized()) {
        setStrichInitializationStatus(InitializationStatus.INITIALIZED);
      } else {
        try {
          await StrichSDK.initialize(
            'eyJhbGciOiJFZERTQSIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyMzU3YThkYS1iZDg5LTQzYWQtYjg0Mi0wNzY5NDFkYjliNTciLCJpc3MiOiJzdHJpY2guaW8iLCJhdWQiOlsiaHR0cHM6Ly9saXZlLnBvcy5kdXRjaGllLmNvbSJdLCJpYXQiOjE3MTI5MzY3MjAsIm5iZiI6MTcxMjkzNjcyMCwiY2FwYWJpbGl0aWVzIjp7Im9mZmxpbmUiOnRydWUsImFuYWx5dGljc09wdE91dCI6dHJ1ZSwiY3VzdG9tT3ZlcmxheUxvZ28iOnRydWV9LCJ2ZXJzaW9uIjoxLCJleHAiOjE3NDk5NDU1OTl9.AMVqwISmriaAIT2RYmRVeU6noI39C5CgI081R8VdNMvnM2uGKhpz46U4pycF4e95OM__RD-TXXVkW4PBgHOjDQ',
          );
          setStrichInitializationStatus(InitializationStatus.INITIALIZED);
        } catch (e) {
          logger.error(e, { message: 'failed to initialize Strich' });
          setStrichInitializationStatus(InitializationStatus.ERROR);
        }
      }
    };

    if (strichInitializationStatus === undefined) {
      setStrichInitializationStatus(InitializationStatus.INITIALIZING);
      void initializeSDK();
    }
  }, [strichInitializationStatus]);

  useEffect(() => {
    if (strichInitializationStatus === InitializationStatus.INITIALIZED && barcodeReaderRef.current === null) {
      const barcodeReaderInitialization = async () => {
        try {
          const config = qrCode
            ? getQrConfig(hostElemRef.current, duplicateScanInterval)
            : getConfig(hostElemRef.current, areAllBarcodeSymbologiesEnabled, duplicateScanInterval);
          const barcodeReader = new Strich(config);
          barcodeReaderRef.current = barcodeReader;
          await barcodeReader.initialize();

          barcodeReader.detected = (detections) => {
            void onScan(detections[0].data);
          };

          await barcodeReader.start();
        } catch (e) {
          logger.error(e, { message: 'failed to initialize barcode reader (Strich)' });
          // eslint-disable-next-line no-console
          console.error(e);
        }
      };

      void barcodeReaderInitialization();

      return () => {
        if (barcodeReaderRef.current !== null) {
          void barcodeReaderRef.current.destroy();
          barcodeReaderRef.current = null;
        }
      };
    }
  }, [strichInitializationStatus]);

  return {
    hostElemRef,
  };
};

export type CheckoutRequest = {
  CCPaid: number | null;
  Data?: PreauthInfo;
  DebitPaid: number;
  DiscountAmt: number;
  DueCustomer: number;
  GiftPaid: number;
  LocId: number | null;
  LoyaltyPoints: number;
  LspId: number | null;
  OrderId: number;
  Paidamt: number;
  PaymentMethods?: PaymentMethod[] | null;
  PreAuth: number;
  PrepaymentType: string;
  ReactVersion?: string;
  SessionId: string | null;
  ShipmentId: number;
  SubTotal: number;
  TaxAmt: number;
  TerminalId: number;
  TipPaid: number;
  TotalCharges: number;
  TotalItems: number;
  TransType: string;
  UserId?: number | null;
};

export type HypurCheckoutRequest = {
  CheckInISN: string;
  IndividualISN: string;
  Pin?: string;
};

export type LinxCheckoutRequest = {
  CardNumber: string;
  CustomerState: string;
  DriversLicenseNumber: string;
};

export type CanPayCheckoutRequest = {
  Token?: string;
};

export type PreauthInfo = {
  Currency: string;
  FinalAmount?: number;
  OrderId: number;
  PaymentType: string;
  PreauthAmount: number;
  PreauthDate?: Date;
  PreauthId: string;
  ShipmentId: number;
};

export type PaymentMethod = {
  amount: number;
  authCode?: string;
  data?: CanPayCheckoutRequest | HypurCheckoutRequest | LinxCheckoutRequest | PreauthInfo;
  finalized?: boolean;
  id: string;
  name: string;
  tipAmount?: number;
  type: PaymentType;
};

export enum PaymentType {
  Cash = 'Cash',
  Check = 'Check',
  Credit = 'Credit',
  Debit = 'Debit',
  Digital = 'Digital',
  // Ecomm-based DutchiePay
  Dutchie = 'Dutchie',
  // In-store based DutchiePay
  DutchiePay = 'DutchiePay',
  Gift = 'Gift',
  MMAP = 'MMAP',
  Prepayment = 'Prepayment',
  RethinkEcomm = 'RethinkEcomm',
  RethinkSms = 'RethinkSms',
  SplitPayment = 'SplitPayment',
}

export enum PrepaymentType {
  Dutchiepay = 'dutchiepay',
  Rethink = 'rethink',
}

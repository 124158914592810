import React from 'react';
import styled from 'styled-components';

type NoResultsOverlayProps = {
  noResultsHeading?: string;
  noResultsSubheading?: string;
};

export function NoResultsOverlay({
  noResultsHeading = 'No data available',
  noResultsSubheading = `We were unable to find data that matches what you're looking for. Please try again.`,
}: NoResultsOverlayProps) {
  return (
    <StyledGridOverlay>
      <StyledGridContainer>
        <Heading>{noResultsHeading}</Heading>
        <Subheading>{noResultsSubheading}</Subheading>
      </StyledGridContainer>
    </StyledGridOverlay>
  );
}

export const StyledGridOverlay = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 7 !important;
  background: white;
`;

export const StyledGridContainer = styled.div`
  height: 166px;
  width: 100%;
  background-color: var(--color-greyscale-grey-95);
  color: var(--color-greyscale-grey-40);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--sizes-50);
  text-align: center;
`;

const Heading = styled.p`
  margin-bottom: 8px;
  color: var(--color-greyscale-grey-40);
  font: var(--font-headings-heading-3);
`;

const Subheading = styled.p`
  color: var(--color-greyscale-grey-40);
  font: var(--font-regular-14pt-normal);
`;

export const colors = {
  accent1: '#124D66',
  accent4: '#40A4BA',
  accent5: '#34D2AF',
  accent6: '#FFB300',
  background: '#FFFFFF',
  black: '#363648',
  borderGrey: '#C4D2D9',
  grey: '#797A7A',
  lightGreen: '#34D2AF',
  lightGrey: '#F2F2F2',
  primary: '#1A3952',
  primaryFaded: '#8697A4',
  primaryHover: '#142C3F',
  realBlack: '#000000',
  red: '#FF0000',
  red10: '#FAE8EA',
  success: '#00d592',
  white: '#FFFFFF',
  blue: '#1DA1F2',
  blue2: '#0078AF',
  yellow: '#FFB300',
  gold: '#EEB868',
  dutchie: {
    backgroundGrey: '#E3E6E7',
    borderGrey: '#A0A8AB',
    toggleBorder: '#CBCDCC',
    darkGrey: '#464F53',
    mediumGrey: '#6A6A6A',
    lightGrey: '#F2F3F4',
    shadowGrey: '#E5E5E5',
    blueGrey: '#EAEDEF',
    grey: '#788287',
    grey10: '#F5F6F7',
    grey20: '#E3E7E9',
    grey40: '#6A6A6A',
    grey30: '#464F53',
    grey35: '#4F5D68',
    grey50: '#788287',
    grey60: '#A0A7AB',
    grey65: '#A0A8AB',
    grey70: '#ADB4B7',
    grey80: '#C9CDCF',
    grey90: '#E3E6E7',
    gray20: '#E3E7E9',
    gray30: '#D2D7DA',
    gray40: '#C1C7CA',
    gray50: '#B0B7BB',
    gray60: '#A0A7AB',
    gray70: '#828A8F',
    gray80: '#646D72',
    gray90: '#485055',
    gray100: '#2C3236',
    blue: '#0075E0',
    blueAccent: '#5348CC',
    blue10: '#EDF1FA',
    blue50: '#3387E1',
    blue62: '#70A0CA',
    blue70: '#0555AE',
    blue80: '#083A7E',
    blue90: '#082352',
    blue100: '#051027',
    bluePill: '#DCECFF',
    bluePillText: '#284C6F',
    cerulean: '#0067B2',
    almostBlack: '#171A1C',
    black: '#121516',
    primaryWhite: '#FFFFFF',
    red: '#EC0017',
    red10: '#FAE8EA',
    red20: '#F2B3B9',
    red60: '#BE0517',
    red70: '#920715',
    flora: '#BE0517',
    terra: '#F8A867',
    opal10: '#ECF0F3',
    opal12: '#0B2033',
    opal18: '#102F4D',
    opal20: '#C8D2DB',
    opal40: '#8197AC',
    opal50: '#5B7791',
    opal60: '#395C7E',
    opal70: '#153F66',
    opal80: '#10304D',
    opal90: '#0B2034',
    purple: '#446583',
    moss: '#297C2C',
    green: '#00A47C',
    green10: '#F1F8F7',
    green20: '#C4E5DD',
    green50: '#2FB594',
    green60: '#7AB680',
    green70: '#008363',
    green80: '#00624A',
    green90: '#004232',
    green100: '#002101',
    gunmetal: '#2E3538',
    white70p: 'rgba(255, 255, 255, 0.7)',
    rose: '#FFE5E8',
    deepRed: '#780511',
    paleYellow: '#FFF6C7',
    crispyUmber: '#615100',
    paleBlue: '#EDF1FA',
    deepBlue: '#083A7E',
    peach: '#FBDFCE',
    brown: '#8A4518',
    starGold: '#736933',
  },
  sessionTimeout: {
    stayButton: '#3273D9',
    logoutButton: '#E4E6E7',
    logoutButtonText: '#212121',
  },
};

export const zIndices = {
  header: 4,
  notifications: 50,
  loading: 400,
  layer: 1,
  overlay: 100,
  bottomDrawer: 25,
  popup: 30,
};

export const shadows = {
  level3: '0 0 2px 1px rgba(0, 0, 0, 0.03), 0 6px 10px 2px rgba(0, 0, 0, 0.08)',
  sideBar: '0 0 8px 2px rgba(0, 0, 0, 0.03), 0 16px 24px 0 rgba(0, 0, 0, 0.1)',
  inlineContent: '0 2px 2px rgba(0, 0, 0, 0.06)',
};

export const eloTabletHeight = 720;

export const eloTabletBreakpoint = `@media (max-width: 1280px) and (max-height: ${eloTabletHeight}px)`;

// Elo with iPad Pro 11in set as max width as a backup
export const eloOriPadBreakpoint = `${eloTabletBreakpoint}, (max-width: 1195px)`;

import React, { type FC } from 'react';

import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { DutchieBackButton } from 'src/components/dutchie-button';
import { LinkButton } from 'src/components/link-button';

type ContentTitleProps = {
  action?: {
    label: string;
    onClick: () => void;
  };
  children: React.ReactNode;
  className?: string;
  noButton?: boolean;
  onGoBack?: () => void;
};

export const ContentTitle: FC<ContentTitleProps> = ({ className, noButton, onGoBack, action, children }) => {
  const navigate = useNavigate();
  const showBackButton = !noButton;

  return (
    <Header className={className}>
      {showBackButton && <DutchieBackButton onClick={onGoBack ?? (() => navigate(-1))} />}
      <RightContainer>
        <Title>{children}</Title>
        {action && <LinkButton label={action.label} onClick={action.onClick} />}
      </RightContainer>
    </Header>
  );
};

const Header = styled.div`
  display: flex;
  gap: 0.625rem;
  align-items: center;
  padding: 0.75rem 0 1.5rem;
  position: sticky;
  top: 5rem;
  background: white;
  height: 76px;
`;

const Title = styled.div`
  color: black;
  font-size: 1.25rem;
  line-height: 2rem;
  font-weight: 700;
`;

const RightContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

import { useQuery } from '@tanstack/react-query';

import { post } from 'src/utils/helpers/http';
import { useBaseAPIParams } from 'src/utils/hooks/useBaseAPIParams';

export const GetManifestBytesKey = 'get-manifest-bytes';
export const GetMultipleManifestBytesKey = 'get-manifest-bytes-multi';

const GET_MANIFEST_BYTES_URL = '/v2/manifest/get-manifest-bytes';

type GetManifestBytesQueryParamsType = {
  enabled: boolean;
  PosId: number;
};

type GetMultipleManifestBytesQueryParamsType = {
  enabled: boolean;
  ids: number[];
};

export function getManifestBytes(PosId: number, baseAPIParams: any) {
  return post<string[]>(GET_MANIFEST_BYTES_URL, { ...baseAPIParams, PosId });
}

export function getManifestBytesMulti(ids: number[], baseAPIParams: any) {
  const promises = ids.map((id) =>
    post<string>(GET_MANIFEST_BYTES_URL, {
      ...baseAPIParams,
      PosId: Number(id),
    })
  );
  return Promise.all(promises).then((results) => results);
}

export function useGetManifestBytesQuery({ PosId, enabled }: GetManifestBytesQueryParamsType) {
  const baseAPIParams = useBaseAPIParams();
  return useQuery({
    queryKey: [GetManifestBytesKey, PosId],
    queryFn: async () => {
      const resp = await post<string>(GET_MANIFEST_BYTES_URL, { ...baseAPIParams, PosId });
      return resp;
    },
    enabled,
  });
}

// TODO: we should not be calling the API in a loop like this?  What is the use case here?
export function useMultipleManifestBytes({ ids, enabled }: GetMultipleManifestBytesQueryParamsType) {
  const baseAPIParams = useBaseAPIParams();

  return useQuery({
    queryKey: [GetMultipleManifestBytesKey, ...ids],
    queryFn: async () => {
      const promises = ids.map((id) =>
        post<string>(GET_MANIFEST_BYTES_URL, {
          ...baseAPIParams,
          PosId: Number(id),
        })
      );
      return await Promise.all(promises).then((results) => results);
    },
    enabled,
  });
}

import { useMutation } from '@tanstack/react-query';

import { post } from 'src/utils/helpers/http';
import { useBaseAPIParams } from 'src/utils/hooks/useBaseAPIParams';

const READY_FOR_DELIVERY_URL = 'v2/cart/ready-for-delivery';

export type ReadyMutation = {
  onError: (ex: any) => void;
  onSuccess: (data: any) => void;
};

export type ReadyRequest = {
  ShipmentID: number;
};

export function useReadyForDeliveryMutation({ onSuccess, onError }: ReadyMutation) {
  const baseAPIParams = useBaseAPIParams();
  return useMutation({
    mutationFn: async (req: ReadyRequest) => {
      const resp: any = await post(
        READY_FOR_DELIVERY_URL,
        {
          ...baseAPIParams,
          ...req,
        },
        { ignoreFormat: true },
      );

      if (!resp.Result) {
        throw resp.Message;
      }

      return resp;
    },
    mutationKey: ['ready-for-delivery'],
    onError,
    onSuccess,
  });
}

import { useQuery } from '@tanstack/react-query';

import { useAppSelector } from 'src/utils';
import { post } from 'src/utils/helpers/http';
import { useBaseAPIParams } from 'src/utils/hooks/useBaseAPIParams';

import type { CarDetails } from './types';

export const GetCarDetailsKey = 'get-car-details';

const GET_CAR_DETAILS_URL = '/curbside/get-car-details';

export function useCarDetails() {
  const selectedRegisterId = useAppSelector((state) => state.user.selectedRegister?.id);
  const baseAPIParams = useBaseAPIParams();

  return useQuery({
    queryKey: [GetCarDetailsKey, selectedRegisterId],
    queryFn: async () => {
      const res = await post<CarDetails>(GET_CAR_DETAILS_URL, {
        ...baseAPIParams,
        RegisterId: selectedRegisterId,
      });
      return res;
    },
  });
}

/**
 * TODO: copied from @dutchie/error-messaging jfrog package. Need to actually set up jfrog and delete this file.
 */
import { SOURCES } from './constants';
import { getMessage, type Message, type MessageMap } from './get-message';

const supportLink = ` Please have customer contact [Dutchie Support](https://help.dutchie.com/hc/en-us/articles/360056774533-Contact-Dutchie-Support)`;

const velocityTransactionLimitsLink = `You can learn more about why here: [Dutchie Pay Transaction Limits](https://support.dutchie.com/hc/en-us/articles/13230474205843-What-limits-apply-to-consumer-spending-using-Dutchie-Pay)`;

const velocitySpendLimitsLink = `You can learn more about why here: [Dutchie Pay Spend Limits](https://support.dutchie.com/hc/en-us/articles/13230474205843-What-limits-apply-to-consumer-spending-using-Dutchie-Pay)`;

export const getDefaultDutchiePayError = (name = `Dutchie Pay`) => `${name} is unavailable. Please use a different method of payment.`;

// SOURCE: https://dutchie.atlassian.net/wiki/spaces/EN/pages/20067156195/Dutchie+Pay+Reason+Codes
export const dutchiePayErrors = (name = `Dutchie Pay`): MessageMap => new Map([
    [
      'B100', // amount too high payment abuse
      {
        source: SOURCES.SIFT,
        ecomm: `Customer has exceeded spending limit. ${supportLink}`,
        pos: `Customer has exceeded spending limit. ${supportLink}`,
      },
    ],
    [
      'B104', // Insufficient Funds
      {
        source: SOURCES.PLAID,
        ecomm:
          'Your bank account has insufficient funds to complete this transaction. Please either use a different method of payment or add funds to your bank account and try again',
        pos: 'Your bank account has insufficient funds to complete this transaction. Please either use a different method of payment or add funds to your bank account and try again',
      },
    ],
    [
      'B105', // aggregator relink required
      {
        source: SOURCES.AGGREGATOR,
        ecomm: 'Your bank account is unlinked. Please reconnect with your financial institution.',
        pos: 'Your bank account is unlinked. Please reconnect with your financial institution.',
      },
    ],
    [
      'B106', // Balance Pending
      {
        source: SOURCES.DUTCHIE_PAY,
        ecomm: `Plaid unable to check bank account balance. Please try again.`,
        pos: `Plaid unable to check bank account balance. Please try again.`,
      },
    ],
    [
      'E001', // unsupported financial institution
      {
        source: SOURCES.DUTCHIE_PAY,
        ecomm: `Your financial institution is not supported by ${name} at this time. Please use a different method of payment.`,
        pos: `Your financial institution is not supported by ${name} at this time. Please use a different method of payment.`,
      },
    ],
    [
      'E002', // unsupported account type
      {
        source: SOURCES.DUTCHIE_PAY,
        ecomm: `Only checking or savings accounts can be used with ${name}. Please update your account information and try again.`,
        pos: `Only checking or savings accounts can be used with ${name}. Please update your account information and try again.`,
      },
    ],
    [
      'E003', // unsupported currency
      {
        source: SOURCES.DUTCHIE_PAY,
        ecomm: 'The currency of this account is unsupported. Please connect to an account that uses either USD or CAD for a currency.',
        pos: 'The currency of this account is unsupported. Please connect to an account that uses either USD or CAD for a currency.',
      },
    ],
    [
      'E004', // The account is a duplicate from another account
      {
        source: SOURCES.DUTCHIE_PAY,
        ecomm: 'This bank account is already linked to a Dutchie Account. Please link a different bank account.',
        pos: 'This bank account is already linked to a Dutchie Account. Please link a different bank account.',
      },
    ],
    [
      'E005', // Order is $0
      {
        source: SOURCES.DUTCHIE_PAY,
        ecomm: 'Order amount must be greater than 0. Please try again.',
        pos: 'Order amount must be greater than 0. Please try again.',
      },
    ],
    [
      'E999', // DEPRECATED. Use Y999 instead.
      {
        source: SOURCES.DUTCHIE_PAY,
        ecomm: getDefaultDutchiePayError(name),
        pos: getDefaultDutchiePayError(name),
      },
    ],
    [
      'F090', // score too high payment abuse
      {
        source: SOURCES.SIFT,
        ecomm: `${name} account is temporarily blocked. ${supportLink}`,
        pos: `${name} account is temporarily blocked. ${supportLink}`,
      },
    ],
    [
      'F091', // Internally blocked customer
      {
        source: SOURCES.DUTCHIE_PAY,
        ecomm: `${name} account is temporarily blocked. ${supportLink}`,
        pos: `${name} account is temporarily blocked. ${supportLink}`,
      },
    ],
    [
      'F092', // exceeded transaction attempts payment abuse
      {
        source: SOURCES.SIFT,
        ecomm: `Customer has exceeded transaction limit. ${supportLink}`,
        pos: `Customer has exceeded transaction limit. ${supportLink}`,
      },
    ],
    [
      'F093', // internally blocked bank account
      {
        source: SOURCES.DUTCHIE_PAY,
        ecomm: `${name} account is temporarily blocked. ${supportLink}`,
        pos: `${name} account is temporarily blocked. ${supportLink}`,
      },
    ],
    [
      'F094', // blacklisted user payment abuse
      {
        source: SOURCES.SIFT,
        ecomm: `${name} account is temporarily blocked. ${supportLink}`,
        pos: `${name} account is temporarily blocked. ${supportLink}`,
      },
    ],
    [
      'F095', // ach return payment abuse
      {
        source: SOURCES.SIFT,
        ecomm: `Customer has unpaid orders. ${supportLink}`,
        pos: `Customer has unpaid orders. ${supportLink}`,
      },
    ],
    [
      'F096', // ach return order payment abuse
      {
        source: SOURCES.SIFT,
        ecomm: `Customer has unpaid orders. ${supportLink}`,
        pos: `Customer has unpaid orders. ${supportLink}`,
      },
    ],
    [
      'F098', // looks bad payment abuse
      {
        source: SOURCES.SIFT,
        ecomm: `DutchiePay account is temporarily blocked. ${supportLink}`,
        pos: `DutchiePay account is temporarily blocked. ${supportLink}`,
      },
    ],
    [
      'F099', // order looks bad payment abuse
      {
        source: SOURCES.SIFT,
        ecomm: `DutchiePay account is temporarily blocked. ${supportLink}`,
        pos: `DutchiePay account is temporarily blocked. ${supportLink}`,
      },
    ],
    [
      'F100', // exceeded transaction attempts payment abuse
      {
        source: SOURCES.SIFT,
        ecomm: `Customer has exceeded transaction limit. ${supportLink}`,
        pos: `Customer has exceeded transaction limit. ${supportLink}`,
      },
    ],
    [
      'F101', // blocklisted phone payment abuse
      {
        source: SOURCES.SIFT,
        ecomm: `${name} account is temporarily blocked. ${supportLink}`,
        pos: `${name} account is temporarily blocked. ${supportLink}`,
      },
    ],
    [
      'F102', // chime block payment abuse
      {
        source: SOURCES.SIFT,
        ecomm: `Your financial institution is not supported by ${name} at this time. Please use a different method of payment.`,
        pos: `Your financial institution is not supported by ${name} Pay at this time. Please use a different method of payment.`,
      },
    ],
    [
      'S001', // Unexpected error response from MX
      {
        source: SOURCES.MX,
        ecomm: getDefaultDutchiePayError(name),
        pos: getDefaultDutchiePayError(name),
      },
    ],
    [
      'S002', // Unexpected error response from SIFT
      {
        source: SOURCES.SIFT,
        ecomm: getDefaultDutchiePayError(name),
        pos: getDefaultDutchiePayError(name),
      },
    ],
    [
      'S003', // dutchie pay service unavailable
      {
        source: SOURCES.DUTCHIE_PAY,
        ecomm: getDefaultDutchiePayError(name),
        pos: getDefaultDutchiePayError(name),
      },
    ],
    [
      'S010', // internal feature switch disabled dutchie pay
      {
        source: SOURCES.DUTCHIE_PAY,
        ecomm: getDefaultDutchiePayError(name),
        pos: getDefaultDutchiePayError(name),
      },
    ],
    [
      'V001', // Consumer DAILY velocity limit of allotted pending and finalized transactions exceeded
      {
        source: SOURCES.CONSUMER_VELOCITY_CHECK,
        ecomm: `Customer has exceeded transaction limit. ${velocityTransactionLimitsLink}`,
        pos: `Customer has exceeded transaction limit. ${velocityTransactionLimitsLink}`,
      },
    ],
    [
      'V002', // Consumer WEEKLY velocity limit of allotted pending and finalized transactions exceeded
      {
        source: SOURCES.CONSUMER_VELOCITY_CHECK,
        ecomm: `Customer has exceeded transaction limit. ${velocityTransactionLimitsLink}`,
        pos: `Customer has exceeded transaction limit. ${velocityTransactionLimitsLink}`,
      },
    ],
    [
      'V003', // Consumer MONTHLY velocity limit of allotted pending and finalized transactions exceeded
      {
        source: SOURCES.CONSUMER_VELOCITY_CHECK,
        ecomm: `Customer has exceeded transaction limit. ${velocityTransactionLimitsLink}`,
        pos: `Customer has exceeded transaction limit. ${velocityTransactionLimitsLink}`,
      },
    ],
    [
      'V004', // consumer velocity failed amount day
      {
        source: SOURCES.CONSUMER_VELOCITY_CHECK,
        ecomm: `Customer has exceeded spending limit. ${velocitySpendLimitsLink}`,
        pos: `Customer has exceeded spending limit. ${velocitySpendLimitsLink}`,
      },
    ],
    [
      'V005', // Consumer WEEKLY DOLLAR AMOUNT velocity limit of allotted pending and finalized transactions exceeded
      {
        source: SOURCES.CONSUMER_VELOCITY_CHECK,
        ecomm: `Customer has exceeded spending limit. ${velocitySpendLimitsLink}`,
        pos: `Customer has exceeded spending limit. ${velocitySpendLimitsLink}`,
      },
    ],
    [
      'V006', // Consumer MONTHLY DOLLAR AMOUNT velocity limit of allotted pending and finalized transactions exceeded
      {
        source: SOURCES.CONSUMER_VELOCITY_CHECK,
        ecomm: `Customer has exceeded spending limit. ${velocitySpendLimitsLink}`,
        pos: `Customer has exceeded spending limit. ${velocitySpendLimitsLink}`,
      },
    ],
    [
      'V007', // Consumer has exceeded the 3_DAY COUNT of allotted pending and finalized transactions.
      {
        source: SOURCES.TRANSACTION_VELOCITY_CHECK,
        ecomm: `Customer has exceeded transaction limit. ${velocityTransactionLimitsLink}`,
        pos: `Customer has exceeded transaction limit. ${velocityTransactionLimitsLink}`,
      },
    ],
    [
      'V008', // Consumer has exceeded the 3_DAY DOLLAR AMOUNT of allotted pending and finalized transactions.
      {
        source: SOURCES.CONSUMER_VELOCITY_CHECK,
        ecomm: `Customer has exceeded spending limit. ${velocitySpendLimitsLink}`,
        pos: `Customer has exceeded spending limit. ${velocitySpendLimitsLink}`,
      },
    ],
    [
      'V010', // Attempted Transaction is more than the allotted total dollar amount.
      {
        source: SOURCES.TRANSACTION_VELOCITY_CHECK,
        ecomm: `Customer has exceeded spending limit. ${velocitySpendLimitsLink}`,
        pos: `Customer has exceeded spending limit. ${velocitySpendLimitsLink}`,
      },
    ],
    [
      'V011', // Consumer has exceeded the HOURLY COUNT of attempted transactions.
      {
        source: SOURCES.TRANSACTION_VELOCITY_CHECK,
        ecomm: `Customer has exceeded transaction limit. ${velocityTransactionLimitsLink}`,
        pos: `Customer has exceeded transaction limit. ${velocityTransactionLimitsLink}`,
      },
    ],
    [
      'V012', // Consumer has exceeded the DAILY COUNT of attempted transactions.
      {
        source: SOURCES.TRANSACTION_VELOCITY_CHECK,
        ecomm: `Customer has exceeded transaction limit. ${velocityTransactionLimitsLink}`,
        pos: `Customer has exceeded transaction limit. ${velocityTransactionLimitsLink}`,
      },
    ],
    [
      'V013', // Consumer has exceeded the WEEKLY COUNT of attempted transactions.
      {
        source: SOURCES.TRANSACTION_VELOCITY_CHECK,
        ecomm: `Customer has exceeded transaction limit. ${velocityTransactionLimitsLink}`,
        pos: `Customer has exceeded transaction limit. ${velocityTransactionLimitsLink}`,
      },
    ],
    [
      'V014', // Consumer has exceeded the MONTHLY COUNT of attempted transactions.
      {
        source: SOURCES.TRANSACTION_VELOCITY_CHECK,
        ecomm: `Customer has exceeded transaction limit. ${velocityTransactionLimitsLink}`,
        pos: `Customer has exceeded transaction limit. ${velocityTransactionLimitsLink}`,
      },
    ],
    [
      'V015', // Consumer has exceeded the 3_DAY COUNT of attempted transactions.
      {
        source: SOURCES.TRANSACTION_VELOCITY_CHECK,
        ecomm: `Customer has exceeded transaction limit. ${velocityTransactionLimitsLink}`,
        pos: `Customer has exceeded transaction limit. ${velocityTransactionLimitsLink}`,
      },
    ],
    [
      'V016', // Chime has exceeded the DAILY 100 DOLLAR AMOUNT of allotted pending and finalized transactions.
      {
        source: SOURCES.SIFT,
        ecomm: `Customer has exceeded the $100 transaction limit. Please lower the order total and try again.`,
        pos: `Customer has exceeded the $100 transaction limit. Please lower the order total and try again.`,
      },
    ],
    [
      'V017', // Neobank consumer has exceeded the DAILY 300 DOLLAR AMOUNT of allotted pending and finalized transactions.
      {
        source: SOURCES.SIFT,
        ecomm: `Customer has exceeded $300 transaction limit. Please lower the order total and try again.`,
        pos: `Customer has exceeded $300 transaction limit. Please lower the order total and try again.`,
      },
    ],
    [
      'Y999', // Generic catch-all error, including Sift errors w/out a reason code
      {
        source: SOURCES.DUTCHIE_PAY,
        ecomm: getDefaultDutchiePayError(name),
        pos: getDefaultDutchiePayError(name),
      },
    ],
  ]);

export function getDutchiePayErrorMessage(errCode?: string, defaultMessage?: string, name?: string): Message {
  const defaultMsg = defaultMessage || getDefaultDutchiePayError(name);
  return getMessage(dutchiePayErrors(name), defaultMsg, errCode);
}

export const dutchiePayReasonCodes = Array.from(dutchiePayErrors().keys());

import { type ReactNode, useCallback, useState } from 'react';

import { createPortal } from 'react-dom';
import styled from 'styled-components';

import { popupWidth } from 'src/app/components/signature/styles';
import Close from 'src/assets/close.svg?react';
import { colors, zIndices } from 'src/css/theme';

const PopupBaseDiv = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: ${zIndices.popup};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

const PopupBodyDiv = styled.div<{ width: string }>`
  background-color: ${colors.dutchie.primaryWhite};
  border-radius: 20px;
  flex-direction: column;
  display: flex;
  position: relative;
  width: ${({ width }) => width};
`;

const PopupHeader = styled.div`
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  background: ${colors.dutchie.primaryWhite};
  padding: 0.875rem 0;
  box-shadow: 0 1px 0 ${colors.dutchie.shadowGrey};
  position: relative;
`;

const PopupBodyContent = styled.div<{ contentMaxHeight?: string }>`
  padding-top: 1.5rem;
  margin: 0;
  max-height: ${({ contentMaxHeight }) => contentMaxHeight ?? 'calc(100vh - 200px)'};
  overflow-y: auto;
`;

export const HeaderH3 = styled.h3<{ margin?: string }>`
  color: ${colors.dutchie.almostBlack};
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  margin: ${({ margin }) => margin || '0 auto'};
`;

const HeaderH4 = styled.h4`
  color: ${colors.dutchie.almostBlack};
  font-size: 1.05rem;
  text-align: center;
  margin: -1rem auto 1rem;
`;

const CloseIcon = styled(Close)`
  cursor: pointer;
  position: absolute;
  left: 1rem;
  top: 0.75rem;
  color: ${colors.dutchie.grey};
`;

type DutchiePopupProps = {
  automationId?: string;
  canClose?: boolean;
  caption: ReactNode;
  className?: string;
  contentMaxHeight?: string;
  hide: () => void;
  isVisible: boolean;
  large?: boolean;
  medium?: boolean;
  portalContainer?: Element;
  small?: boolean;
  subCaption?: string;
  width?: string;
};

export const DutchiePopup = ({
  caption,
  subCaption,
  isVisible,
  width,
  hide,
  className,
  children,
  canClose = true,
  contentMaxHeight,
  small = false,
  large = false,
  medium = false,
  portalContainer,
  automationId,
}: DutchiePopupProps) =>
  isVisible
    ? createPortal(
        <PopupBaseDiv>
          <PopupBodyDiv
            className={className}
            data-testid={automationId ?? 'popup_popup-body-div_section'}
            width={popupSize({ small, medium, large, width })}
          >
            <PopupHeader data-testid='popup_popup-header-div_section'>
              <HeaderH3>{caption}</HeaderH3>
              {subCaption && <HeaderH4>{subCaption}</HeaderH4>}
              {canClose && <CloseIcon data-testid='popup_popup-body-div_close-icon' onClick={hide} />}
            </PopupHeader>
            <PopupBodyContent contentMaxHeight={contentMaxHeight} data-testid='popup_popup-content-div_section'>
              {children}
            </PopupBodyContent>
          </PopupBodyDiv>
        </PopupBaseDiv>,
        portalContainer ?? document.body
      )
    : null;

type usePopupHook = (popupVisible?: boolean, onClose?: () => void) => { isVisible: boolean; toggle: () => void };

export const usePopup: usePopupHook = (popupVisible = false, onClose?: () => void) => {
  const [isVisible, setIsShowing] = useState(popupVisible);

  const toggle = useCallback(() => {
    if (isVisible && onClose) {
      onClose();
    }

    setIsShowing(!isVisible);
  }, [setIsShowing, isVisible, onClose]);

  return { isVisible, toggle };
};

type IPopupSize = {
  large?: boolean;
  medium?: boolean;
  small?: boolean;
  width?: string;
};

const popupSize = ({ small, medium, large, width }: IPopupSize): string => {
  if (small) {
    return popupWidth.small;
  }

  if (medium) {
    return popupWidth.medium;
  }

  if (large) {
    return popupWidth.large;
  }

  return width ?? popupWidth.small;
};

import { configureStore } from '@reduxjs/toolkit';
import { combineReducers, type Middleware } from 'redux';
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';

import { drawersReducer, type DrawersState } from './reducers/drawers-reducer';
import { mobileCheckoutSlice, type MobileCheckoutState } from './reducers/MobileCheckoutReducer';
import { notificationsReducer, type NotificationsState } from './reducers/notifications-reducer';
import { popupsReducer, type PopupsState } from './reducers/popups-reducer';
import { userSlice, type UserState } from './slices/user';

export const reducer = {
  user: userSlice.reducer,
  mobileCheckout: mobileCheckoutSlice.reducer,
  notifications: notificationsReducer,
  popups: popupsReducer,
  drawers: drawersReducer,
};

export type State = {
  drawers: DrawersState;
  mobileCheckout: MobileCheckoutState;
  notifications: NotificationsState;
  popups: PopupsState;
  user: UserState;
};

const persistConfig = { key: 'root', storage: storageSession, whitelist: ['user', 'settings'] };
const persistedReducer = persistReducer(persistConfig, combineReducers(reducer));

export const store = (loggers: Middleware[]) =>
  configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false, isImmutable: false }).concat(loggers),
  });

export type AppDispatch = ReturnType<typeof store>['dispatch'];

import { Link, useNavigate } from 'react-router-dom';

import { ScreenLoader } from '@dutchie-pos/components';

import { useRegisters } from 'src/queries/curbside/get-registers';
import type { RegisterInfo } from 'src/queries/curbside/get-registers';
import { Path, setRegister } from 'src/store/slices/user';
import { useAppSelector, useAppDispatch } from 'src/utils';

import { ContentLayout } from '../components/content-layout';
import { headerHeight } from '../components/header';
import { SelectionList } from '../components/selection-list';

export const SelectRegister = () => {
  // Global state
  const selectedPath = useAppSelector((state) => state.user.selectedPath);

  // Hooks
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data: registers, isLoading: fetchingRegisters } = useRegisters();

  return (
    <ContentLayout>
      <SelectionList
        key='id'
        list={registers ?? []}
        name='Register'
        value='TerminalName'
        onGoBack={() => navigate('/paths')}
        onSelection={(register: RegisterInfo) => {
          dispatch(setRegister(register));
          if (selectedPath === Path.Delivery) {
            navigate(`/route/delivery`);
          } else {
            navigate(`/carts`);
          }
        }}
      />
      {registers?.length === 0 && (
        <div>
          Location does not have registers. Go back to <Link to='/locations'>locations</Link>
        </div>
      )}
      <ScreenLoader speed='fast' top={headerHeight} visible={fetchingRegisters} />
    </ContentLayout>
  );
};

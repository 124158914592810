import { type FC, type ReactNode } from 'react';

import styled, { type FlattenSimpleInterpolation, css } from 'styled-components';

import { colors } from 'src/css/theme';

import { DutchieButton } from '../dutchie-button';

import { DutchiePopup } from './popup';

const StickyFooter = css`
  margin-top: 0;
  position: sticky;
  bottom: 0;
  background-color: white;
  border-radius: 0 0 20px 20px;
`;

type ButtonStyleProps = {
  styles?: FlattenSimpleInterpolation;
};

type ButtonsStyleProps = {
  reverse?: boolean;
  sticky?: boolean;
};

const Buttons = styled.div<ButtonsStyleProps>`
  margin-top: 1.5rem;
  display: flex;
  flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
  justify-content: space-between;
  padding: 1rem 1.5rem;
  box-shadow: 0px -1px 0px ${colors.dutchie.shadowGrey};

  ${({ sticky }) => (sticky ? StickyFooter : null)}
`;

const CancelButton = styled.button<ButtonStyleProps>`
  font-size: 1rem;
  color: ${colors.dutchie.almostBlack};
  text-decoration-line: underline;
  background-color: ${colors.white};
  border: none;
  cursor: pointer;
  ${({ styles }) => styles};
`;

const ConfirmButton = styled(DutchieButton)<ButtonStyleProps>`
  ${({ styles }) => styles};
`;

type ButtonProps = {
  disabled?: boolean;
  id?: string;
  onClick: () => void;
  styles?: FlattenSimpleInterpolation;
  text: ReactNode;
};

type ConfirmationPopupProps = {
  automationId?: string;
  buttonsSectionVisible?: boolean;
  cancel?: ButtonProps;
  canClose?: boolean;
  className?: string;
  confirm?: ButtonProps;
  contentMaxHeight?: string;
  hide: () => void;
  isVisible: boolean;
  large?: boolean;
  medium?: boolean;
  portalContainer?: Element;
  small?: boolean;
  title: ReactNode;
  width?: string;
};

export const DutchieConfirmationPopup: FC<ConfirmationPopupProps> = ({
  isVisible,
  buttonsSectionVisible = false,
  hide,
  className,
  title,
  contentMaxHeight,
  children,
  confirm,
  cancel,
  canClose,
  ...rest
}) => (
  <DutchiePopup
    canClose={canClose}
    caption={title}
    className={className}
    contentMaxHeight={contentMaxHeight}
    hide={hide}
    isVisible={isVisible}
    {...rest}
  >
    {children}
    {(confirm || cancel || buttonsSectionVisible) && (
      <Buttons reverse={confirm && !cancel} sticky={contentMaxHeight !== undefined}>
        {cancel && (
          <CancelButton
            data-testid='confirmation-popup_cancel-button_cancel'
            disabled={cancel.disabled}
            id={cancel.id}
            styles={cancel.styles}
            type='button'
            onClick={cancel.onClick}
          >
            {cancel.text || 'Cancel'}
          </CancelButton>
        )}
        {confirm && (
          <ConfirmButton
            automationId='confirmation-popup_confirm-button_confirm'
            disabled={confirm.disabled}
            id={confirm.id}
            styles={confirm.styles}
            type='button'
            onClick={confirm.onClick}
          >
            {confirm.text || 'Confirm'}
          </ConfirmButton>
        )}
      </Buttons>
    )}
  </DutchiePopup>
);

import { useMutation } from '@tanstack/react-query';

import { doPostWithoutParams } from 'src/utils/helpers/http';

const LOGOUT_URL = '/api/posv3/user/Logout';

type LogoutRequest = {
  Origin: string;
};

export function useLogoutMutation() {
  return useMutation({
    mutationFn: async (payload: LogoutRequest) => {
      const logoutRequestPayload: LogoutRequest = {
        Origin: payload.Origin ?? 'UserInitiatedLogout',
      };

      await doPostWithoutParams(LOGOUT_URL, logoutRequestPayload);
    },
    mutationKey: ['logout'],
  });
}

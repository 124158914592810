import { type ProductSearchResult } from './products';

/** @deprecated Clean up with pos.register.anon-cart-workflow.rollout */
export type CartItem = {
  AllowSaleToMinor?: number;
  AutoPrintLabel?: number;
  BatchId?: number;
  BrandId?: number;
  BrandName?: string;
  CannbisProduct?: string;
  Customer?: number;
  DaysSupply?: number;
  DaysSupplyPerItem?: number;
  DefaultLabelId?: number;
  DefaultUnitId?: number;
  DiscountAmt?: number;
  Equivalent?: number;
  Grams?: number;
  Id: number;
  Instructions?: string;
  InventoryId?: number;
  LoyaltyAmt?: number;
  MMURDeviceId?: number;
  MMURFormId?: number;
  MMUROrderId?: number;
  Multiplier?: number;
  OrderDate?: number;
  OrderNo: string;
  PackageItemId?: number;
  PackageItemSerialNumber?: string;
  PricedQuantity?: number;
  Product: string;
  ProductCategory?: string;
  ProductId: number;
  QtyAllocated: number;
  QtySelected: number;
  RowInventoryIds?: number[];
  SerialNo: string;
  Taxable?: number;
  TaxAmt?: number;
  TotalCost: number;
  WgtCnt: 'Qty' | 'Wgt';
};

export type ScanResponse = {
  AllowSaleToMinor?: string;
  AutoPrintLabel?: number;
  CannabisInventory: string;
  ChargeCodeId?: number;
  Coupon?: string;
  DefaultLabelId?: number;
  DefaultUnitId: number;
  Description?: string;
  InvId: number;
  MasterCategory?: string;
  PackageItemId?: number;
  ProductCategory?: string;
  ProductDesc: string;
  ProductId: number;
  ProductImageURL?: string;
  ProductNo: string;
  ProductType?: string;
  RecUnitPrice: number;
  SerialNo: string;
  Strain?: string;
  TotalAvailable: number;
  TotalGrams?: number;
  TotalOz?: number;
  UnitGrams: number;
  UnitPrice: number;
};

export const isCartItemOrProductSearchResult = (item: CartItem | ProductSearchResult): item is CartItem =>
  (item as CartItem).WgtCnt !== undefined;

export const convertScanResponseToProductSearchResult = (scanResponse: ScanResponse): ProductSearchResult => ({
    allowSaleToMinor: scanResponse.AllowSaleToMinor === 'yes',
    autoPrintLabel: scanResponse.AutoPrintLabel === 1,
    batchId: undefined,
    brandName: undefined,
    cannabisInventory: scanResponse.CannabisInventory,
    cbdContent: undefined,
    cbdContentUnitId: undefined,
    chargeCodeId: scanResponse.ChargeCodeId ?? 0,
    coupon: scanResponse.Coupon ?? 'no',
    defaultLabelId: scanResponse.DefaultLabelId,
    defaultUnitId: scanResponse.DefaultUnitId,
    description: scanResponse.Description ?? '',
    invId: scanResponse.InvId,
    masterCategory: scanResponse.MasterCategory ?? '',
    packageItemId: scanResponse.PackageItemId,
    packageItemSerialNumber: undefined,
    productCategory: scanResponse.ProductCategory ?? '',
    productDescription: scanResponse.ProductDesc,
    productId: scanResponse.ProductId,
    productImageURL: scanResponse.ProductImageURL ?? '',
    productNo: scanResponse.ProductNo,
    productType: scanResponse.ProductType === 'Wgt' ? 'Wgt' : 'Qty',
    receivedDate: undefined,
    recUnitPrice: scanResponse.RecUnitPrice,
    serialNo: scanResponse.SerialNo,
    strain: scanResponse.Strain ?? '',
    thcContent: undefined,
    thcContentUnitId: undefined,
    totalAvailable: scanResponse.TotalAvailable,
    totalGrams: scanResponse.TotalGrams ?? 0,
    totalOz: scanResponse.TotalOz ?? 0,
    unitGrams: scanResponse.UnitGrams,
    unitPrice: scanResponse.UnitPrice,
    useByDate: undefined,
    vendor: undefined,
  });

export type ScanItem = {
  CustomerId: number;
  Register: number;
  SerialNumber: string;
  UserId?: number;
};

/** @deprecated Clean up with pos.register.anon-cart-workflow.rollout */
export type Preorder = {
  Abbreviation: string;
  BrandId?: number;
  BrandName?: string;
  Category: string;
  Customer: string;
  Grams: number;
  Grower: string;
  Id: number;
  InventoryDate: Date;
  Location: string;
  OrderDate: Date;
  OrderId: number;
  OrderItemId: number;
  OrderNo: string;
  OrderStatus: string;
  PackageItemSerialNumber?: string;
  Product: string;
  ProductId: number;
  ProductImageURL?: string;
  QtySelected: number;
  SerialNo: number;
  Strain: string;
  TotalCost: number;
  UnitId: number;
  UnitName: number;
};

import { type QueryKey, useQuery } from '@tanstack/react-query';

import { post } from 'src/utils/helpers/http';
import { useBaseAPIParams } from 'src/utils/hooks/useBaseAPIParams';

export const GetCancellationReasons: QueryKey = ['get-cancellation-reasons'];

const GET_CANCELLATION_REASONS_URL = '/posv3/maintenance/get-cancellation-reasons';

export type CancellationReasonsResponse = {
  Reason: string;
  ReasonId: number;
};

export function useGetCancellationReasonsQuery({ enabled }: { enabled: boolean }) {
  const baseAPIParams = useBaseAPIParams();
  return useQuery({
    queryKey: GetCancellationReasons,
    queryFn: async () => {
      const res = await post<CancellationReasonsResponse[]>(GET_CANCELLATION_REASONS_URL, baseAPIParams);
      return res;
    },
    enabled,
  });
}

import { useMutation } from '@tanstack/react-query';

import { useActiveCustomer } from 'src/queries/curbside/use-carts';
import { successNotification, errorNotification } from 'src/store/actions/notifications-actions';
import { useAppSelector, useAppDispatch } from 'src/utils';
import { post } from 'src/utils/helpers/http';
import { useBaseAPIParams } from 'src/utils/hooks/useBaseAPIParams';

const PRINT_RECEIPT_URL = '/v2/print-jobs/print-receipt';

type usePrintReceiptType = {
  Subtotal: number;
  Total: number;
};

type ElectronicPaymentResponse = {
  CreditPaid: number;
  DebitPaid: number;
  PaymentSuccess: boolean;
  PollingComplete: boolean;
  PollingError: string;
  TipAmount?: number;
};

export function usePrintReceipt({ Subtotal, Total }: usePrintReceiptType) {
  const dispatch = useAppDispatch();
  const baseAPIParams = useBaseAPIParams();

  const { data: activeCustomer } = useActiveCustomer();
  const selectedReceiptPrinterId = useAppSelector((state) => state.user.selectedRegister?.ReceiptPrinterId);

  return useMutation({
    mutationFn: async () =>
      await post(PRINT_RECEIPT_URL, {
        ...baseAPIParams,
        Total,
        Subtotal,
        ReceiptType: 'Receipt',
        ReceiptParameters: activeCustomer?.ShipmentId,
        PrinterId: selectedReceiptPrinterId,
        ForDelivery: true,
        localPrinter: false,
      }),
    onSuccess: () => dispatch(successNotification('Receipt printed')),
    onError: () => dispatch(errorNotification('Receipt failed to print')),
  });
}

/* eslint-disable no-console */
import { type BaseCustomEventData } from '../custom-event-keys';
import { LoggingProvider } from '../logging-provider';

import { sanitizeData } from './sanitize-data';

import type { UserData } from '../logging-provider';
import type { Dispatch, AnyAction, Middleware } from '@reduxjs/toolkit';

export class ConsoleLoggingProvider extends LoggingProvider {
  public providerId = 'console';

  public getMiddleware = (): Middleware => (_store: unknown) => (next: Dispatch<AnyAction>) => (action: AnyAction) => {
    console.debug(`redux_${action.type}`, action.payload);
    return next(action);
  };

  public setGlobalProperty = (key: string, value: unknown) => {
    console.log(`Global Property '${key}' was set`, value);
  };

  public setUser = (userId: string, userData?: UserData) => {
    console.log(`User '${userId}' was set`, JSON.stringify(userData));
  };

  public track = <CustomEventData extends BaseCustomEventData>(
    customEventDescription: string,
    data?: CustomEventData
  ) => {
    console.log(`Custom Event '${customEventDescription}' was tracked`, JSON.stringify(sanitizeData(data)));
  };

  public error = (e: unknown, data?: object) => {
    console.error(e, JSON.stringify(data));
  };
}
/* eslint-enable no-console */

/* eslint-disable react/jsx-no-useless-fragment */
import { closePopup } from 'src/store/actions/popups-actions';
import { useAppDispatch, useAppSelector } from 'src/utils';

import { ConfirmRemoveItemPopup } from './components/confirm-remove-item-popup';

export const Popups = () => {
  const dispatch = useAppDispatch();

  const confirmRemoveItemPopup = useAppSelector((state) => state.popups.confirmRemoveItemPopup);

  return (
    <>
      {confirmRemoveItemPopup && (
        <ConfirmRemoveItemPopup
          {...confirmRemoveItemPopup}
          hide={() => dispatch(closePopup('confirmRemoveItemPopup'))}
          onCancel={() => dispatch(closePopup('confirmRemoveItemPopup'))}
        />
      )}
    </>
  );
};

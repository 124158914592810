import React from 'react';

import { LoaderRing, InnerRing } from './loader.styles';

export enum LoadingSpinnerSizeProp {
  '1.25x' = '1.25em',
  '10x' = '10em',
  '1x' = '1em',
  '2x' = '2em',
  '3x' = '3em',
  '4x' = '4em',
  '5x' = '5em',
  '6x' = '6em',
  '7x' = '7em',
  '8x' = '8em',
  '9x' = '9em',
  'lg' = '1.33em',
  'sm' = '0.875em',
  'xs' = '0.75em',
}

export type LoaderProps = {
  size?: keyof typeof LoadingSpinnerSizeProp;
  variant?: 'black' | 'blue' | 'default' | 'grey';
};

export function Loader({ size = '1x', variant = 'default' }: LoaderProps): JSX.Element {
  return (
    <LoaderRing
      aria-label='Loading'
      aria-valuetext='Loading'
      data-testid='loading-spinner_icon'
      size={LoadingSpinnerSizeProp[size]}
    >
      <InnerRing $variant={variant} />
      <InnerRing $variant={variant} />
      <InnerRing $variant={variant} />
      <InnerRing $variant={variant} />
    </LoaderRing>
  );
}

import { datadogRum } from '@datadog/browser-rum';
import { type RumPublicApi } from '@datadog/browser-rum-core';

import { type BaseCustomEventData } from '../custom-event-keys';
import { LoggingProvider } from '../logging-provider';

import { sanitizeData } from './sanitize-data';

import type { AnyAction, Dispatch, Middleware } from '@reduxjs/toolkit';

let GLOBAL_DD_CLIENT: RumPublicApi;

export class DatadogLoggingProvider extends LoggingProvider {
  public providerId = 'datadog';

  public getMiddleware = (): Middleware => (_store: unknown) => (next: Dispatch<AnyAction>) => (action: AnyAction) => {
    datadogRum.addAction(`Register-Redux-Action_${action.type}`, action.payload);
    return next(action);
  };

  public setGlobalProperty = (key: string, value: unknown) => {
    datadogRum.setGlobalContextProperty(key, value);
  };

  public track = <CustomEventData extends BaseCustomEventData>(
    customEventDescription: string,
    data?: CustomEventData
  ) => {
    datadogRum.addAction(customEventDescription, sanitizeData(data));
  };

  public error = (e: unknown, data?: object) => {
    datadogRum.addError(e, data);
  };
}

export const getDDClient = () => GLOBAL_DD_CLIENT;

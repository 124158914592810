import { useNavigate } from 'react-router-dom';

import { Path, setPath } from 'src/store/slices/user';
import { useAppDispatch } from 'src/utils';

import { ContentLayout } from '../components/content-layout';
import { SelectionList } from '../components/selection-list';

export const SelectPath = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleMethodSelection = (method: { value: Path }) => {
    dispatch(setPath(method.value));
    navigate(`/registers`);
  };

  return (
    <ContentLayout>
      <SelectionList
        key='key'
        list={[
          { key: Path.Curbside, value: Path.Curbside },
          { key: Path.Delivery, value: Path.Delivery },
        ]}
        name='Use'
        value='value'
        onGoBack={() => navigate('/locations')}
        onSelection={handleMethodSelection}
      />
    </ContentLayout>
  );
};

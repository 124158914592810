import { useParams } from 'react-router';

import { type CartParams } from 'src/app/pages/cart';
import { Path } from 'src/store/slices/user';
import { useAppSelector } from 'src/utils';

import { useRoutes } from './get-my-routes';
import { useOrdersForRoute } from './get-orders-for-route';
import { useGuestList } from './guest-list';

/**
 * Note: good chance this should live elsewhere...
 */
export function useCarts() {
  const selectedPath = useAppSelector((state) => state.user.selectedPath);
  const { data: guestList, isLoading: guestListLoading } = useGuestList();
  const { data: ordersForRoute, isLoading: getOrdersForRouteIsLoading } = useOrdersForRoute();

  const filteredCurbsideCarts = guestList?.filter((value) => value.TotalItems > 0) ?? [];
  const filteredOrdersForRoute = ordersForRoute?.filter((order) => order.ShipmentStatusId === 1) ?? [];

  return {
    data: selectedPath === Path.Curbside ? filteredCurbsideCarts : filteredOrdersForRoute,
    isLoading: guestListLoading || getOrdersForRouteIsLoading,
  };
}

/**
 * Why is this data not returned on the get-cart query?
 */
export function useActiveCustomer() {
  const selectedPath = useAppSelector((state) => state.user.selectedPath);
  const { data: carts, isLoading } = useCarts();
  const { shipmentId } = useParams<CartParams>();
  const cart = carts.find((cart) => cart.ShipmentId === shipmentId);

  if (!cart) {
    return { data: null, isLoading };
  }

  return {
    data: {
      ...cart,
      IsDelivery: selectedPath === Path.Delivery,
      Phone: !!cart?.PatientPhone && cart?.PatientPhone.length > 0 ? cart?.PatientPhone : cart?.CellPhone,
    },
    isLoading,
  };
}

export function useActiveRoute() {
  const selectedRouteId = useAppSelector((state) => state.user.selectedRouteId);
  const { data, isLoading: isLoadingRoutes } = useRoutes(false);
  const route = data?.find((route) => route.DeliveryRouteId === selectedRouteId);

  return {
    data: route,
    isLoading: isLoadingRoutes,
  };
}

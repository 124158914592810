export const FeeNameByProcessor = {
  paynetworx: 'Non-Cash Adjustment',
  usag: 'Card Activation',
};

export const getFeeNameFromProcessor = (processor) => {
  if (processor in FeeNameByProcessor) {
    return FeeNameByProcessor[processor];
  }
  return 'Non-Cash Adjustment';
};
